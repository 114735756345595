import { useMutation } from '@tanstack/react-query';
import { editorImage } from 'api/editorImage';
import { CustomToast } from 'components/utils/toast-message';
import { useState } from 'react';
import { toast } from 'react-toastify';

interface UploadMediaProps {
  editor: any;
  mediaRef: any;
  isEditMode?: boolean;
}

export const UploadMedia = ({ editor, mediaRef, isEditMode }: UploadMediaProps) => {
  const [image, setImage] = useState<File>();

  const { mutate: uploadImageToS3 } = useMutation({
    mutationFn: editorImage.uploadImageUsingPresignedUrl,
    onSuccess: (data) => insertImage(data?.headers?.location),
  });

  const { mutate: getPresignedURL } = useMutation({
    mutationFn: editorImage.generatePresignedPostUrl,
    onSuccess: (presignedPostData) => {
      let formdata = new FormData();
      // append the fields in presignedPostData in formData
      Object.keys({ ...presignedPostData.fields }).forEach((key) => {
        formdata.append(key, presignedPostData.fields[key]);
      });

      // append the image in formData
      if (image) {
        formdata.append('file', image);
        uploadImageToS3({
          preSignedURL: presignedPostData?.url,
          formdata,
        });
      }
    },
  });

  const insertImage = (url: string) => {
    if (isEditMode) {
      editor.chain().focus().updateAttributes('image', { src: url }).run();
    } else {
      editor.chain().focus().setImage({ src: url }).run();
    }
  };

  const onFileSelect = (e) => {
    const file = e.target.files[0];
    if (file && !file.type.startsWith('image/')) {
      toast.error(CustomToast, { data: 'Only Images are allowed' });
      return;
    }

    setImage(file);
    uploadImage(file);
    if (mediaRef.current) mediaRef.current.value = '';
  };

  const uploadImage = (image) => {
    switch (process.env.NODE_ENV) {
      case 'production':
        getPresignedURL(image.name);
        break;
      case 'development':
      default:
        localUpload(image);
        break;
    }
  };

  const localUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      insertImage(readerEvent?.target?.result as string);
    };
    reader.readAsDataURL(file);
  };

  return (
    <input type='file' accept='image/*' className='hidden' ref={mediaRef} onChange={onFileSelect} />
  );
};
