import { Navigate, useParams } from 'react-router-dom';

interface PermanentRedirectProps {
  to: string;
}

const PermanentRedirect = ({ to }: PermanentRedirectProps) => {
  const params = useParams();

  const updatedPath = to.replace(/:(\w+)/g, (match, paramName) => {
    if (params[paramName]) {
      return params[paramName];
    }
    return match;
  });

  return <Navigate to={updatedPath} replace />;
};

export default PermanentRedirect;
