import { Box, Chip } from '@material-ui/core';
import { getCalculators } from 'actions/calculators/getCalculators';
import getUniqueCode from 'actions/unique-code/getUniqueCodeAction';
import AddIcon from 'assets/icons/addIcon';
import axios from 'axios';
import ChoicePanelPreview from 'components/app-previews/ChoicePanel';
import AppearingSituation from 'components/utils/AppearingSituation';
import AnswerSection from 'components/utils/answers/AnswerSection';
import ExampleNToolsList from 'components/utils/example-n-tools/ExampleNToolsList';
import InputField from 'components/utils/form-input/field';
import { compareCardPosition } from 'components/utils/general/compareCardPosition';
import { generate_ID } from 'components/utils/general/generateId';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import 'components/utils/modals/modal.css';
import {
  AddButton,
  AddLabel,
  CancelButton,
  CreateButton,
  Examples,
  PanelLabel,
  StyledFormGroup,
  StyledFormHelperText,
} from 'components/utils/styled-components/FormStyle';
import { CustomToast } from 'components/utils/toast-message';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Form } from 'reactstrap';
import { getRepresentationPhrase } from 'utils/utilityFunctions';
import { CANCEL_BUTTON, CHOICE_CARD_API_URL, MODULE_TYPES, SAVE_BUTTON } from '../../constants';
import { withTriggers } from '../../hooks/module/resources/useTriggers';

interface ChoiceCardFormProps {
  ansCardId: any;
  formState: any;
  answerPageId: any;
  handleAddExamples?: Function;
  handleAddTools: Function;
  infoboxState: any;
  mediaState: any;
  calculatorState: any;
  toggleModal: Function;
  getUniqueCode: Function;
  resetAnsCards: Function;
  triggerState: any;
  moduleId: any;
}
interface ChoiceCardFormState {
  images: any[];
  examples: any[];
  tools: any[];
  answer_page: any;
  pk: any;
  title: any;
  rationale: any;
  variable: any;
  choices: any[];
  question_panel: any[];
  question: any;
  trigger: any;
  titleSync: boolean;
  infoboxes: any[];
}
class ChoiceCardForm extends Component<ChoiceCardFormProps, ChoiceCardFormState> {
  constructor(props: ChoiceCardFormProps) {
    super(props);
    this.state = {
      images: [],
      examples: [],
      tools: [],

      answer_page: null,
      pk: '',
      title: '',
      rationale: '',
      variable: '',
      choices: [],
      question_panel: [],
      question: '',
      trigger: '',
      titleSync: true,
      infoboxes: [],
    };
  }

  helpInfoData = getHelpInfoData('ChoicePanel');

  // checks url and updates state accordingly (edit or create)
  componentDidMount() {
    if (!this.props.ansCardId) {
      let alwayOnTrigger = this.props.triggerState.triggers.find(
        (data) => data.title === 'Always On'
      );
      this.setState({
        trigger: alwayOnTrigger ? alwayOnTrigger.id : '',
      });
    }

    // if update request encountered
    if (this.props.ansCardId) {
      axios.get(CHOICE_CARD_API_URL + this.props.ansCardId + '/').then((res) => {
        this.updateState(res.data);
      });
    } else {
      this.setState({
        answer_page: this.props.answerPageId,
        choices: [
          { sortable_id: 1, name: 'Answer 1', position: 0 },
          { sortable_id: 2, name: 'Answer 2', position: 1 },
          { sortable_id: 3, name: 'Answer 3', position: 2 },
        ],
      });
    }
  }

  updateState = (data) => {
    let choicesObj: Array<{ id: string; name: string; position: any; sortable_id: any }> = [];
    data.choices.forEach((element) => {
      choicesObj.push({
        id: element.id,
        name: element.name,
        position: element.position,
        sortable_id: element.id,
      });
    });

    choicesObj.sort(compareCardPosition);

    this.setState({
      choices: choicesObj,
    });

    this.setState({
      pk: data.id,
      title: data.title,
      rationale: data.rationale,
      question: data.question,
      trigger: data.trigger ? data.trigger : '',
      variable: data.variable_item,
      infoboxes: data.infobox_detail,
      images: data.images,
      examples: data.examples,
      tools: data.tools,
      answer_page: data.answer_page,
    });
  };

  onChange = (e) => {
    if (e.target.name === 'question' && this.state.titleSync === true) {
      this.setState({
        title: getRepresentationPhrase(
          e.target.value,
          this.helpInfoData['title'].character_limit,
          true
        ),
      });
    }
    this.setState({ [e.target.name]: e.target.value } as Pick<ChoiceCardFormState, any>);
  };

  // for open or close modal
  toggleModal = (modalName) => {
    this.setState(
      (previous) =>
        ({
          [modalName]: !previous[modalName],
        }) as Pick<ChoiceCardFormState, any>
    );
  };

  deleteExample = (example) => {
    let examples = this.state.examples.filter((el) => el.id !== example.id);
    this.setState({
      examples: examples,
    });
  };

  updateExamples = (newList) => {
    this.setState({
      examples: newList,
    });
  };

  updateTools = (newList) => {
    this.setState({
      tools: newList,
    });
  };

  deleteTool = (tool) => {
    let tools = this.state.tools.filter((el) => el.id !== tool.id);
    this.setState({
      tools: tools,
    });
  };

  // add text item to example
  addText = () => {
    let text = {
      id: generate_ID(),
      section: 'E',
      text: {
        statement: 'Text Item',
      },
    };

    let examples = this.state.examples.concat(text);
    this.setState({ examples: examples });
    this.props.handleAddExamples && this.props.handleAddExamples(examples);
  };

  // add infobox to example
  addInfoBox = () => {
    let infobox = {
      id: generate_ID(),
      label: '',
      section: 'E',
      infobox: {},
    };

    let examples = this.state.examples.concat(infobox);
    this.setState({ examples: examples });
    this.props.handleAddExamples && this.props.handleAddExamples(examples);
  };

  addToolInfoBox = () => {
    let infobox = {
      id: generate_ID(),
      label: '',
      section: 'T',
      infobox: {},
    };

    let tools = this.state.tools.concat(infobox);
    this.setState({ tools });
    this.props?.handleAddTools?.(tools);
  };

  addImage = () => {
    let image = {
      id: generate_ID(),
      label: '',
      section: 'T',
      image: {},
    };

    let tools = this.state.tools.concat(image);
    this.setState({ tools });
    this.props?.handleAddTools?.(tools);
  };

  addCalculator = () => {
    let calculator = {
      id: generate_ID(),
      label: '',
      legacy_calculator: {},
      section: 'T',
    };

    let tools = this.state.tools.concat(calculator);
    this.setState({ tools });
    this.props?.handleAddTools?.(tools);
  };

  updateExample = (id, value, inputName) => {
    const updatedExamples = this.state.examples.map((example) => {
      let updatedItem = example;

      if (example.id === id) {
        if (inputName === 'label') {
          // text item doesn't have label
          if (example.text) {
            updatedItem = {
              ...example,
              text: {
                statement: value,
              },
            };
          } else {
            updatedItem = {
              ...example,
              label: value,
            };
          }
        } else if (inputName === 'infobox') {
          let infoboxObj = this.props.infoboxState.infoBoxes.find(
            (infobox) => infobox.id === value
          );
          updatedItem = {
            ...example,
            infobox: infoboxObj || {},
            label: !example.label && infoboxObj ? infoboxObj.title : example.label,
          };
          delete updatedItem.image;
        } else if (inputName === 'image') {
          let imageObj = this.props.mediaState.images.find((image) => image.id === value);
          updatedItem = {
            ...example,
            image: imageObj || {},
            label: !example.label && imageObj ? imageObj.title : example.label,
          };
          delete updatedItem.infobox;
        }

        return updatedItem;
      }

      return updatedItem;
    });

    this.setState({ examples: updatedExamples });
    this.props.handleAddExamples && this.props.handleAddExamples(updatedExamples);
  };

  updateTool = (id, value, inputName) => {
    const updatedTools = this.state.tools.map((tool) => {
      let updatedItem = tool;

      if (tool.id === id) {
        if (inputName === 'label') {
          updatedItem = {
            ...tool,
            label: value,
          };
        } else if (inputName === 'infobox') {
          let infoboxObj = this.props.infoboxState.infoBoxes.find(
            (infobox) => infobox.id === value
          );
          updatedItem = {
            ...tool,
            infobox: infoboxObj || {},
            label: !tool.label && infoboxObj ? infoboxObj.title : tool.label,
          };
          delete updatedItem.image;
        } else if (inputName === 'image') {
          let imageObj = this.props.mediaState.images.find((image) => image.id === value);
          updatedItem = {
            ...tool,
            image: imageObj || {},
            label: !tool.label && imageObj ? imageObj.title : tool.label,
          };
          delete updatedItem.infobox;
        } else if (inputName === 'legacy_calc') {
          let calcObj = this.props.calculatorState.calculators.find((image) => image.id === value);
          updatedItem = {
            ...tool,
            legacy_calculator: calcObj || {},
            label: !tool.label && calcObj ? calcObj.title : tool.label,
          };
        }

        return updatedItem;
      }

      return updatedItem;
    });

    this.setState({ tools: updatedTools });
    this.props?.handleAddTools?.(updatedTools);
  };

  // add choice in choices array
  addChoice = () => {
    // create choice obj
    let obj = {
      sortable_id: generate_ID(),
      name: 'Answer ' + (this.state.choices.length + 1),
      position: this.state.choices.length,
    };

    let concatedArray = this.state.choices.concat(obj);
    this.setState({ choices: concatedArray });
  };

  updateChoices = (newList) => {
    for (let i = 0; i < newList.length; i++) {
      newList[i].position = i;
    }

    this.setState({
      choices: newList,
    });
  };

  updateChoice = (id, value, inputName) => {
    const updatedChoices = this.state.choices.map((choice) => {
      let updatedItem = choice;

      if (choice.sortable_id === id) {
        if (inputName === 'name') {
          updatedItem = {
            ...choice,
            name: value,
          };
        }
        return updatedItem;
      }

      return updatedItem;
    });

    this.setState({ choices: updatedChoices });
  };

  deleteChoice = (choiceId) => {
    let prevChoices = this.state.choices.filter((el) => el.id !== choiceId);
    for (let i = 0; i < prevChoices.length; i++) {
      prevChoices[i].position = i;
    }
    this.setState({ choices: prevChoices });
  };

  getSelectedTrigger = (data) => {
    this.setState({
      trigger: data,
    });
  };

  getPayload = () => {
    let payload = {
      title: this.state.title,
      rationale: this.state.rationale,
      variable: this.state.title,
      choices: this.state.choices,
      question: this.state.question,
      trigger: this.state.trigger === 'notAssigned' ? null : this.state.trigger,
      question_items: this.state.examples.concat(this.state.tools),
      answer_page: this.state.answer_page ? this.state.answer_page : this.props.answerPageId,
    };
    return payload;
  };

  isExampleOrToolEmpty = () => {
    for (let i = 0; i < this.state.examples.length; i++) {
      const example = this.state.examples[i];

      if (
        (example.text && !example.text.statement) ||
        (example.infobox && (!example.label || Object.keys(example.infobox).length === 0))
      ) {
        return true;
      }
    }

    for (let i = 0; i < this.state.tools.length; i++) {
      const tool = this.state.tools[i];

      if (
        !tool.label ||
        (tool.infobox && Object.keys(tool.infobox).length === 0) ||
        (tool.image && Object.keys(tool.image).length === 0) ||
        (tool.legacy_calculator && Object.keys(tool.legacy_calculator).length === 0)
      ) {
        return true;
      }
    }

    return false;
  };

  isChoicesEmpty = () => {
    for (let i = 0; i < this.state.choices.length; i++) {
      if (!this.state.choices[i].name) {
        return true;
      }
    }

    return false;
  };

  createChoiceCard = (e) => {
    e.preventDefault();
    const hasEmptyName = this.state.choices.some((obj) => obj.name === '');

    if (this.isExampleOrToolEmpty()) {
      toast.error(CustomToast, { data: 'Example and Tool items cannot be empty.' });
      return;
    }
    if (hasEmptyName) {
      toast.error(CustomToast, { data: 'Please fill out all the Answers label fields.' });
      return;
    }
    if (this.isChoicesEmpty()) {
      toast.error(CustomToast, { data: 'Label is missing in answers' });
      return;
    }

    this.props.toggleModal();
    axios.post(CHOICE_CARD_API_URL, this.getPayload()).then(async () => {
      // reset unique codes mapping to get latest result
      await this.props.getUniqueCode(this.props.moduleId);
      this.props.resetAnsCards();
    });
  };

  editChoiceCard = (e) => {
    e.preventDefault();
    const hasEmptyName = this.state.choices.some((obj) => obj.name === '');

    if (this.isExampleOrToolEmpty()) {
      toast.error(CustomToast, { data: 'Example and Tool items cannot be empty.' });

      return;
    }
    if (hasEmptyName) {
      toast.error(CustomToast, { data: 'Please fill out all the Answers label fields.' });
      return;
    }

    if (this.isChoicesEmpty()) {
      toast.error(CustomToast, { data: 'Label is missing in answers' });
      return;
    }

    this.props.toggleModal();
    axios
      .put(CHOICE_CARD_API_URL + this.props.ansCardId + '/', this.getPayload())
      .then(async () => {
        // reset unique codes mapping to get latest result
        await this.props.getUniqueCode(this.props.moduleId);
        this.props.resetAnsCards();
      });
  };

  onKeyPress = (e) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  render() {
    // Check if any object has an empty "label" value
    const hasEmptyName = this.state.choices.some((obj) => obj.name === '');
    const helpInfoData = this.helpInfoData;
    const triggers = [
      ...this.props.triggerState.triggers,
      ...this.props.triggerState.candidate_triggers,
    ];

    return (
      <div className='row'>
        <div className='side-panel-form'>
          <Form
            style={{ display: 'flex', flexDirection: 'column' }}
            onKeyPress={this.onKeyPress}
            autoComplete='off'
            onSubmit={this.props.ansCardId ? this.editChoiceCard : this.createChoiceCard}
          >
            <br />

            {this.state.variable && (
              <div className='row' style={{ marginBottom: 0 }}>
                <div className='col-md-10 offset-md-1'>
                  <Chip
                    style={{ float: 'right', borderRadius: '10px' }}
                    label={this.state.variable.unique_code}
                    disabled
                  />
                </div>
              </div>
            )}
            <Box className='ml-1 mt-3'>
              <PanelLabel>App Preview</PanelLabel>
            </Box>
            <Box className=' mt-2'>
              <ChoicePanelPreview
                answerForm
                choices={this.state.choices}
                editorQuestion={this.state.question || 'What question should this card answer?'}
                editorRationale={
                  this.state.rationale ||
                  'Rationale (e.g. severe illness requires stronger antibiotic)'
                }
                editorElements={this.state.examples.concat(this.state.tools)}
                editorTrigger={
                  this.state.trigger
                    ? triggers.find((e) => e.id.toString() === this.state.trigger.toString())
                    : null
                }
                containerCard={false}
                editMode={true}
              />
            </Box>

            <Box style={{ marginBottom: '30px' }}>
              <AppearingSituation
                onInputChange={this.getSelectedTrigger}
                triggerOptions={triggers}
                defaultValue={this.state.trigger}
                moduleId={this.props.moduleId}
                isAnswerCard={true}
              />
            </Box>

            <Box className='ml-1 mr-3'>
              <InputField
                name='question'
                required={true}
                value={this.state.question}
                onChange={this.onChange}
                label={helpInfoData?.question?.label}
                detail={helpInfoData?.question?.detail}
                placeholder={helpInfoData?.question?.placeholder}
                maxLength={helpInfoData?.question?.character_limit}
              />
            </Box>

            <Box className='ml-1 mr-3'>
              <InputField
                name='title'
                required={true}
                value={this.state.title}
                onChange={this.onChange}
                label={helpInfoData?.title?.label}
                detail={helpInfoData?.title?.detail}
                placeholder={helpInfoData?.title?.placeholder}
                maxLength={helpInfoData?.title?.character_limit}
              />
            </Box>

            <Box className='ml-1 mr-3'>
              <InputField
                name='rationale'
                required={true}
                value={this.state.rationale}
                onChange={this.onChange}
                label={helpInfoData?.rationale?.label}
                detail={helpInfoData?.rationale?.detail}
                placeholder={helpInfoData?.rationale?.placeholder}
                maxLength={helpInfoData?.rationale?.character_limit}
              />
            </Box>

            <Box className='ml-1'>
              {helpInfoData['examples'] && (
                <React.Fragment>
                  <StyledFormGroup>
                    <PanelLabel style={{ fontSize: '16px !important' }} for='examples'>
                      {helpInfoData['examples'].label}{' '}
                      <StyledFormHelperText id='component-helper-text'>
                        {helpInfoData['examples'].detail}
                      </StyledFormHelperText>
                    </PanelLabel>
                    <Examples
                      container
                      direction='column'
                      justifyContent='flex-start'
                      alignItems='flex-start'
                    >
                      <div className='example-n-tools-textBox m-4 mt-2'>
                        <ExampleNToolsList
                          moduleId={this.props.moduleId}
                          list={this.state.examples}
                          handleDeleteClick={this.deleteExample}
                          handleListChange={this.updateExample}
                          updateListItems={this.updateExamples}
                        />
                      </div>

                      <div className='row d-flex align-items-center ml-2  mt-2'>
                        <AddLabel className='ml-4 mr-3 mt-2'>Add</AddLabel>
                        <AddButton
                          style={{
                            display: 'inline-flex',
                          }}
                          disableRipple
                          disableFocusRipple
                          startIcon={<AddIcon />}
                          className=' waves-effect waves-light  mr-2'
                          onClick={this.addText}
                        >
                          Text
                        </AddButton>
                        <AddButton
                          style={{ width: '180px', maxWidth: '180px', display: 'inline-flex' }}
                          startIcon={<AddIcon />}
                          className=' waves-effect waves-light mr-2'
                          onClick={this.addInfoBox}
                        >
                          InfoBox / Media
                        </AddButton>
                      </div>
                    </Examples>
                  </StyledFormGroup>
                </React.Fragment>
              )}
            </Box>

            <Box className='ml-1'>
              {helpInfoData['tools'] && (
                <React.Fragment>
                  <StyledFormGroup>
                    <PanelLabel style={{ fontSize: '16px !important' }} for='tools'>
                      {helpInfoData['tools'].label}{' '}
                      <StyledFormHelperText id='component-helper-text'>
                        {helpInfoData['tools'].detail}
                      </StyledFormHelperText>
                    </PanelLabel>
                    <Examples
                      container
                      direction='column'
                      justifyContent='flex-start'
                      alignItems='flex-start'
                    >
                      <div className='example-n-tools-textBox m-4 mt-2'>
                        <ExampleNToolsList
                          moduleId={this.props.moduleId}
                          list={this.state.tools}
                          handleDeleteClick={this.deleteTool}
                          handleListChange={this.updateTool}
                          updateListItems={this.updateTools}
                        />
                      </div>

                      <div className='row d-flex align-items-center ml-2  mt-2'>
                        <AddLabel className='ml-4 mr-3 mt-2'>Add</AddLabel>
                        <AddButton
                          style={{ width: '180px', maxWidth: '180px', display: 'inline-flex' }}
                          startIcon={<AddIcon />}
                          className='waves-effect waves-light mr-2'
                          onClick={this.addToolInfoBox}
                        >
                          InfoBox / Media
                        </AddButton>
                        <AddButton
                          style={{
                            maxWidth: '150px',
                            width: '150px',
                            display: 'inline-flex',
                          }}
                          startIcon={<AddIcon />}
                          className='waves-effect waves-light mr-2'
                          onClick={this.addCalculator}
                        >
                          Calculator
                        </AddButton>
                        {/* <AddButton
                          startIcon={<AddIcon />}
                          className='waves-effect waves-light mr-2'
                          onClick={this.addImage}
                        >
                          Media
                        </AddButton> */}
                      </div>
                    </Examples>
                  </StyledFormGroup>
                </React.Fragment>
              )}
            </Box>

            <Box className='ml-1'>
              {helpInfoData['choices'] && (
                <React.Fragment>
                  <StyledFormGroup>
                    <PanelLabel style={{ fontSize: '16px !important' }} for='choices'>
                      {helpInfoData['choices'].label}{' '}
                      <StyledFormHelperText id='component-helper-text'>
                        {helpInfoData['choices'].detail}
                      </StyledFormHelperText>
                    </PanelLabel>
                    <AnswerSection
                      answers={this.state.choices}
                      addChoice={this.addChoice}
                      updateChoices={this.updateChoices}
                      updateChoice={this.updateChoice}
                      deleteChoice={this.deleteChoice}
                    />
                  </StyledFormGroup>
                </React.Fragment>
              )}
            </Box>

            <Box className='ml-auto'>
              <CancelButton onClick={() => this.props.toggleModal()} className='mr-4'>
                {CANCEL_BUTTON}
              </CancelButton>
              <CreateButton disabled={!this.state.question || hasEmptyName} type='submit'>
                {SAVE_BUTTON}
              </CreateButton>
            </Box>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });
const mapDispatchToProps = (dispatch, ChoiceCardFormProps) => ({
  getUniqueCode: (moduleId) => dispatch(getUniqueCode(moduleId)),
  getCalculators: () => dispatch(getCalculators()),
});
export default withTriggers(connect(mapStateToProps, mapDispatchToProps)(ChoiceCardForm));
