import { Menu } from '@headlessui/react';
import { Icons } from 'components/utils/Icons';
import { debounce } from 'lodash';
import { HTMLAttributes, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { twJoin, twMerge } from 'tailwind-merge';
import { AlgoMoreIcon } from '../../../../assets/icons/algoCardIcons';
import { hasViewOnlyPermission } from '../../../../utils/permissions';
import { PREVIEW_CARD_Z_INDEX } from '../../../../v2/utils/constant';
import { VariablesPreview } from '../../../app-previews/common/variable';
import { ConfirmModal } from '../../../utils/modals/ConfirmModal';
import { ModuleContext } from '../../../utils/module/ModuleContext';
import { Body2 } from '../../../utils/typo';
import { ModuleElementContext } from '../../ModuleBoard';
import { TriggerBadge } from './TriggerBadge';

interface CardContainerProps extends HTMLAttributes<HTMLDivElement> {
  elementId: number;
  onOpenPanel: () => void;
  isPreviewing: boolean;
  isContainer: boolean;
  variables: { type: string; name: string }[];
  hideTrigger?: boolean;
}

export const CardContainer = ({
  children,
  className,
  elementId,
  isPreviewing,
  isContainer,
  onOpenPanel,
  variables,
  hideTrigger,
  ...rest
}: CardContainerProps) => {
  const { module } = useContext(ModuleContext);
  const [deleteModal, setDeleteModal] = useState(false);

  const { deleteUIElement } = useContext(ModuleElementContext);
  const { watch } = useFormContext();
  const triggerId = watch('trigger');
  const hideOutPut = watch('is_hide');

  // due to slide panel transition delay
  const [debouncedPreview, setPreview] = useState(false);
  const setDebouncedPreview = debounce(setPreview, 300);

  useEffect(() => {
    if (isPreviewing) {
      setPreview(isPreviewing);
    } else {
      setDebouncedPreview(isPreviewing);
    }
  }, [isPreviewing]);

  return (
    <div
      className={twJoin(
        'relative mb-4 w-full max-w-[796px]',
        debouncedPreview && PREVIEW_CARD_Z_INDEX
      )}
    >
      <div
        className={twMerge(
          'relative flex flex-col gap-[8px] rounded-md',
          !isContainer && 'bg-white shadow-06',
          isPreviewing ? 'border-[2px] border-primary-600 p-[14px]' : 'group p-[16px]',
          !isContainer && !isPreviewing && 'hover:bg-primary-200',
          className
        )}
        onDoubleClick={() => !hasViewOnlyPermission(module?.type) && onOpenPanel()}
        {...rest}
      >
        <div className='flex items-center gap-2'>
          {!hideTrigger && <TriggerBadge isContainer={isContainer} triggerId={triggerId} />}
          {hideOutPut && (
            <>
              <Icons.EyeSlashIcon className='fill-gray' />
              <Body2 className='text-gray-700'>Hidden in link</Body2>
            </>
          )}
        </div>
        <div className='absolute right-[16px] hidden group-hover:block'>
          <Menu>
            {!hasViewOnlyPermission(module?.type) && (
              <Menu.Button>
                <AlgoMoreIcon />
              </Menu.Button>
            )}
            <Menu.Items className='absolute z-10 m-0 w-[100px] rounded border border-gray-500 bg-white shadow-md'>
              <Menu.Item
                className='mb-0 h-[40px] w-[100px] px-3 py-[10px] hover:bg-primary-bg-strong hover:text-white'
                as='p'
                onClick={onOpenPanel}
              >
                <Body2>Edit</Body2>
              </Menu.Item>
              <Menu.Item
                as='p'
                className='mb-0 h-[40px] w-[100px] px-3 py-[10px] hover:bg-primary-bg-strong hover:text-white'
                onClick={() => setDeleteModal(true)}
              >
                <Body2>Delete</Body2>
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
        {children}
      </div>

      {!isContainer && <VariablesPreview variables={variables} />}

      <ConfirmModal
        preset='delete'
        open={deleteModal}
        content='Are you sure you want to delete this card?'
        toggleModal={() => setDeleteModal((prev) => !prev)}
        performAction={() => {
          deleteUIElement(elementId);
          setDeleteModal(false);
        }}
      />
    </div>
  );
};
