import { Box, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import NavBar from 'components/bars/NavBar';
import Footer from 'components/layout/Footer';
import { multiSelectstyle } from 'components/module-detail/container/ContainerCardForm';
import {
  ActionButton,
  ActionContainer,
  CancelButton,
  CreateTeamContainer,
  FormTitle,
} from 'components/teams/team-settings/styles';
import History from 'components/utils/History';
import { globalStyles } from 'components/utils/bootsrap-and-materialize';
import ButtonProgress from 'components/utils/button-progress';
import InputField from 'components/utils/form-input/field';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { GreenCheckbox } from 'components/utils/styled-components/FormStyle';
import _ from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Form } from 'reactstrap';
import { TeamWithMates } from '../../api/team';
import { CHANNELS_ROUTE, CHANNEL_API_URL } from '../../constants';
import { Authentication, withAuthentication } from '../../hooks/useAuthentication';
import { withTeam } from '../../hooks/useTeam';

interface ChannelFormState {
  isRedirect: boolean;
  id: string;
  name: string;
  password: string;
  modules: any;
  calculators: any;
  channel_code: string;
  description: string;
  is_institutional: boolean;
  is_generic: boolean;
  isLoading: boolean;
  moduleOptions: any;
  calculatorOptions: any;
}

interface ChannelFormProps {
  formState: any;
  modulesState: any;
  mirrorState: any;
  location: any;
  teamCalculatorState: any;
  length: number;
  authentication: Authentication;
  team: TeamWithMates;
}

class ChannelForm extends Component<ChannelFormProps, ChannelFormState> {
  constructor(props: any) {
    super(props);

    // state element name should be same as the name of input elements in form
    this.state = {
      isRedirect: false,
      id: '',
      name: '',
      password: '',
      modules: [],
      calculators: [],
      channel_code: '',
      description: '',
      is_institutional: false,
      is_generic: false,
      isLoading: false,
      moduleOptions: [],
      calculatorOptions: [],
    };
  }

  helpInfoData = getHelpInfoData('Channel');

  componentDidMount() {
    let modules = (this.props.modulesState?.modules as any[])?.map((module: any) => ({
      ...module,
    }));
    let calculators = _.cloneDeep([...this.props.teamCalculatorState?.teamCalculators]);

    let codes = [...modules, ...calculators].map((item) => {
      if (!item.last_synced) {
        item.name = item.name + ' (Not synced yet)';
      }
      return item.code;
    });

    this.props.mirrorState?.mirrors?.forEach((mirror: any) => {
      this.processMirrorDetails(mirror, codes, modules, calculators);
    });
    this.setState({ moduleOptions: modules, calculatorOptions: calculators });

    if (this.props.location?.state) {
      let channel = this.props.location.state.channelObj;

      this.setState({
        id: channel.id,
        password: channel.password ? channel.password : '',
        name: channel.name ? channel.name : '',
        modules: channel.modules,
        calculators: channel.calculators,
        channel_code: channel.channel_code ? channel.channel_code : '',
        description: channel.description ? channel.description : '',
        is_institutional: channel.is_institutional,
        is_generic: channel.is_generic,
      });
    }
  }
  processMirrorDetails = (mirror, codes, modules, calculators) => {
    const item = {
      ...mirror?.details,
      name: mirror?.name + (!mirror?.details?.last_synced ? ' (Not synced yet)' : ''),
    };

    if (
      (mirror.is_mirror_of === 'calculator' || mirror.is_mirror_of === 'algo') &&
      !codes.includes(item.code)
    ) {
      const targetArray = mirror.is_mirror_of === 'calculator' ? calculators : modules;
      targetArray.push(item);
    }
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'channel_code') {
      this.setState({
        [e.target.name]: e.target.value.toLowerCase().replace(/[^a-zA-Z0-9]/g, ''),
      } as Pick<ChannelFormState, any>);
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      } as Pick<ChannelFormState, any>);
    }
  };

  toggle = (varName: any) => {
    this.setState(
      (previous) =>
        ({
          [varName]: !previous[varName],
        }) as Pick<ChannelFormState, any>
    );
  };

  resetState = () => {
    this.setState({
      isRedirect: false,
      id: '',
      name: '',
      password: '',
      modules: '',
      channel_code: '',
    });
  };

  getPayload = () => {
    return {
      id: this.state.id,
      name: this.state.name,
      password: this.state.password,
      is_generic: this.state.is_generic,
      description: this.state.description,
      team: this.props.team.id,
      is_institutional: this.state.is_institutional,
      modules: this.state.modules.map((module) => module.id),
      calculators: this.state.calculators.map((calc) => calc.id),
      channel_code: this.state.channel_code.replace(/\./g, '-').replace(' ', '-').toLowerCase(),
    };
  };

  createChannel = (e: any) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    axios.post(CHANNEL_API_URL, this.getPayload()).then(() => {
      this.setState({ isRedirect: true, isLoading: false });
    });
  };

  editChannel = (e: any) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    axios.put(CHANNEL_API_URL + this.state.id + '/', this.getPayload()).then(() => {
      this.setState({ isRedirect: true, isLoading: false });
    });
  };

  onMultiSelect = (stateName: string, selectedList: any, selectedItem: any) => {
    this.setState({
      [stateName]: selectedList,
    } as Pick<ChannelFormState, any>);
  };

  onKeyPress = (e: any) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  render() {
    const helpInfoData = this.helpInfoData;
    const isStaff = this.props.authentication.user?.is_staff;
    if (this.state.isRedirect) {
      return <Navigate to={CHANNELS_ROUTE} replace />;
    }
    // Inject types to the component that is a js file yet it should be removed later on
    const GreenCheckboxEnhanced = GreenCheckbox as React.JSXElementConstructor<{
      checked: boolean;
      onChange: () => void;
      name: string;
    }>;
    return (
      <>
        {/* // TODO: Remove the global style for materialize and bootstrap in future */}
        {globalStyles}
        <NavBar />
        <CreateTeamContainer>
          <Form
            onKeyPress={this.onKeyPress}
            onSubmit={this.props.location?.state ? this.editChannel : this.createChannel}
          >
            <FormTitle>
              {this.props.location?.state ? `Update Channel` : `Create Channel`}
            </FormTitle>
            <Box mb={3}>
              <InputField
                name='channel_code'
                required={true}
                value={this.state.channel_code}
                onChange={this.onChange}
                label={helpInfoData?.channel_code?.label}
                detail={helpInfoData?.channel_code?.detail}
                placeholder={helpInfoData?.channel_code?.placeholder}
                maxLength={helpInfoData?.channel_code?.character_limit}
              />
            </Box>

            <Box mb={3}>
              <InputField
                name='name'
                required={true}
                value={this.state.name}
                onChange={this.onChange}
                label={helpInfoData?.name?.label}
                detail={helpInfoData?.name?.detail}
                placeholder={helpInfoData?.name?.placeholder}
                maxLength={helpInfoData?.name?.character_limit}
              />
            </Box>

            <Box mb={3}>
              <InputField
                name='password'
                value={this.state.password}
                onChange={this.onChange}
                label={helpInfoData?.password?.label}
                detail={helpInfoData?.password?.detail}
                placeholder={helpInfoData?.password?.placeholder}
                maxLength={helpInfoData?.password?.character_limit}
              />
            </Box>

            <Box mb={3}>
              <InputField
                name='description'
                value={this.state.description}
                onChange={this.onChange}
                label={helpInfoData?.description?.label}
                detail={helpInfoData?.description?.detail}
                placeholder={helpInfoData?.description?.placeholder}
                maxLength={helpInfoData?.description?.character_limit}
              />
            </Box>

            <Box>
              <StyledFormControlLabel
                control={
                  <GreenCheckboxEnhanced
                    checked={this.state.is_institutional}
                    onChange={() => this.toggle('is_institutional')}
                    name='is_institutional'
                  />
                }
                label={helpInfoData?.is_institutional?.label}
              />
            </Box>

            {isStaff && (
              <Box mb={3}>
                <StyledFormControlLabel
                  control={
                    <GreenCheckboxEnhanced
                      checked={this.state.is_generic}
                      onChange={() => this.toggle('is_generic')}
                      name='is_generic'
                    />
                  }
                  label={helpInfoData?.is_generic?.label}
                />
              </Box>
            )}

            <Box mb={3}>
              <FieldLabel
                label={helpInfoData?.modules?.label}
                detail={helpInfoData?.modules?.detail}
              />
              <MultiSelectBox length={this.state.modules?.length || 0}>
                <Multiselect
                  name='modules'
                  options={this.state.moduleOptions}
                  selectedValues={this.state.modules}
                  onSelect={(selectedList, selectedItem) =>
                    this.onMultiSelect('modules', selectedList, selectedItem)
                  }
                  onRemove={(selectedList, removedItem) =>
                    this.onMultiSelect('modules', selectedList, removedItem)
                  }
                  isObject={true}
                  style={multiSelectstyle}
                  displayValue='name'
                />
              </MultiSelectBox>
            </Box>

            <Box mb={3}>
              <FieldLabel
                label={helpInfoData?.calculators?.label}
                detail={helpInfoData?.calculators?.detail}
              />
              <MultiSelectBox length={this.state.calculators?.length || 0}>
                <Multiselect
                  name='calculators'
                  options={this.state.calculatorOptions}
                  selectedValues={this.state.calculators}
                  onSelect={(selectedList, selectedItem) =>
                    this.onMultiSelect('calculators', selectedList, selectedItem)
                  }
                  onRemove={(selectedList, removedItem) =>
                    this.onMultiSelect('calculators', selectedList, removedItem)
                  }
                  isObject={true}
                  style={multiSelectstyle}
                  displayValue='name'
                />
              </MultiSelectBox>
            </Box>

            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <ActionContainer>
                <CancelButton onClick={() => History.goBack()}> Cancel </CancelButton>
                <ActionButton type='submit' disabled={this.state.isLoading}>
                  {this.props.location?.state ? 'Update' : 'Create'}
                  <ButtonProgress isLoading={this.state.isLoading} />
                </ActionButton>
              </ActionContainer>
            </Box>
          </Form>
        </CreateTeamContainer>
        <Box mb={3} />
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  formState: state.formState,
  mirrorState: state.mirrorState,
  modulesState: state.modulesState,
  teamCalculatorState: state.teamCalculatorState,
});

export default withTeam(withAuthentication(connect(mapStateToProps)(ChannelForm)));

export const StyledFormControlLabel = withStyles({
  label: {
    color: '#37352F',
  },
})(FormControlLabel);

const MultiSelectBox = withStyles({
  root: {
    background: '#ffffff',
    border: '1px solid #cccccc',
    boxSizing: 'border-box',
    borderRadius: '5px',
    height: (props: any) => (props.length > 0 ? 'auto' : '47px'),
    paddingRight: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px',
    paddingTop: (props) => (props.length > 0 ? '15px' : '5px'),
  },
})(Box);
