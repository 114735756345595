import { useQuery } from '@tanstack/react-query';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { format, isToday } from 'date-fns';
import { ReactNode, useMemo, useState } from 'react';
import { NotificationHistory, notificationAPI } from '../../api/notification';
import { ROLES } from '../../api/team';
import { useTeam } from '../../hooks/useTeam';
import Button from '../utils/Button';
import { Icons } from '../utils/Icons';
import { H2, H4 } from '../utils/typo';
import { NotificationDetailModal } from './NotificationDetailModal';
import { SendNotificationModal } from './SendNotificationModal';

export const NotificationHistoryList = () => {
  const { team } = useTeam();
  const isTeamAdmin = team.current_teammate?.role === ROLES.Admin;
  const [detailOpen, setDetailOpen] = useState<boolean>(false);
  const [notification, setNotification] = useState<NotificationHistory | undefined>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { data } = useQuery({
    queryKey: ['notifications'],
    queryFn: notificationAPI.getNotificationHistories,
    initialData: [],
  });

  const onDetail = (notificationId: number) => {
    setNotification(data.find((notification) => notification.id === notificationId));
    setDetailOpen(true);
  };

  const columns = useMemo<ColumnDef<NotificationHistory>[]>(
    () => [
      {
        id: 'channels',
        header: 'Channels',
        accessorKey: 'channels',
        cell: (cell) => <div className='line-clamp-2'>{cell.renderValue() as ReactNode}</div>,
      },
      {
        id: 'title',
        header: 'Title',
        accessorKey: 'title',
        cell: (cell) => <div className='line-clamp-2'>{cell.renderValue() as ReactNode}</div>,
      },
      {
        id: 'message',
        header: 'Message',
        accessorKey: 'message',
        cell: (cell) => <div className='line-clamp-2'>{cell.renderValue() as ReactNode}</div>,
      },
      {
        id: 'time',
        header: 'Time',
        accessorKey: 'created',
        cell: (cell) => {
          const date = cell.renderValue() as string;
          const time = isToday(date) ? format(date, 'hh:mm aa') : format(date, 'P');
          return <div className='line-clamp-2'>{time as ReactNode}</div>;
        },
      },
    ],
    []
  );
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data: data,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div>
        <div className='flex items-center justify-between py-[40px]'>
          <H2>Notification History</H2>
          <div>
            <Button onClick={() => setModalOpen(true)} disabled={!isTeamAdmin}>
              <div className='flex gap-[2px]'>
                <Icons.Plus />
                <span>Send Notification</span>
              </div>
            </Button>
          </div>
        </div>
        <div>
          <table className='w-full'>
            {getHeaderGroups().map((headerGroup) => (
              <thead className='border-b-2 border-gray-300' key={headerGroup.id}>
                <tr>
                  {headerGroup.headers.map((header) =>
                    header.isPlaceholder ? null : (
                      <th key={header.id} className='px-[8px] py-[16px] text-left'>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    )
                  )}
                </tr>
              </thead>
            ))}
            <tbody>
              {getRowModel().rows.length == 0 ? (
                <tr>
                  <td colSpan={4} className='my-auto h-[300px] text-center text-gray-700'>
                    <H4>No History.</H4>
                  </td>
                </tr>
              ) : (
                getRowModel().rows.map((row) => (
                  <tr
                    className='cursor-pointer border-b border-gray-300'
                    onClick={() => onDetail(row.original.id)}
                    key={row.id}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className='px-[8px] py-[12px]'>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <NotificationDetailModal
        open={detailOpen}
        onClose={() => setDetailOpen(false)}
        notification={notification}
      />
      <SendNotificationModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};
