import Editor, { useMonaco } from '@monaco-editor/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { teamAPI } from 'api/team';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import Button from 'components/utils/Button';
import Input from 'components/utils/Input';
import RequiredMark from 'components/utils/requiredMark';
import { CustomToast } from 'components/utils/toast-message';
import { H1, H4, H5 } from 'components/utils/typo';
import { isEmpty } from 'lodash';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CANCEL_BUTTON, CREATE, UPDATE } from '../../constants';
import { useTeam } from '../../hooks/useTeam';

interface EHRConfigVariable {
  title: string;
  jsonConfig: any;
  team: number;
}

interface ConfigFormProps {
  configId?: number;
  closeModal: () => void;
}

const PROD_WARNING_THEME = 'prod-warning-theme';

const ConfigForm = ({ configId, closeModal }: ConfigFormProps) => {
  const monaco = useMonaco();
  const [title, setTitle] = useState('');
  const isProdConfig = useMemo(() => title.toLowerCase().startsWith('prod'), [title]);
  const { team } = useTeam();
  const queryClient = useQueryClient();

  const { data: ehrConfig, isLoading: isConfigLoading } = useQuery({
    queryKey: ['ehrConfig', configId],
    queryFn: teamAPI.getEHRConfig,

    enabled: !!configId,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const { mutate, isPending: isMutating } = useMutation({
    mutationFn: !configId
      ? teamAPI.postEHRConfig
      : (data) => teamAPI.patchEHRConfig(data, configId),

    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries({ queryKey: ['teamEHRConfigs', team.id] });
    },
  });

  const { handleSubmit, register, watch, setValue, reset } = useForm<EHRConfigVariable>({
    defaultValues: {
      title: '',
      jsonConfig: '{}',
      team: team.id,
    },
  });

  useEffect(() => {
    if (!ehrConfig || isConfigLoading) return;
    setTitle(ehrConfig.title);

    reset({
      title: ehrConfig.title,
      jsonConfig: JSON.stringify(ehrConfig.json_config, null, 2),
      team: ehrConfig.team,
    });
  }, [isConfigLoading, ehrConfig]);

  useEffect(() => {
    if (monaco) {
      monaco.editor.defineTheme(PROD_WARNING_THEME, {
        base: 'vs',
        inherit: true,
        rules: [],
        colors: {
          'editor.background': '#fcbdbd',
        },
      });
    }
  }, [monaco]);

  const onSubmit = async (data) => {
    if (isMutating) return;
    try {
      data['jsonConfig'] = JSON.parse(data.jsonConfig);
    } catch (e) {
      toast.error(CustomToast, { data: 'Invalid JSON Config' });

      return;
    }

    if (isEmpty(data.jsonConfig)) {
      toast.error(CustomToast, { data: 'JSON Config cannot be empty' });
      return;
    }

    mutate({
      ...data,
      json_config: data.jsonConfig,
    });
  };

  return (
    <div className='!mt-5'>
      <H1>EHR Config</H1>
      {isProdConfig && <H4 className='text-error'>WARNING: PRODUCTION CONFIGURATION FILE</H4>}
      {!!configId && isConfigLoading ? (
        <div className='flex min-h-screen items-center justify-center'>
          <LoadingSpinner />
        </div>
      ) : (
        <form className='!mt-5 flex flex-col gap-6' onSubmit={handleSubmit(onSubmit)}>
          <div>
            <FieldLabel label='Title' required />
            <Input
              placeholder='Enter Title'
              maxLength={50}
              required
              {...register('title', {
                maxLength: 50,
                required: true,
                onChange: (e: ChangeEvent<HTMLInputElement>) => {
                  setTitle(e.target.value);
                },
              })}
            />
            <div className='!mt-1 text-right text-[12px]'>{title.length}/50 characters</div>
          </div>

          <div>
            <FieldLabel label='JSON Config' required />
            <div className='mt-2 rounded-md border border-solid'>
              <Editor
                height='400px'
                language='json'
                value={watch('jsonConfig')}
                theme={isProdConfig ? PROD_WARNING_THEME : ''}
                onChange={(e) => setValue('jsonConfig', e)}
                options={{
                  automaticLayout: true,
                  autoClosingBrackets: 'always',
                  autoClosingQuotes: 'always',
                  formatOnPaste: true,
                  formatOnType: true,
                  scrollBeyondLastLine: false,
                }}
              />
            </div>
          </div>

          <div className='!mt-10 flex justify-end'>
            <div className='flex'>
              <Button.Reverse
                onClick={closeModal}
                className='px-5 font-bold uppercase'
                type='button'
              >
                {CANCEL_BUTTON}
              </Button.Reverse>
              <Button className='px-5 font-bold uppercase' type='submit' disabled={isMutating}>
                {isMutating ? <LoadingSpinner size='small' /> : !!configId ? UPDATE : CREATE}
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

const FieldLabel = ({ label, required }) => (
  <div className='flex gap-1'>
    <H5>{label}</H5>
    {required && <RequiredMark />}
  </div>
);

export default ConfigForm;
