import { XCircleIcon } from '@heroicons/react/24/solid';
import { useMutation } from '@tanstack/react-query';
import { PostSourceData, moduleAPI } from 'api/module';
import Button from 'components/utils/Button';
import Input from 'components/utils/Input';
import Label from 'components/utils/Label';
import CircleButton from 'components/utils/RadioButton';
import { Modal } from 'components/utils/modals/Modal';
import { ChangeEvent, useState } from 'react';

export interface ISource {
  id: number;
  name: string;
  website_link: string;
  pdf_file: string;
}

interface AddSourceModalProps {
  open: boolean;
  onClose: () => void;
  onAdd: (source: ISource) => void;
}

const AddSourceModal = ({ open, onClose, onAdd }: AddSourceModalProps) => {
  const { mutate } = useMutation<PostSourceData, null, FormData>({
    mutationFn: moduleAPI.postSource,
    onSuccess: (data) => {
      onClose();
      onAdd(data);
    },
  });
  const [sourceType, setSourceType] = useState<'pdf' | 'link'>('pdf');
  const [sourceName, setSourceName] = useState('');
  const [pdf, setPdf] = useState<File>();
  const [link, setLink] = useState('');

  const onSelectPDF = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length < 1) return;
    setSourceName(e.target.files?.[0].name || '');
    setPdf(e.target.files?.[0] || undefined);
  };

  const clearFile = () => {
    setPdf(undefined);
    setSourceName('');
  };

  const onTypeChanged = (newType: 'pdf' | 'link') => {
    setSourceType(newType);
    clearFile();
    setLink('');
  };

  const onLinkChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const newLink = e.target.value;
    setLink(newLink);
    setSourceName(newLink);
  };

  const onSave = () => {
    if ((!pdf && !link) || !sourceName) return;
    const formdata = new FormData();
    formdata.set('name', sourceName);
    formdata.set('website_link', link);
    if (pdf && typeof pdf === 'object') {
      formdata.set('pdf_file', pdf);
    }
    mutate(formdata);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Head onClose={onClose}>Add Source</Modal.Head>
      <Modal.Body>
        <div className='space-y-4'>
          <div className='ml-[26px] space-y-4'>
            <div className='relative flex'>
              <div className='cursor-pointer' onClick={() => onTypeChanged('pdf')}>
                <div className='absolute left-0 top-1/2 -ml-[26px] -translate-y-1/2'>
                  {sourceType === 'pdf' ? <CircleButton.Selected /> : <CircleButton />}
                </div>
                <div>PDF file</div>
              </div>
            </div>
            {sourceType === 'pdf' &&
              (pdf ? (
                <div className='flex items-center justify-between rounded border border-gray-300 px-[12px] py-[10px]'>
                  <div>{pdf.name}</div>
                  <XCircleIcon
                    className='h-[20px] w-[20px] cursor-pointer text-gray-400'
                    onClick={clearFile}
                  />
                </div>
              ) : (
                <Label>
                  <div className='relative rounded bg-primary-500 px-[12px] py-[10px] font-medium text-white hover:bg-primary-600'>
                    Attach File
                  </div>
                  <Input
                    className='hidden'
                    type='file'
                    name='file'
                    onChange={onSelectPDF}
                    accept='application/pdf'
                  />
                </Label>
              ))}
            <div className='relative flex'>
              <div className='cursor-pointer' onClick={() => onTypeChanged('link')}>
                <div className='absolute left-0 top-1/2 -ml-[26px] -translate-y-1/2'>
                  {sourceType === 'link' ? <CircleButton.Selected /> : <CircleButton />}
                </div>
                <div>Link</div>
              </div>
            </div>
            {sourceType === 'link' && (
              <Input placeholder='Enter website link' onChange={onLinkChanged} />
            )}
          </div>
          <div>
            <div>Name of the source</div>
            <Input value={sourceName} onChange={(e) => setSourceName(e.target.value)} />
          </div>
        </div>
        <div className='mt-8 flex justify-end'>
          <div className='flex space-x-2'>
            <Button.Reverse type='button' className='px-8 py-2' onClick={onClose}>
              Cancel
            </Button.Reverse>
            <Button
              type='button'
              className='px-8 py-2'
              onClick={onSave}
              disabled={(!pdf && !link) || !sourceName}
            >
              Add
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddSourceModal;
