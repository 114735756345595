import { JSONContent } from '@tiptap/react';
import { WithLastUpdated } from '../../../api/resources';

export interface APIResource extends WithLastUpdated {
  id?: number;
  title: string;
  url_json: JSONContent | null;
  content_type: ContentTypes;
  header: JSONContent;
  body: JSONContent;
  method: HTTPMethods;
  output_type: OutputTypes;
  output_type_definition: string;
  output_you_want_to_use: string;
}

export enum ContentTypes {
  JSON = 'application/json',
  XML = 'application/xml',
  FORM_URLENCODED = 'application/x-www-form-urlencoded',
  MULTIPART_FORM = 'multipart/form-data',
  TEXT_PLAIN = 'text/plain',
  TEXT_HTML = 'text/html',
}

export enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum OutputTypes {
  STRING = 'string',
  OBJECT = 'object',
  // ARRAY = 'array',
}

export interface APIResourceFormPayload extends Omit<APIResource, 'last_updated'> {
  module?: number;
  calculator?: number;
}
