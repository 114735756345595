import TryAvoBuilder from 'components/accounts/TryAvoBuilder';
import ForgotPassword from 'components/accounts/forgot-password/ForgotPassword';
import Signin from 'components/accounts/signin/Signin';
import Signup from 'components/accounts/signup/Signup';
import { LOGIN_ROUTE, SIGNUP_ROUTE } from '../../constants';

const AuthRoutes = [
  {
    type: 'PUBLIC',
    path: LOGIN_ROUTE,
    component: Signin,
  },
  {
    type: 'PUBLIC',
    path: '/try-avo-builder',
    component: TryAvoBuilder,
  },
  {
    type: 'PUBLIC',
    path: SIGNUP_ROUTE,
    component: Signup,
  },
  {
    type: 'PUBLIC',
    path: '/auth/forgotPassword',
    component: ForgotPassword,
  },
];

export default AuthRoutes;
