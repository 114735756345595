import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import { ACCEPT_INVITATION_API_URL, GET_INVITE_EMAIL_API_URL } from '../constants';

interface InvitationData {
  email: string;
  first_name?: string;
  last_name?: string;
  user_exists: boolean;
}

export const invitationAPI = {
  getInvitation: ({ queryKey }: QueryFunctionContext) => {
    const API_URL = `${GET_INVITE_EMAIL_API_URL}/${queryKey[1]}`;
    return axios.get<InvitationData>(API_URL).then((res) => res.data);
  },
  acceptInvitation: (inviteCode: string) => {
    return axios.put(`${ACCEPT_INVITATION_API_URL}/${inviteCode}`);
  },
};
