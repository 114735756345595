import { Box, Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { setIsFormDisabled } from 'actions/miscellaneous/formHelperAction';
import AddIcon from 'assets/icons/addIcon';
import axios from 'axios';
import MediaCardPreview from 'components/app-previews/card-previews/MediaCardPreview';
import InfoBoxForm from 'components/resources/infobox/infoBoxForm';
import RefManagerForm from 'components/resources/reference-manager/refManagerForm';
import AddValueItem from 'components/utils/AddValueItem';
import AppearingSituation from 'components/utils/AppearingSituation';
import ListItem from 'components/utils/ListItem';
import InputField from 'components/utils/form-input/field';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { compareCardPosition } from 'components/utils/general/compareCardPosition';
import 'components/utils/modals/modal.css';
import Overlay from 'components/utils/overlay';
import { RichTextMenuButtonTypes, SuggestionTypes } from 'components/utils/tiptap/tiptapInterfaces';
import { CustomToast } from 'components/utils/toast-message';
import { debounce } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';
import { Form, FormGroup } from 'reactstrap';
import { getNumerics, getSuggestions, getVariables } from 'utils/suggestions';
import { ANSWER_CARD_API_URL, CANCEL_BUTTON, MODULE_TYPES, SAVE_BUTTON } from '../../constants';

import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import { ToolbarButton } from 'components/utils/draftJS/utils';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import {
  AddButton,
  AddLabel,
  CancelButton,
  CreateButton,
  CreateRefInfoboxButton,
  Examples,
  InfoboxRefMenu,
  InfoboxRefMenuItem,
  PanelLabel,
  StyledFormGroup,
} from 'components/utils/styled-components/FormStyle';
import { RADIOLOGY_REPORT_TYPE } from '../../hooks/useEHRVariables';
import { withTriggers } from '../../hooks/module/resources/useTriggers';

const richTextButtonsShowList: RichTextMenuButtonTypes[] = [
  'textStyles',
  'bold',
  'italic',
  'highlight',
  'bulletList',
  'orderedList',
  'more',
];

interface MediaCardFormProps {
  formState: any;
  mediaCardId: any;
  triggerState: any;
  moduleId: any;
  answerPageId: any;
  toggleModal: Function;
  resetAnsCards: Function;
  mediaState: any;
  setIsFormDisabled: (val) => void;
}
interface MediaCardFormState {
  card_text: any;
  new_card_text_content: any;
  card_text_json_tiptap: any;
  card_title: any;
  answer_page: any;
  refInfoDropdown: any;
  trigger: any;
  textEditorsuggestions: any[];
  variables: any[];
  numerics: any[];
  media_items: any[];
  mediaPreview: any[];
  modal: boolean;
  refModal: boolean;
  infoModal: boolean;
  mediaModal: boolean;
  showError: boolean;
  activeStep: any;
  temp_text: any;
}
class MediaCardForm extends Component<MediaCardFormProps, MediaCardFormState> {
  debTextChangeTiptap: any;
  constructor(props: MediaCardFormProps) {
    super(props);

    // state element name should be same as the name of input elements in form
    this.state = {
      card_text: '',
      new_card_text_content: '',
      card_text_json_tiptap: {},
      card_title: '',
      answer_page: '',
      refInfoDropdown: null,
      trigger: '',
      textEditorsuggestions: [],
      variables: [],
      numerics: [],
      media_items: [],
      mediaPreview: [],
      modal: false,

      refModal: false,
      infoModal: false,
      mediaModal: false,
      showError: false,
      activeStep: 0,
      temp_text: null,
    };
    this.debTextChangeTiptap = debounce(this.onTextChangeTiptap, 300);
  }

  onTextChangeTiptap = (editor) => this.setState({ card_text_json_tiptap: editor.getJSON() });

  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleStepChange = (step) => this.setState({ activeStep: step });

  helpInfoData = getHelpInfoData('TextCard');

  componentDidMount() {
    this.props.setIsFormDisabled(false);
    this.setState({
      showError: false,
    });

    if (!this.props.mediaCardId) {
      let alwayOnTrigger = this.props.triggerState.triggers.find(
        (data) => data.title === 'Always On'
      );
      this.setState({ trigger: alwayOnTrigger ? alwayOnTrigger.id : '' });
    }

    this.populateSuggestions();

    // for update
    if (this.props.mediaCardId) {
      axios.get(ANSWER_CARD_API_URL + this.props.mediaCardId + '/').then((res) => {
        let media_items: Array<{}> = [];
        let mediaPreview: string[] = [];
        res.data.media_items.map((item) => mediaPreview.push(item.image));
        res.data.media_items
          .filter((item) => item.section === 'T')
          .sort(compareCardPosition)
          .forEach((item) => {
            media_items.push(Object.assign(item.image, { type: 'image' }));
          });

        this.setState({
          card_text: res.data.card_text || '',
          new_card_text_content: res.data.new_card_text_content,
          card_text_json_tiptap: res.data.card_text_json_tiptap,
          card_title: res.data.card_title || '',
          answer_page: res.data.answer_page,
          trigger: res.data.trigger,
          temp_text: res.data.new_card_text_content,
          media_items: media_items,
          mediaPreview: mediaPreview,
        });
      });
    } else this.setState({ answer_page: this.props.answerPageId });
  }

  populateSuggestions = async () => {
    const suggestions = await getSuggestions(this.props.moduleId);
    const variables = await getVariables(this.props.moduleId);
    const numerics = await getNumerics(this.props.moduleId);
    this.setState({ textEditorsuggestions: suggestions, variables, numerics });
  };

  onChange = (e) =>
    this.setState({ [e.target.name]: e.target.value } as Pick<MediaCardFormState, any>);

  // updates the state on field input
  onTextChange = (e) => {
    let text = '';
    // eslint-disable-next-line
    e.blocks.map((block) => {
      text += block.text + '\n';
    });

    this.setState({
      card_text: text,
      new_card_text_content: e,
    });
  };

  toggleModal = (modalName) => {
    this.setState(
      (prev) =>
        ({
          [modalName]: !prev[modalName],
        }) as Pick<MediaCardFormState, any>
    );
  };

  getSelectedTrigger = (data) => this.setState({ trigger: data });

  addImage = (images) => {
    let media_items = this.state.media_items;
    let mediaPreview = this.state.mediaPreview;

    images.forEach((item) => {
      let image = {
        id: item.id,
        representation_phrase: item.representation_phrase,
        type: 'image',
        src: item.image,
      };
      media_items = media_items.concat(image);
      mediaPreview = mediaPreview.concat(item);
    });
    this.setState({ media_items, mediaPreview });
  };

  updateTools = (newList) => {
    this.setState({ media_items: newList });
  };

  deleteTool = (tool) => {
    let media_items = this.state.media_items.filter((el) => el.id !== tool.id);
    this.setState({ media_items });
  };

  getPayload = () => {
    return {
      card_text: this.state.card_text,
      new_card_text_content: this.state.new_card_text_content,
      card_text_json_tiptap: this.state.card_text_json_tiptap,
      card_title: this.state.card_title,
      answer_page: this.state.answer_page,
      trigger: this.state.trigger === 'notAssigned' ? null : this.state.trigger,
      media_items: this.state.media_items,
      card_type: 'M',
    };
  };

  createAnsCard = (e) => {
    e.preventDefault();

    if (this.state.media_items.length === 0) {
      toast.error(CustomToast, { data: 'At least one media item is required' });
      return;
    }

    // close modal
    if (this.state.card_text.length > 1) {
      let payload = this.getPayload();
      this.props.toggleModal();

      axios.post(ANSWER_CARD_API_URL, payload).then(() => {
        // reset answer cards
        this.props.resetAnsCards();
      });
    } else
      this.setState({
        showError: true,
      });
  };

  editAnsCard = (e) => {
    e.preventDefault();

    if (this.state.media_items.length === 0) {
      toast.error(CustomToast, { data: 'At least one media item is required' });
      return;
    }

    // close modal
    if (this.state.card_text.length > 1) {
      let payload = this.getPayload();
      this.props.toggleModal();
      axios.put(ANSWER_CARD_API_URL + this.props.mediaCardId + '/', payload).then(() => {
        // reset answer cards list
        this.props.resetAnsCards();
      });
    } else
      this.setState({
        showError: true,
      });
  };

  onKeyPress = (e) => {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  };

  render() {
    const helpInfoData = this.helpInfoData;
    const card_text_content = this.state.temp_text;
    const isFormDisabled = this.props?.formState?.isFormDisabled || false;
    const triggers = [
      ...this.props.triggerState.triggers,
      ...this.props.triggerState.candidate_triggers,
    ];
    const infoBoxFormProps = {
      isFullHeight: true,
      createModal: true,
      toggleModal: () => this.toggleModal('infoModal'),
      savePosition: () => [],
      populateSuggestions: this.populateSuggestions,
    };
    const refMangerFormProps = {
      toggleModal: () => this.toggleModal('refModal'),
      savePosition: () => [],
      populateSuggestions: this.populateSuggestions,
    };

    return (
      <div className='row'>
        <Overlay show={isFormDisabled} />
        <div className='side-panel-form'>
          <Form
            autoComplete='off'
            onSubmit={this.props.mediaCardId ? this.editAnsCard : this.createAnsCard}
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 20,
              pointerEvents: isFormDisabled ? 'none' : 'auto',
            }}
          >
            <Box className='row ml-1 mt-3'>
              <PanelLabel style={{ fontSize: '16px !important' }} for='title'>
                App Preview
              </PanelLabel>
            </Box>
            <Box className='ml-1 mt-3'>
              <MediaCardPreview
                title={this.state.card_title}
                text={this.state.card_text}
                media={this.state.mediaPreview}
                media_text_json={this.state.new_card_text_content}
                media_text_json_tiptap={this.state.card_text_json_tiptap}
              />
            </Box>

            <Box className='ml-1 mt-3'>
              <AppearingSituation
                conditionLabel='Trigger (Optional)'
                contentClass='center-modal'
                onInputChange={this.getSelectedTrigger}
                triggerOptions={triggers}
                defaultValue={this.state.trigger}
                moduleId={this.props.moduleId}
                isAnswerCard={true}
              />
            </Box>
            <Box className='ml-1 mt-3'>
              <InputField
                name='card_title'
                required={true}
                value={this.state.card_title}
                onChange={this.onChange}
                label={helpInfoData?.card_title?.label}
                detail={helpInfoData?.card_title?.detail}
                placeholder={helpInfoData?.card_title?.placeholder}
                maxLength={helpInfoData?.card_title?.character_limit}
              />
            </Box>

            <Box className='ml-1'>
              {helpInfoData['media'] && (
                <StyledFormGroup>
                  <FieldLabel
                    detail={helpInfoData?.media?.detail}
                    label={helpInfoData?.media?.label}
                  />
                  <Examples
                    container
                    direction='column'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                  >
                    <div className='new-textBox m-4 mt-2'>
                      <ListItem
                        list={this.state.media_items}
                        updateListItems={this.updateTools}
                        delete={this.deleteTool}
                      />
                    </div>

                    <div className='row d-flex align-items-center ml-2  mt-2'>
                      <AddLabel className='ml-4 mr-2'>Add</AddLabel>
                      <AddButton
                        style={{
                          display: 'inline-flex',
                        }}
                        disableRipple
                        disableFocusRipple
                        startIcon={<AddIcon />}
                        className=' waves-effect waves-light  mr-2'
                        onClick={() => this.toggleModal('mediaModal')}
                      >
                        Media
                      </AddButton>
                    </div>
                  </Examples>
                </StyledFormGroup>
              )}
            </Box>

            <Box className='ml-1 mt-3'>
              {helpInfoData['card_text'] && (
                <FormGroup>
                  <FieldLabel
                    detail={helpInfoData?.card_text?.detail}
                    label={helpInfoData?.card_text?.label}
                  />
                  {this.state.showError && (
                    <p style={{ color: 'red' }}> Text field can't be empty</p>
                  )}
                  <AvoEditor
                    moduleId={this.props.moduleId}
                    suggestions={this.state.textEditorsuggestions}
                    setValue={this.onTextChange}
                    prevValue={card_text_content}
                    variables={this.state.variables}
                    numerics={this.state.numerics}
                    richTextButtonShowList={[
                      ToolbarButton.TEXT_STYLE,
                      ToolbarButton.BOLD,
                      ToolbarButton.ITALIC,
                      ToolbarButton.HIGHLIGHT,
                      ToolbarButton.BULLETED_LIST,
                      ToolbarButton.NUMBERED_LIST,
                      ToolbarButton.INFOBOX,
                      ToolbarButton.INSERT_LINK,
                      ToolbarButton.MEDIA,
                      ToolbarButton.PHONE_NUMBER,
                      ToolbarButton.VARIABLES,
                      ToolbarButton.CONDITIONAL_TEXT,
                    ]}
                    wrapperClassNames='flex-grow max-h-[600px] min-h-[280px] !h-auto'
                    onUpdate={this.debTextChangeTiptap}
                    initialContent={this.state.card_text_json_tiptap}
                    richTextButtonsShowListTiptap={richTextButtonsShowList}
                    suggestionsToExclude={suggestionsToExclude}
                  />
                  <Grid container style={{ marginTop: 5 }}>
                    <Grid item xs={7}>
                      <CreateRefInfoboxButton
                        style={{
                          color: '#08A88E',
                          borderBottom: '3 solid #08A88E',
                        }}
                        aria-controls='simple-menu'
                        aria-haspopup='true'
                        onClick={(event) => this.setState({ refInfoDropdown: event.target })}
                      >
                        Create Infobox/Reference
                        <ExpandMoreIcon color='disabled' />
                      </CreateRefInfoboxButton>
                      <InfoboxRefMenu
                        style={{
                          marginTop: 35,
                        }}
                        id='simple-menu'
                        anchorEl={this.state.refInfoDropdown}
                        keepMounted
                        open={Boolean(this.state.refInfoDropdown)}
                        onClose={() => this.setState({ refInfoDropdown: null })}
                      >
                        <InfoboxRefMenuItem
                          onClick={() => {
                            this.toggleModal('infoModal');
                            this.setState({ refInfoDropdown: null });
                          }}
                        >
                          Create Infobox
                        </InfoboxRefMenuItem>
                        <Divider variant='middle' light />

                        <InfoboxRefMenuItem
                          onClick={() => {
                            this.toggleModal('refModal');
                            this.setState({ refInfoDropdown: null });
                          }}
                        >
                          Create Reference
                        </InfoboxRefMenuItem>
                      </InfoboxRefMenu>
                    </Grid>
                  </Grid>
                </FormGroup>
              )}
            </Box>

            <Box className='ml-auto'>
              <CancelButton onClick={() => this.props.toggleModal()} className='mr-4'>
                {CANCEL_BUTTON}
              </CancelButton>
              <CreateButton type='submit'>{SAVE_BUTTON}</CreateButton>
            </Box>

            <SlidingPane
              isOpen={this.state.mediaModal}
              onRequestClose={() => this.toggleModal('mediaModal')}
              from='bottom'
              // hideHeader
              className='sliding-pan-modal  side-popup-shadow'
              title={
                <div>
                  Add an Image
                  <HighlightOffIcon
                    className='backIcon'
                    onClick={() => this.toggleModal('mediaModal')}
                  ></HighlightOffIcon>
                </div>
              }
              //   subtitle='Build a Trigger'
              width='587px'
              closeIcon={
                <div>
                  <ArrowBackIcon className='closeIcon' fontSize='large'></ArrowBackIcon>
                </div>
              }
            >
              <AddValueItem
                dropdownOptions={this.props.mediaState.images}
                toggleModal={() => this.toggleModal('mediaModal')}
                addItem={this.addImage}
                moduleId={this.props.moduleId}
              />
            </SlidingPane>

            <SlidingPane
              isOpen={this.state.refModal}
              onRequestClose={() => this.toggleModal('refModal')}
              from='bottom'
              // hideHeader
              className='sliding-pan-modal  side-popup-shadow'
              title={
                <div>
                  Create a Reference Manager
                  <HighlightOffIcon
                    className='backIcon'
                    onClick={() => this.toggleModal('refModal')}
                  ></HighlightOffIcon>
                </div>
              }
              //   subtitle='Build a Trigger'
              width='587px'
              closeIcon={
                <div>
                  <ArrowBackIcon className='closeIcon' fontSize='large'></ArrowBackIcon>
                </div>
              }
            >
              <RefManagerForm {...refMangerFormProps} />
            </SlidingPane>

            <SlidingPane
              isOpen={this.state.infoModal}
              onRequestClose={() => this.toggleModal('infoModal')}
              overlayClassName='infobox-overlay-for-zindex'
              from='right'
              // hideHeader
              className='no-padding add-info-box sliding-panel-shadow'
              width='1210px'
            >
              <InfoBoxForm {...infoBoxFormProps} />
            </SlidingPane>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });
const suggestionsToExclude: SuggestionTypes[] = [
  'knowledge_base',
  'media',
  'ehr_order',
  // TODO: Need to make sure all EHR types are included
  RADIOLOGY_REPORT_TYPE,
];

const mapDispatchToProps = (dispatch, MediaCardFormProps) => ({
  setIsFormDisabled: (val) => dispatch(setIsFormDisabled(val)),
});

export default withTriggers(connect(mapStateToProps, mapDispatchToProps)(MediaCardForm));
