import PrivateRoute from './PrivateRoute';

import { routes } from '../route-definitions';
import { v2routes } from '../route-definitions/v2';

import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import NotFoundPage from '../../components/home/NotFoundPage';
import PermanentRedirect from './PermanentRedirect';

const processRoutes = (allRoutes) => {
  return allRoutes.map((routeDef) => {
    if (routeDef.type === 'REDIRECT') {
      return {
        ...routeDef,
        element: <PermanentRedirect to={routeDef.to} />,
      };
    } else if (routeDef.type === 'PRIVATE') {
      return {
        ...routeDef,
        element: <PrivateRoute {...routeDef} />,
      };
    }
    return {
      ...routeDef,
      element: React.createElement(routeDef.component),
    };
  });
};

// Combine routes and v2routes into one array
const allRoutes = processRoutes([
  ...routes,
  ...v2routes,
  {
    path: '*',
    component: NotFoundPage, // Custom 404 component
  },
]);

// Create the router instance
const router = createBrowserRouter(allRoutes);

// Export the router
export default router;
