import { useQuery } from '@tanstack/react-query';
import { auth } from 'api/firebase';
import { invitationAPI } from 'api/invitation';
import AvoMDLogo from 'assets/images/bigLogo.png';
import { Icons } from 'components/utils/Icons';
import VerticalDivider from 'components/utils/VerticalDivider';
import { CustomToast } from 'components/utils/toast-message';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HOME_ROUTE, LOGIN_ROUTE, SIGNUP_ROUTE } from '../../../constants';
import { useAuthentication } from '../../../hooks/useAuthentication';
import AccountLayout from '../AccountLayout';
import SigninForm from './SigninForm';

const Signin = () => {
  const googleAuthProvider = new GoogleAuthProvider();
  const navigate = useNavigate();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const { inviteCode } = Object.fromEntries(urlSearchParams.entries());

  const { data, isError } = useQuery({
    queryKey: ['inviteCode', inviteCode],
    queryFn: invitationAPI.getInvitation,
    enabled: !!inviteCode,
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isError) {
      toast.error(CustomToast, { data: 'Not valid inviteCode' });
      setTimeout(() => navigate(LOGIN_ROUTE, { replace: true }), 0);
    }
  }, [isError]);

  useEffect(() => {
    if (data?.email && !data.user_exists) {
      toast.info(CustomToast, { data: 'Account does not exist. Please sign up' });
      navigate(`${SIGNUP_ROUTE}?inviteCode=${inviteCode}`, { replace: true });
    }
  }, [data]);

  const [isLoading, setIsLoading] = useState(false);
  const { isLoggedIn } = useAuthentication();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(inviteCode ? `/invitation/${inviteCode}` : HOME_ROUTE, { replace: true });
    }
  }, [isLoggedIn, navigate, inviteCode]);

  const signInWithGoogle = async () => {
    await signInWithPopup(auth, googleAuthProvider).catch(() => {});
  };

  return (
    <AccountLayout>
      <div className='space-y-[12px]'>
        <div>
          <img src={AvoMDLogo} alt='AvoMD Logo' className='mx-auto' />
        </div>
        <div
          onClick={signInWithGoogle}
          className='flex h-11 cursor-pointer items-center justify-center gap-4 rounded border border-gray-300 font-bold'
        >
          <Icons.Google />
          <span>Sign In With Google</span>
        </div>

        <VerticalDivider text='OR' />
        <SigninForm isLoading={isLoading} email={data?.email} />
        <VerticalDivider />
        <div className='flex justify-center text-center'>
          <div className='py-[8px] text-body-2 text-gray-700'>Don't have an account?</div>
          <Link to={`/try-avo-builder${location.search}`}>
            <div className='px-[8px] py-[9px] text-heading-5 font-semibold text-primary-600'>
              Try AvoBuilder
            </div>
          </Link>
        </div>
      </div>
    </AccountLayout>
  );
};

export default Signin;
