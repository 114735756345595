import LoadingSpinner from 'components/loader/LoadingSpinner';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConditionalTextRequest } from '../../../../api/resources';
import { MODULE_TYPES } from '../../../../constants';
import { useConditionalTexts } from '../../../../hooks/module/resources/useConditionalTexts';
import { generateID } from '../../../../utils/utilityFunctions';
import Input from '../../../utils/Input';
import Label from '../../../utils/Label';
import { Button } from '../../../utils/common/Button';
import { Icon } from '../../../utils/common/Icon';
import { ConfirmModal } from '../../../utils/modals/ConfirmModal';
import { CustomToast } from '../../../utils/toast-message';
import { Body2, H4 } from '../../../utils/typo';
import { ConditionalTextBuilder } from './ConditionalTextBuilder';

const DEFAULT_CONDITONAL_TEXT_DATA: ConditionalTextRequest = {
  name: '',
  is_name_optional: false,
  conditions: [
    {
      sortableId: generateID(),
      is_default: false,
      label: 'Condition 1',
      position: 0,
    },
    {
      sortableId: generateID(),
      is_default: true,
      label: 'None of the above (Default)',
      position: 1,
    },
  ],
};

interface ConditionalTextFormProps {
  conditionalTextId: string | null;
  open?: boolean;
  closeModal?: () => void;
  closePanel?: () => void;
  addItem?: (item: any) => void;
}

export const ConditionalTextForm = ({
  conditionalTextId,
  open = false,
  closeModal = () => {},
  closePanel = () => {},
  addItem,
}: ConditionalTextFormProps) => {
  const { pathname } = useLocation();
  const moduleType = pathname.split('/')[1];
  const { moduleId } = useParams<{ moduleId: string }>();
  const { data, postConditionalText, putConditionalText, isMutationLoading } =
    useConditionalTexts();

  const form = useForm<ConditionalTextRequest>({
    defaultValues: {
      ...DEFAULT_CONDITONAL_TEXT_DATA,
      module: moduleType === MODULE_TYPES.ALGO ? moduleId : undefined,
      calculator: moduleType === MODULE_TYPES.CALCULATOR ? moduleId : undefined,
    },
  });

  const conditions = form.watch('conditions');
  const onSubmit = (data: ConditionalTextRequest) => {
    if (isMutationLoading) {
      return;
    }

    /* There was a bug (AVONG-2333) in which certain modules had multiple default conditions.
    Therefore, before saving such a module, if it has multiple conditions, one of them must be deleted. */
    if (conditions.filter((condition) => condition.is_default).length > 1) {
      toast.error(CustomToast, { data: 'Delete one of the default conditions.' });
      return;
    }

    if (
      conditions.some(
        (condition) => condition.is_default === false && (!condition.text || !condition.condition)
      )
    ) {
      toast.error(CustomToast, { data: 'Text or condition is empty in one of switch case' });
      return;
    }

    const conditionSet = new Set(conditions.map((condition) => condition.condition));
    if (conditionSet.size !== conditions.length) {
      toast.error(CustomToast, { data: 'Two switch cases cannot have same condition' });
      return;
    }

    !!data.id
      ? putConditionalText(data, {
          onSuccess: (res) => {
            closePanel();
            addItem?.({ ...res, type: 'conditional_text' });
          },
        })
      : postConditionalText(data, {
          onSuccess: (res) => {
            closePanel();
            addItem?.({ ...res, type: 'conditional_text' });
          },
        });
  };

  useEffect(() => {
    const conditionalText = data.find(
      (conditionalText) => conditionalText.id === conditionalTextId
    );
    if (!!conditionalText) {
      const conditionalTextRequest = {
        id: conditionalText.id,
        name: conditionalText.name,
        conditions: [...conditionalText.conditions],
        is_name_optional: conditionalText.is_name_optional,
      };
      form.reset(conditionalTextRequest);
    }
  }, [data]);

  return (
    <>
      <div className='w-[480px]'>
        <div className='mb-[30px] flex items-center justify-between'>
          <H4>Conditional Text</H4>
          <Icon.Close className='ml-auto cursor-pointer' onClick={closePanel} />
        </div>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Label className='w-full space-y-2'>
              <Body2>Title</Body2>
              <Input {...form.register('name', { required: true })} />
            </Label>
            <div className='mt-[24px]'>{!!form && <ConditionalTextBuilder />}</div>
            <div className='flex flex-row-reverse gap-2 pt-3'>
              <Button className='filled-primary p-[10px]' disabled={isMutationLoading}>
                {isMutationLoading ? <LoadingSpinner size='small' /> : 'Save'}
              </Button>
              <Button
                type='button'
                className='outlined-primary p-[10px]'
                onClick={closePanel}
                disabled={isMutationLoading}
                children='Cancel'
              />
            </div>
          </form>
        </FormProvider>
      </div>
      <ConfirmModal
        preset='unsaved'
        open={open}
        toggleModal={closeModal}
        toggleModalPanel={closePanel}
        handleSubmit={() => onSubmit(form.getValues())}
        panelForm
      />
    </>
  );
};
