import { Channels } from 'pages/channels';
import { ChannelUpsert } from 'pages/channels/upsert';
import { FeaturedAuthor } from 'pages/featured-author';
import { Notification } from 'pages/notification';
import { Setting } from 'pages/setting';
import { CustomTags } from 'pages/tags/customs';
import { Tags } from 'pages/tags/default';
import { TeamCreate } from 'pages/teams/create';
import {
  CHANNEL,
  CHANNELS,
  CHANNEL_CREATE,
  CREATE_TEAM,
  CUSTOM_TAGS,
  EHR_ORDER_INDIVIDUAL,
  FEATURED_AUTHOR,
  MEMBERS,
  NOTIFICATION,
  SETTING,
  TAGS,
} from 'routes/urls';
import { EHROrderIndividual } from '../../../pages/ehr-order/individual';
import Members from '../../../pages/members';

export const teamRoutes = [
  {
    type: 'PRIVATE',
    path: MEMBERS,
    component: Members,
  },
  {
    type: 'PRIVATE',
    path: FEATURED_AUTHOR,
    component: FeaturedAuthor,
  },
  {
    type: 'PRIVATE',
    path: TAGS,
    component: Tags,
  },
  {
    type: 'PRIVATE',
    path: CUSTOM_TAGS,
    component: CustomTags,
  },
  {
    type: 'PRIVATE',
    path: SETTING,
    component: Setting,
  },
  {
    type: 'PRIVATE',
    path: CREATE_TEAM,
    component: TeamCreate,
  },
  {
    type: 'PRIVATE',
    path: CHANNELS,
    component: Channels,
  },
  {
    type: 'PRIVATE',
    path: CHANNEL_CREATE,
    component: ChannelUpsert,
  },
  {
    type: 'PRIVATE',
    path: CHANNEL,
    component: ChannelUpsert,
  },

  {
    type: 'PRIVATE',
    path: NOTIFICATION,
    component: Notification,
  },
  {
    type: 'PRIVATE',
    path: EHR_ORDER_INDIVIDUAL,
    component: EHROrderIndividual,
  },
];
