import { ModuleProvider } from 'components/utils/module/ModuleContext';
import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UiElementProvider } from '../utils/module/UiElementContext';

interface ContextWrapperParams extends Record<string, string> {
  moduleType: 'algo' | 'calculator';
  moduleId: string;
}

// TODO: remove it and related logic after integrate all draft content to tiptap
export const ConvertContext = createContext<{
  isConverted: boolean;
  setIsConverted: Dispatch<SetStateAction<boolean>>;
}>({
  isConverted: false,
  setIsConverted: () => {},
});

function ConvertProvider({ children }: { children: ReactNode }) {
  const [isConverted, setIsConverted] = useState(false);
  return (
    <ConvertContext.Provider value={{ isConverted, setIsConverted }}>
      {children}
    </ConvertContext.Provider>
  );
}

export function ContextWrapper({ children }: { children: ReactNode }) {
  const { moduleType } = useParams<ContextWrapperParams>();

  return (
    <ModuleProvider>
      <UiElementProvider>
        <ConvertProvider>{children}</ConvertProvider>
      </UiElementProvider>
    </ModuleProvider>
  );
}
