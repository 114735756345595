import { NUMBER_ROUTE } from '../../constants';

const NumericRoutes = [
  // {
  //   type: 'PRIVATE',
  //   path: NUMBER_ROUTE,
  //   component: OldNumericList,
  // },
  // {
  //   type: 'PRIVATE',
  //   path: NUMBER_ROUTE + '/create',
  //   component: OldNumberFormWrapper,
  // },
  // {
  //   type: 'PRIVATE',
  //   path: NUMBER_ROUTE + '/:numberId',
  //   component: OldNumberFormWrapper,
  // },
  {
    type: 'REDIRECT',
    path: '/NumericForm',
    to: NUMBER_ROUTE + '/create',
  },
  {
    type: 'REDIRECT',
    path: '/NumericListing',
    to: NUMBER_ROUTE,
  },
];

export default NumericRoutes;
