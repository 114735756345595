import { Box, Divider } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import { setIsFormDisabled } from 'actions/miscellaneous/formHelperAction';
import { getNoteGenerator } from 'actions/resources/getNoteGenerator';
import getUniqueCode from 'actions/unique-code/getUniqueCodeAction';
import axios from 'axios';
import InfoboxPreview from 'components/resources/infobox/infoboxPreview';
import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import { ToolbarButton } from 'components/utils/draftJS/utils';
import InputField from 'components/utils/form-input/field';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'components/utils/modals/modal.css';
import Overlay from 'components/utils/overlay';
import { Header } from 'components/utils/panels/Header';
import {
  CancelButton,
  CreateButton,
  InfoboxFormButton,
} from 'components/utils/styled-components/FormStyle';
import { RichTextMenuButtonTypes, SuggestionTypes } from 'components/utils/tiptap/tiptapInterfaces';
import { CustomToast } from 'components/utils/toast-message';
import { CANCEL_BUTTON, SAVE_BUTTON } from 'constants/variables';
import { debounce } from 'lodash';
import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, FormGroup, Input } from 'reactstrap';
import {
  getCustomNumericSuggestions,
  getFormulaSuggestions,
  getNumericSuggestions,
  getNumerics,
  getVariableSuggestions,
  getVariables,
} from 'utils/suggestions';
import { getRepresentationPhrase } from 'utils/utilityFunctions';
import { INFOBOX_API_URL } from '../../../constants';
import { RADIOLOGY_REPORT_TYPE } from '../../../hooks/useEHRVariables';
import SmartNoteSimulator from './smart-note-simulator';

const richTextButtonsShowList: RichTextMenuButtonTypes[] = [
  'textStyles',
  'bold',
  'italic',
  'highlight',
  'bulletList',
  'orderedList',
  'more',
];

interface SmartNoteFormProps {
  formState: any;
  teamState: any;
  label: any;
  noteGeneratorId: any;
  type: any;
  isFullHeight: any;
  toggleModal: (modalName?: any) => void;
  savePosition: () => void;
  getNoteGenerator: (type: any, mirrorId, moduleId) => void;
  selectValue: (val?: any) => void;
  populateSuggestions: () => void;
  getUniqueCode: (id?: any, type?: any) => void;
  setIsFormDisabled: (val) => void;
  modal?: boolean;
  closeUiElementPane?: () => void;

  //for withRouter
  params: any;
}

interface SmartNoteFormState {
  title: string;
  shortened_title: string;
  text: any;
  newTextContent: any;
  tempContent: any;
  textJSONTiptap: any;
  module: any;

  urls: any[];
  textEditorsuggestions: any[];
  variables: any[];
  numerics: any[];
  noteGenModal: boolean;
  textEditorVariables: any[];
  previewContent: any;

  moduleId: string;
}
// The Wrapper functional component
const SmartNoteFormWrapper = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  return (
    <SmartNoteForm
      {...props} // Pass down any props from Redux connect
      navigate={navigate}
      params={params}
      location={location}
    />
  );
};

class SmartNoteForm extends Component<SmartNoteFormProps, SmartNoteFormState> {
  debOnTextChangeTiptap: any;

  constructor(props: any) {
    super(props);

    // state element name should be same as the name of input elements in form
    this.state = {
      title: '',
      shortened_title: '',
      text: '',
      newTextContent: null,
      tempContent: null,
      module: '',

      urls: [],
      textEditorsuggestions: [],
      variables: [],
      numerics: [],
      textJSONTiptap: {},
      noteGenModal: false,
      textEditorVariables: [],
      previewContent: null,

      moduleId: this.props.params.moduleId,
    };
    this.debOnTextChangeTiptap = debounce(this.onTextChangeTiptap, 300);
  }

  addURL = () => {
    this.setState((prevState) => ({
      urls: [...prevState.urls, { name: '', url: '' }],
    }));
  };

  handleURLChange = (i: any, e: any) => {
    const name = e.target ? e.target.name : '';
    let value = e.target ? e.target.value : '';
    let urls = [...this.state.urls];
    if (name === 'url' && !~value.indexOf('http')) {
      value = 'https://' + value;
    }
    urls[i] = { ...urls[i], [name]: value };
    this.setState({ urls });
  };

  removeURL = (i: any, e: any) => {
    let urls = [...this.state.urls];
    urls.splice(i, 1);
    this.setState({ urls });
  };

  helpInfoData = getHelpInfoData('InfoBox');

  componentDidMount() {
    this.props.setIsFormDisabled(false);
    if (this.props.label) {
      this.setState({
        title: this.props.label.substring(0, this.helpInfoData['title'].character_limit),
        shortened_title: getRepresentationPhrase(
          this.props.label,
          this.helpInfoData['shortened_title'].character_limit
        ),
      });
    }

    if (this.props.noteGeneratorId) {
      axios.get(INFOBOX_API_URL + this.props.noteGeneratorId + '/').then((res) => {
        // eslint-disable-next-line
        this.setState({
          title: res.data.title || '',
          shortened_title: res.data.shortened_title || '',
          text: res.data.text || '',
          newTextContent: res.data.new_text_content,
          previewContent: res.data.new_text_content,
          tempContent: res.data.new_text_content,
          textJSONTiptap: res.data.text_json_tiptap || {},
          urls: res.data.url_detail ? res.data.url_detail : [],
          module: res.data.moduleId,
        });
      });
    }

    this.populateSuggestions();
  }

  populateSuggestions = async () => {
    // suggestions for text field
    const customNumericSuggestion = getCustomNumericSuggestions();
    const formulaSuggestions = getFormulaSuggestions();

    const variableSuggestions = await getVariableSuggestions(this.state.moduleId, this.props.type);

    const numericSuggestions = await getNumericSuggestions(this.state.moduleId, this.props.type);

    const variables = await getVariables(this.state.moduleId, this.props.type);

    const numerics = await getNumerics(this.state.moduleId, this.props.type);

    const textEditorsuggestions = [
      ...formulaSuggestions,
      ...variableSuggestions,
      ...numericSuggestions,
      ...customNumericSuggestion,
    ];
    this.setState({
      textEditorsuggestions,
      variables,
      numerics,
    });
  };

  toggleModal = (modalName: any) => {
    this.setState(
      (prev) =>
        ({
          [modalName]: !prev[modalName],
        }) as Pick<SmartNoteFormState, any>
    );
  };

  // updates the state on field input
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'title') {
      this.setState({
        [e.target.name]: e.target.value,
        shortened_title: getRepresentationPhrase(
          e.target.value,
          this.helpInfoData['shortened_title'].character_limit
        ),
      });
    } else this.setState({ [e.target.name]: e.target.value } as Pick<SmartNoteFormState, any>);
  };
  setVariables = (variables) => {
    this.setState({ textEditorVariables: variables });
  };
  // updates the state on field input
  onTextChange = (e: any) => {
    let text = '';
    e.blocks.forEach((block) => {
      text += block.text + '\n';
    });

    this.setState({
      text: text,
      newTextContent: e,
      previewContent: e,
    });
  };

  onTextChangeTiptap = (editor) =>
    this.setState({
      text: editor.getText(),
      textJSONTiptap: editor.getJSON(),
    });

  getPayload = () => {
    let payload = {
      title: this.state.title,
      shortened_title: this.state.shortened_title,
      text: this.state.text,
      new_text_content: this.state.newTextContent,
      urls: this.state.urls ? JSON.stringify(this.state.urls) : JSON.stringify([]),
      infobox_type: 'NG',
      text_json_tiptap: this.state.textJSONTiptap,
    };
    if (this.props.type === 'calculator') {
      payload['calculator'] = this.state.moduleId;
    } else {
      payload['module'] = this.state.moduleId;
    }

    return payload;
  };

  createInfoBox = async () => {
    let payload = this.getPayload();
    await this.props.savePosition();

    axios.post(INFOBOX_API_URL, payload).then(async (res) => {
      // reset infobox list
      await this.props.getNoteGenerator(this.props.type, null, this.state.moduleId);

      if (this.props.selectValue) {
        this.props.selectValue(res.data);
      }

      // reset unique codes mapping to get latest result
      if (this.props.type === 'calculator') {
        await this.props.getUniqueCode(this.state.moduleId, this.props.type);
      } else {
        await this.props.getUniqueCode(this.state.moduleId, this.props.type);
      }

      // close modal
      this.props.toggleModal();
    });
  };

  editInfoBox = async () => {
    let payload = this.getPayload();
    await this.props.savePosition();

    axios.put(INFOBOX_API_URL + this.props.noteGeneratorId + '/', payload).then(async (res) => {
      await this.props.getNoteGenerator(this.props.type, null, this.state.moduleId);
      if (this.props.selectValue) {
        this.props.selectValue(res.data);
      }

      // reset unique codes mapping to get latest result
      if (this.props.type === 'calculator') {
        await this.props.getUniqueCode(this.state.moduleId, this.props.type);
      } else {
        await this.props.getUniqueCode(this.state.moduleId, this.props.type);
      }

      // close modal
      this.props.toggleModal(this.props.noteGeneratorId);
    });
  };

  resetSimulatorInputs = () => {
    this.setState((prev) => ({ previewContent: this.state.newTextContent }));
  };

  setPreviewContent = (content) => {
    const entMap = content.entityMap;
    this.setState({ previewContent: { ...this.state.previewContent, entMap } });
  };

  onKeyPress = (e) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { title, text } = this.state;
    const { noteGeneratorId, modal, closeUiElementPane } = this.props;
    if (title === '' || text.length <= 1) {
      toast.error(CustomToast, { data: 'Please fill out the title and text field.' });
    } else if (noteGeneratorId) {
      this.editInfoBox();
    } else {
      this.createInfoBox();
    }
    modal && closeUiElementPane?.();
  };

  render() {
    const helpInfoData = this.helpInfoData;
    const isFormDisabled = this.props?.formState?.isFormDisabled || false;
    return (
      <div>
        <Overlay show={isFormDisabled} />
        <div className='row'>
          <div className='col-md-5'>
            <InfoboxPreview
              title={this.state.title}
              text={this.state.text}
              textJson={this.state.newTextContent}
              tiptapJSON={this.state.textJSONTiptap}
              infobox_links={this.state.urls ? this.state.urls : []}
              references={[]}
            />
          </div>
          <div className='col-md-7'>
            <Divider
              orientation='vertical'
              style={{
                marginTop: 25,
                marginBottom: 25,
                display: 'inline-block',
                float: 'left',
              }}
            />
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                height: '100%',
                minHeight: '100%',
                pointerEvents: isFormDisabled ? 'none' : 'auto',
              }}
              autoComplete='off'
              onKeyPress={this.onKeyPress}
              className='trigger'
              onSubmit={this.handleSubmit}
            >
              {this.props.isFullHeight && (
                <Header className='px-0' title='Smart Note' toggleModal={this.props.toggleModal} />
              )}

              <div className='row'>
                <div className='col-md-12'>
                  <InputField
                    name='title'
                    required={true}
                    value={this.state.title}
                    onChange={this.onChange}
                    label={helpInfoData?.title?.label}
                    detail={helpInfoData?.title?.detail}
                    placeholder={helpInfoData?.title?.placeholder}
                    maxLength={helpInfoData?.title?.character_limit}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  {helpInfoData['text'] && (
                    <FormGroup>
                      <FieldLabel
                        detail={helpInfoData?.text?.detail}
                        label={helpInfoData?.text?.label}
                      />

                      <AvoEditor
                        maintainVariables
                        setVariables={this.setVariables}
                        moduleType={this.props.type}
                        moduleId={this.state.moduleId}
                        suggestions={this.state.textEditorsuggestions}
                        setValue={this.onTextChange}
                        prevValue={this.state.tempContent}
                        variables={this.state.variables}
                        numerics={this.state.numerics}
                        richTextButtonShowList={[
                          ToolbarButton.TEXT_STYLE,
                          ToolbarButton.BOLD,
                          ToolbarButton.ITALIC,
                          ToolbarButton.HIGHLIGHT,
                          ToolbarButton.BULLETED_LIST,
                          ToolbarButton.NUMBERED_LIST,
                          ToolbarButton.INSERT_LINK,
                          ToolbarButton.PHONE_NUMBER,
                          ToolbarButton.VARIABLES,
                          ToolbarButton.CONDITIONAL_TEXT,
                        ]}
                        wrapperClassNames='flex-grow max-h-[600px] min-h-[280px] !h-auto'
                        onUpdate={this.debOnTextChangeTiptap}
                        initialContent={this.state.textJSONTiptap}
                        suggestionsToExclude={suggestionsToExclude}
                        richTextButtonsShowListTiptap={richTextButtonsShowList}
                      />
                    </FormGroup>
                  )}
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  {helpInfoData['url_links'] && (
                    <FormGroup>
                      <FieldLabel
                        detail={helpInfoData?.url_links?.detail}
                        label={helpInfoData?.url_links?.label}
                      />

                      <Box style={{ marginBottom: 10 }}>
                        <InfoboxFormButton
                          variant='contained'
                          onClick={this.addURL}
                          startIcon={
                            <AddCircleOutlineIcon style={{ marginTop: '-2px', marginRight: 5 }} />
                          }
                        >
                          CREATE
                        </InfoboxFormButton>
                      </Box>

                      {this.state.urls.map((url, i) => (
                        <div className='row' key={i}>
                          <div className='col-md-4'>
                            <Input
                              placeholder='Name'
                              name='name'
                              value={url.name || ''}
                              onChange={(e) => this.handleURLChange(i, e)}
                            />
                          </div>
                          <div className='col-md-6'>
                            <Input
                              placeholder='URL'
                              type='url'
                              name='url'
                              onChange={(e) => this.handleURLChange(i, e)}
                              value={url.url || ''}
                            />
                          </div>
                          <div className='col-md-2 pl-0'>
                            <InfoboxFormButton
                              variant='contained'
                              onClick={(e) => this.removeURL(i, e)}
                              startIcon={<DeleteIcon style={{ marginTop: '-6px' }} />}
                            >
                              Remove
                            </InfoboxFormButton>
                          </div>
                        </div>
                      ))}
                    </FormGroup>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <FormGroup>
                    <FieldLabel label='Simulator' />
                    <InfoboxFormButton
                      variant='contained'
                      onClick={() => this.toggleModal('noteGenModal')}
                    >
                      Simulate Note Generator
                    </InfoboxFormButton>
                  </FormGroup>
                </div>
                {this.state.noteGenModal && (
                  <SmartNoteSimulator
                    // TODO: integrate moduleId & calculatorId
                    calculatorId={this.state.moduleId}
                    moduleId={this.state.moduleId}
                    open={this.state.noteGenModal}
                    editorVariables={this.state.textEditorVariables}
                    previewContent={this.state.previewContent}
                    textJsonTiptap={this.state.textJSONTiptap}
                    handleClose={() => this.toggleModal('noteGenModal')}
                    setPreviewContent={this.setPreviewContent}
                  />
                )}
              </div>

              <div className='row' style={{ marginLeft: 'auto', marginTop: '60px' }}>
                <CancelButton
                  style={{ color: 'black', marginRight: '10px' }}
                  onClick={this.props.toggleModal}
                >
                  {CANCEL_BUTTON}
                </CancelButton>
                <CreateButton type='submit' name='action'>
                  {SAVE_BUTTON}
                </CreateButton>
              </div>
            </Form>
          </div>
        </div>
        <ConfirmModal
          preset='unsaved'
          open={this.props.modal!}
          toggleModal={this.props.closeUiElementPane!}
          toggleModalPanel={this.props.toggleModal!}
          handleSubmit={this.handleSubmit}
          panelForm
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({ ...state });
const mapDispatchToProps = (dispatch, SmartNoteFormProps) => ({
  getUniqueCode: (id, type) => dispatch(getUniqueCode(id, type)),
  getNoteGenerator: (type, mirrorId, moduleId) =>
    dispatch(getNoteGenerator(type, mirrorId, moduleId)),
  setIsFormDisabled: (val) => dispatch(setIsFormDisabled(val)),
});
const suggestionsToExclude: SuggestionTypes[] = [
  'knowledge_base',
  'infobox',
  'media',
  'ehr_order',
  // TODO: Need to make sure all EHR types are included
  RADIOLOGY_REPORT_TYPE,
];
export default connect(mapStateToProps, mapDispatchToProps)(SmartNoteFormWrapper);
