import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseMutateFunction,
  useMutation,
  useQueryClient,
  useSuspenseQueries,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { ComponentType } from 'react';
import { useDispatch } from 'react-redux';
import setTeamAction from '../actions/team/selectedTeamAction';
import {
  InviteResponse,
  LinkInvitationRequest,
  MemberInvitationRequest,
  StaffJoinRequest,
  TeamListItem,
  TeamMemberRoleRequest,
  TeamWithMates,
  teamAPI,
} from '../api/team';
import { SortingMethod } from '../components/teams/teamSettingForm';

export const TEAM_QUERY_KEY = 'teamWithMembers';

interface UseTeam {
  teamList: TeamListItem[];
  isTeamLoading: boolean;
  refetchTeamList: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters) | undefined
  ) => Promise<QueryObserverResult<TeamListItem[], unknown>>;
  team: TeamWithMates;
  refetchTeam: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters) | undefined
  ) => Promise<QueryObserverResult<TeamWithMates, unknown>>;
  setTeam: UseMutateFunction<any, unknown, number, unknown>;
  staffJoin: UseMutateFunction<any, unknown, StaffJoinRequest, unknown>;
  patchRole: UseMutateFunction<AxiosResponse<any, any>, unknown, TeamMemberRoleRequest, unknown>;
  deleteMember: UseMutateFunction<AxiosResponse<any, any>, unknown, number, unknown>;
  inviteMembers: UseMutateFunction<
    InviteResponse,
    unknown,
    MemberInvitationRequest | LinkInvitationRequest,
    unknown
  >;
}

const INITIAL_TEAM_VALUE = {
  teamList: [],
  team: {
    id: 0,
    name: '',
    display_name: '',
    icon: null,
    team_type: null,
    default_sorting: SortingMethod.RELEVANT,
    teammates: [],
    ambient_listening_enabled: false,
    askavo_link_enabled: false,
  },
};

export function useTeam(): UseTeam {
  const queryClient = useQueryClient();
  const [
    { data: teamList, refetch: refetchTeamList },
    { data: team, refetch: refetchTeam, isFetching: isTeamLoading },
  ] = useSuspenseQueries({
    queries: [
      {
        queryKey: ['teams'],
        queryFn: teamAPI.getTeams,
        initialData: INITIAL_TEAM_VALUE.teamList,
        initialDataUpdatedAt: 0,
        staleTime: 1000 * 60 * 5,
        // enabled: isLoggedIn, // not supported by v5 react-query. will need to skeleton ui for each loading components
      },
      {
        queryKey: [TEAM_QUERY_KEY],
        queryFn: teamAPI.getTeam,
        initialData: INITIAL_TEAM_VALUE.team,
        initialDataUpdatedAt: 0,
        staleTime: 1000 * 60 * 5,
        // enabled: isLoggedIn, // not supported by v5 react-query. will need to skeleton ui for each loading components
      },
    ],
  });

  // TODO: refactoring
  const dispatch = useDispatch();
  const refresh = () => {
    Promise.allSettled([refetchTeam(), refetchTeamList()]).then(() => dispatch(setTeamAction()));
    queryClient.invalidateQueries({ queryKey: ['team'] });
  };
  const { mutate: setTeam } = useMutation({
    mutationFn: teamAPI.setSelectedTeam,
    onSuccess: refresh,
  });
  const { mutate: staffJoin } = useMutation({
    mutationFn: teamAPI.postStaffJoin,
    onSuccess: refresh,
  });
  const { mutate: patchRole } = useMutation({
    mutationFn: teamAPI.patchTeamMemberRole,
    onSuccess: () => {
      refetchTeam();
    },
  });
  const { mutate: deleteMember } = useMutation({
    mutationFn: teamAPI.deleteTeamMember,
    onSuccess: () => refetchTeam(),
  });
  const { mutate: inviteMembers } = useMutation({ mutationFn: teamAPI.inviteMembers });
  return {
    teamList: teamList || [],
    isTeamLoading,
    refetchTeamList,
    team: team || INITIAL_TEAM_VALUE.team,
    refetchTeam,
    setTeam,
    staffJoin,
    patchRole,
    deleteMember,
    inviteMembers,
  };
}

// TODO: Remove when replace classComponent to functionalComponent
// HOC for component with teams
export function withTeam<P extends object>(WrappedComponent: ComponentType<P>) {
  return (props: P) => {
    const { team, teamList, refetchTeam, refetchTeamList, setTeam } = useTeam();
    return (
      <WrappedComponent
        team={team}
        teamList={teamList}
        refetchTeam={refetchTeam}
        refetchTeamList={refetchTeamList}
        setTeam={setTeam}
        {...props}
      />
    );
  };
}
