import { Backdrop, Box, Fade, Modal, Typography } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import CustomButton from 'components/buttons/CustomButton';
import { CloseIcon, StyledPaper } from 'components/utils/firebase/sync-modal/styles';
import { CustomToast } from 'components/utils/toast-message';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import { ROLES, StaffJoinRequest } from '../../../api/team';
import { HOME_ROUTE } from '../../../constants';
import { useAuthentication } from '../../../hooks/useAuthentication';
import { useTeam } from '../../../hooks/useTeam';
import { useStyles } from './styles';

interface BecomeTeamMemberProps {
  modal: boolean;
  team: { id: number };
  toggleModal: () => void;
}
const BecomeTeamMember: React.FC<BecomeTeamMemberProps> = ({ modal, team, toggleModal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isRedirect, setIsRedirect] = useState(false);
  const { authentication } = useAuthentication();
  const { staffJoin } = useTeam();

  const joinTeam = async () => {
    const payload: StaffJoinRequest = {
      team: team.id,
      user: authentication.user?.id,
      role: ROLES.Admin,
      status: 'A',
    };

    toggleModal();
    staffJoin(payload);
    setIsRedirect(true);
    toast.success(CustomToast, { data: 'You are now a member of this team' });
  };

  if (isRedirect && window.location.pathname !== '/') {
    return <Navigate to={HOME_ROUTE} />;
  }

  return (
    <Modal
      open={modal}
      onClose={toggleModal}
      closeAfterTransition
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        className: 'light-overlay',
      }}
    >
      <Fade in={modal}>
        <StyledPaper style={{ height: '35%' }}>
          <Box style={{ height: '80%' }}>
            <Box className={classes.modalBody}>
              <CloseIcon data-testid='close-icon' onClick={toggleModal} style={{ zIndex: '1200' }}>
                <HighlightOff />
              </CloseIcon>
              <Box className={classes.textBox}>
                <Typography data-testid='error-msg' variant='h4' gutterBottom>
                  You do not have permission to access this team. Click the{' '}
                  <strong>Join Team</strong> button below to become a member.
                </Typography>
                <CustomButton
                  data-testid='join-team-btn'
                  style={{ marginTop: '30px' }}
                  text='JOIN TEAM'
                  onClick={joinTeam}
                />
              </Box>
            </Box>
          </Box>
        </StyledPaper>
      </Fade>
    </Modal>
  );
};

export default BecomeTeamMember;
