/**
 * urls for new design
 */

export const HOME = '/';

/**
 * Sidebar menus
 */
export const MEMBERS = HOME + 'members';
export const FEATURED_AUTHOR = HOME + 'featured-author';
export const TAGS = HOME + 'tags';
export const CUSTOM_TAGS = HOME + 'custom-tags';
export const SETTING = HOME + 'setting';
export const NUMBERS = HOME + 'numbers';
export const NUMBER_CREATE = NUMBERS + '/create';
export const NUMBER = NUMBERS + '/:numberId';
export const EHR_CONFIG = HOME + 'ehr-config';
export const CHANNELS = HOME + 'channels';
export const CHANNEL_CREATE = CHANNELS + '/create';
export const CHANNEL = CHANNELS + '/:channelId';
export const NOTIFICATION = HOME + 'notification';
export const PROFILE = HOME + 'profile';
export const EHR_ORDER_INDIVIDUAL = HOME + 'ehr-order/individual';
export const EHR_ORDER_GROUP = HOME + 'ehr-order/group';
/**
 * Team releated
 */

export const CREATE_TEAM = HOME + 'teams/create';

/**
 * module
 */

const MODULE_ROOT = HOME + ':moduleType'; //TODO removing (algo|calculator) temporary fix it after migration react 18
const MODULE = MODULE_ROOT + '/:moduleId';
export const MODULE_FLOW = MODULE + '/flow';
export const MODULE_OUTPUT = MODULE + '/output';
export const MODULE_TRIGGERS = MODULE + '/resources/triggers';
export const MODULE_INFOBOXES = MODULE + '/resources/infoboxes';
export const MODULE_FORMULAS = MODULE + '/resources/formulas';
export const MODULE_REFERENCES = MODULE + '/resources/references';
export const MODULE_SMART_NOTES = MODULE + '/resources/smart-notes';
export const MODULE_CUSTOM_NUMBERS = MODULE + '/resources/custom-numbers';
export const MODULE_INTEGRATIONS = MODULE + '/resources/integrations';
export const MODULE_CONDITIONAL_TEXTS = MODULE + '/resources/conditional-texts';
export const MODULE_APIS = MODULE + '/resources/apis';

/**
 * algo
 */

const ALGO_ROOT = HOME + 'algo';
export const ALGO_CREATE = ALGO_ROOT + '/create';
export const ALGO = ALGO_ROOT + '/:moduleId';

/**
 * calculator
 */

const CALCULATOR_ROOT = HOME + 'calculator';
export const CALCULATOR_CREATE = CALCULATOR_ROOT + '/create';
export const CALCULATOR = CALCULATOR_ROOT + '/:moduleId';

/**
 * knowledge base
 */

const KNOWLEDGE_BASE_ROOT = HOME + 'knowledge-base';
export const KNOWLEDGE_BASE_CREATE = KNOWLEDGE_BASE_ROOT + '/create';
export const KNOWLEDGE_BASE = KNOWLEDGE_BASE_ROOT + '/:moduleId';
