import { DeviceSelector } from '@avomd/shared-library';
import { useLocation } from 'react-router-dom';
import { useAuthentication } from '../../hooks/useAuthentication';
export default function Simulate() {
  const { authentication } = useAuthentication();
  const allowedUsers = ['dahun@avomd.io', 'sj@avomd.io', 'stella@avomd.io'];
  const paramForDebugPrompt = allowedUsers.includes(authentication.user?.email || '')
    ? '&debug=true'
    : '';

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const link = decodeURIComponent(params.get('link') || '{}') + paramForDebugPrompt;
  return (
    <DeviceSelector>
      <iframe
        src={link}
        width='100%'
        height='100%'
        style={{ border: 'none' }}
        allow='clipboard-write; microphone'
      />
    </DeviceSelector>
  );
}
