import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { getCustomNumerics } from '../../../actions/resources/getCustomNumerics';
import { CustomNumeric, resourcesAPI } from '../../../api/resources';
import { MODULE_TYPES } from '../../../constants';
import { ConfirmModal } from '../../utils/modals/ConfirmModal';
import { Caption2, H4 } from '../../utils/typo';
import { HasEditPermissionContext, ResourceFormModuleTypes, ResourceTable } from '../ResourceTable';
import { FloatingActionButton } from '../util/FloatingActionButton';
import CustomNumericForm from './CustomNumericForm';

interface RequiredResourceFormProps {
  customNumericId?: string;
  toggleModal: () => void;
  type: ResourceFormModuleTypes;
  isFullHeight: boolean;
  createModal: boolean;
  closeUiElementPane: () => void;
  modal: boolean;

  savePosition: () => void;
}

const labels = {
  title: 'Custom Numbers',
  input: 'custom number',
  button: 'Custom Number',
  data: 'custom numbers',
};

export const CustomNumericResourceTable = () => {
  const { module } = useContext(ModuleContext);
  const { data, refetch } = useQuery({
    queryKey: [module?.type, module?.id, 'custom-numerics'],
    queryFn:
      module?.type === MODULE_TYPES.ALGO
        ? resourcesAPI.getCustomNumerics
        : resourcesAPI.getCalculatorCustomNumerics,
    enabled: !!module,
    initialData: [],
  });

  const [open, setOpen] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [resourceId, setResourceId] = useState<string | null>(null);
  const onOpen = (resourceId: string | null) => {
    setOpen(true);
    setResourceId(resourceId);
  };
  const onClose = () => {
    setOpen(false);
    refetch();
  };

  const resourceFormProps: RequiredResourceFormProps = useMemo(
    () => ({
      customNumericId: resourceId ?? undefined,
      toggleModal: onClose,
      type: module?.type as ResourceFormModuleTypes,
      isFullHeight: true,
      createModal: !resourceId,
      closeUiElementPane: () => setModal(false),
      modal: modal,

      savePosition: () => {},
    }),
    [open, modal]
  );

  // TODO: remove when redux resource data is depracated
  const dispatch = useDispatch();
  useEffect(() => {
    if (!module) return;
    dispatch(getCustomNumerics(module.type, null, module.id));
  }, [data, module]);

  return (
    <>
      <ResourceTable
        moduleType={module?.type as ResourceFormModuleTypes}
        labels={labels}
        data={data}
        renderCard={(resource) => ResourceCard(resource, onOpen)}
        onOpen={onOpen}
      />
      <SlidingPane
        isOpen={open}
        onRequestClose={() => {
          setModal(true);
        }}
        from='right'
        hideHeader
        className='no-padding sliding-panel-shadow'
        width='622px'
      >
        <div className='mt-14'>
          <CustomNumericForm {...resourceFormProps} />
        </div>
      </SlidingPane>
    </>
  );
};

const ResourceCard = (
  resource: CustomNumeric,
  onOpen: (resourceId: string | null) => void
): ReactNode => {
  const { module } = useContext(ModuleContext);
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: resourcesAPI.deleteCustomNumeric,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [module?.type, module?.id, 'custom-numerics'] }),
  });
  const [open, setOpen] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const hasEditPermission = useContext(HasEditPermissionContext);
  const onEdit = () => hasEditPermission && onOpen(resource.id);
  const onDelete = () => {
    mutate(resource.id);
    setDeleteModal(false);
  };
  const toggleDelete = () => setDeleteModal((prev) => !prev);

  return (
    <>
      <div
        className='group flex select-none divide-x divide-gray-200 rounded-xl bg-white py-[18px] hover:!bg-primary-200'
        onDoubleClick={onEdit}
      >
        <div className='w-[70%] space-y-2 px-[20px]'>
          <div className='space-y-2'>
            <Caption2 className='text-gray-700'>Source</Caption2>
            <H4>{resource.name}</H4>
          </div>
        </div>
        <div className='flex w-[30%] px-[20px]'>
          <div className='w-1/2 space-y-2'>
            <div className='space-y-2'>
              <Caption2 className='text-gray-700'>Upper Limit</Caption2>
              <Caption2>{resource.upper_limit}</Caption2>
            </div>
            <div className='space-y-2'>
              <Caption2 className='text-gray-700'>Lower Limit</Caption2>
              <Caption2>{resource.lower_limit}</Caption2>
            </div>
          </div>
          <div className='w-1/2 space-y-2'>
            <Caption2 className='text-gray-700'>Unit</Caption2>
            <Caption2>{resource.unit}</Caption2>
          </div>
          {hasEditPermission && (
            <FloatingActionButton
              open={open}
              onClose={() => setOpen(false)}
              onEdit={onEdit}
              onDelete={toggleDelete}
            />
          )}
        </div>
      </div>
      <ConfirmModal
        preset='delete'
        open={deleteModal}
        content='Are you sure you want to delete this reference?'
        toggleModal={toggleDelete}
        performAction={onDelete}
      />
    </>
  );
};
