import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { getNumerics, getSuggestions, getVariables } from '../../../../utils/suggestions';
import { generateID } from '../../../../utils/utilityFunctions';
import { Icons } from '../../../utils/Icons';
import { ConditionalTextDisclosure } from './ConditionalTextDisClosure';

export const ConditionalTextBuilder = () => {
  const { watch, setValue } = useFormContext();
  const conditions = watch('conditions');

  const handleAdd = useCallback(() => {
    const defaultCondition = {
      sortableId: generateID(),
      is_default: false,
      label: `Condition ${conditions.length}`,
      position: 0,
    };
    const addedConditions = [defaultCondition, ...conditions];
    addedConditions.forEach((condition, position) => (condition.position = position));
    setValue('conditions', addedConditions);
  }, [conditions]);
  const onDragEnd = ({ source, destination }) => {
    if (!destination) return;

    const deepCopiedConditions = [...conditions];
    const [targetCondition] = deepCopiedConditions.splice(source.index, 1);
    deepCopiedConditions.splice(destination.index, 0, targetCondition);
    deepCopiedConditions.forEach((condition, position) => (condition.position = position));
    setValue('conditions', deepCopiedConditions);
  };

  // TODO: remove below when replace DraftJS to tiptap
  const { pathname } = useLocation();
  const moduleType = pathname.split('/')[1];
  const { moduleId } = useParams<{ moduleId: string }>();
  const { data: numerics } = useQuery({
    queryKey: ['numerics', moduleId],
    queryFn: () => getNumerics(moduleId, moduleType),
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: Infinity,
  });
  const { data: variables } = useQuery({
    queryKey: ['variables', moduleId],
    queryFn: () => getVariables(moduleId, moduleType),
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: Infinity,
  });
  const [draftJSSuggestions, setDraftJSSuggestions] = useState<any[]>([]);

  useEffect(() => {
    getSuggestions(moduleId, true, moduleType).then((suggestions) =>
      setDraftJSSuggestions(suggestions)
    );
  }, []);

  // dnd needs to be rendered after call requestAnimationFrame
  // if not, unexpected Draggable component be rendered
  // https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return (
    <div className='w-full rounded border border-gray-200 bg-gray-bg-strong'>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='ConditionalTextDroppable' key='ConditionalTextDroppable'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className='select-none'>
              {conditions.map(
                (condition, key) =>
                  !condition.is_default && (
                    <Draggable
                      draggableId={`condition-${condition.id ?? condition.sortableId}`}
                      key={`condition-${condition.id ?? condition.sortableId}`}
                      index={key}
                    >
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <ConditionalTextDisclosure
                            index={key}
                            numerics={numerics}
                            variables={variables}
                            draftJSSuggestions={draftJSSuggestions}
                          />
                        </div>
                      )}
                    </Draggable>
                  )
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {conditions.map(
        (condition, key) =>
          condition.is_default && (
            <ConditionalTextDisclosure
              index={key}
              numerics={numerics}
              variables={variables}
              draftJSSuggestions={draftJSSuggestions}
            />
          )
      )}
      <div className='mt-[8px] px-[14px] py-[9px]'>
        <button
          type='button'
          onClick={handleAdd}
          className='flex cursor-pointer items-center space-x-1 focus:bg-transparent'
        >
          <Icons.Plus className='w-3 fill-primary-600' />
          <p className='text-button-1 font-semibold text-primary-600'>Add Condition</p>
        </button>
      </div>
    </div>
  );
};
