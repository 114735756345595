import { PlusCircleIcon, UserIcon } from '@heroicons/react/24/solid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Button from 'components/utils/Button';
import Input from 'components/utils/Input';
import Label from 'components/utils/Label';
import { Modal } from 'components/utils/modals/Modal';
import RequiredMark from 'components/utils/requiredMark';
import Textarea from 'components/utils/textarea';
import { CustomToast } from 'components/utils/toast-message';
import { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { authorAPI } from '../../api/author';
import { useTeam } from '../../hooks/useTeam';

interface AddNewAuthorModalProps {
  open: boolean;
  onClose: () => void;
}

const AddNewAuthorModal = ({ open, onClose }: AddNewAuthorModalProps) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation<null, null, FormData>({
    mutationFn: authorAPI.upsertAuthor,
    onSuccess: () => {
      toast.success(CustomToast, { data: 'Author Created Successfully' });
      queryClient.invalidateQueries({ queryKey: ['teamAuthors', team] });
      onClose();
    },
  });
  const [image, setImage] = useState<File>();
  const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null>();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const { team } = useTeam();

  const handleImage = (e: ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    const imageFile = e.target.files?.[0];
    if (!imageFile) return;

    reader.onloadend = () => {
      setImage(imageFile);
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(imageFile);
  };

  const clearImage = () => {
    setImage(undefined);
    setImagePreview(null);
  };

  const onSave = () => {
    const formdata = new FormData();
    formdata.set('name', name);
    formdata.set('description', description);
    formdata.set('team', String(team.id));
    if (image && typeof image === 'object') {
      formdata.set('image', image);
    }
    mutate(formdata);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Head onClose={onClose}>Add New Author</Modal.Head>
      <Modal.Body className='space-y-4'>
        {image && imagePreview ? (
          <div className='flex items-end space-x-4'>
            <img
              src={imagePreview + ''}
              alt={'author preview'}
              className='h-[120px] w-[120px] overflow-hidden rounded-full'
            />
            <div className='cursor-pointer font-bold text-primary-600' onClick={clearImage}>
              Delete
            </div>
          </div>
        ) : (
          <Label>
            <div className='relative'>
              <UserIcon className='h-[120px] w-[120px] rounded-full bg-gray-300 text-white' />
              <PlusCircleIcon className='absolute bottom-0 right-0 h-[32px] w-[32px]' />
            </div>
            <Input
              className='hidden'
              type='file'
              name='image'
              onChange={(e) => handleImage(e)}
              accept='image/*'
            />
          </Label>
        )}
        <div className='space-y-2'>
          <div className='flex space-x-1'>
            <div>Featured Author Name</div>
            <RequiredMark />
          </div>
          <Input onChange={(e) => setName(e.currentTarget.value)} placeholder='Full Name' />
        </div>
        <div className='space-y-2'>
          <div className='flex space-x-1'>
            <div>Description</div>
            <RequiredMark />
          </div>
          <Textarea
            className='h-24'
            placeholder='Details about the author'
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
        </div>
        <div className='flex justify-end space-x-1'>
          <div>
            <Button.Reverse type='button' className='px-4 py-2' onClick={onClose}>
              Cancel
            </Button.Reverse>
          </div>
          <div>
            <Button
              type='button'
              className='px-8 py-2'
              onClick={onSave}
              disabled={!name || !description}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewAuthorModal;
