import { Box, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import getUniqueCode from 'actions/unique-code/getUniqueCodeAction';
import axios from 'axios';
import {
  convertCondition,
  ifEmptyRuleFound,
  removePrefixesInCondition,
  validateFhirConditions,
} from 'components/resources/triggers/SituationBuilderUtils';
import { AvoEditorTextInput } from 'components/utils/avoeditor/AvoEditorTextInput';
import InputField from 'components/utils/form-input/field';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { capitalizeWords } from 'components/utils/general/capitalizeWords';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'components/utils/modals/modal.css';
import { Header } from 'components/utils/panels/Header';
import { CancelButton, CreateButton } from 'components/utils/styled-components/FormStyle';
import { CustomToast } from 'components/utils/toast-message';
import { CANCEL_BUTTON, SAVE_BUTTON } from 'constants/variables';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, FormGroup, Input } from 'reactstrap';
import { convertCodeToText, mapConditionChoiceUniqueCode } from 'utils/utilityFunctions';
import { TRIGGER_API_URL } from '../../../constants';
import { withTriggers } from '../../../hooks/module/resources/useTriggers';
import { withVariables } from '../../../hooks/module/useVariables';
import { withAuthentication } from '../../../hooks/useAuthentication';
import {
  DEMOGRAPHICS_TYPE,
  LAB_DATA_TYPE,
  MEDICATION_TYPE,
  NOTE_TYPE,
  PROBLEM_LIST_TYPE,
  RADIOLOGY_REPORT_TYPE,
  VITAL_SIGN_TYPE,
} from '../../../hooks/useEHRVariables';
import { withTeam } from '../../../hooks/useTeam';
import { QueryBuilder } from './QueryBuilder';

const StyledSwitch = withStyles({
  switchBase: {
    color: '#08A88E',
    '&$checked': {
      color: '#08A88E',
    },
    '&$checked + $track': {
      backgroundColor: '#E8E8E8',
    },
  },
  checked: {},
  track: { backgroundColor: '#E8E8E8' },
})(Switch);

// The Wrapper functional component
const SituationBuilderWrapper = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  return (
    <SituationBuilder
      {...props} // Pass down any props from Redux connect
      navigate={navigate}
      params={params}
      location={location}
    />
  );
};

class SituationBuilder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      title: '',
      condition: '',
      condition_logic: undefined,
      condition_json_tree: '',
      disabled: true,
      queryValue: '',
      choiceMapping: '',
      rawCondition: '',
      candidate_trigger: false,
      edit: false,
      new_trigger_content: '',
      previousTriggerContent: '',
      triggerContentTiptap: '',
      readableCondition: '',
      mannual_editing: false,
      modal: false,

      // integrated algo & calculator id
      moduleId: this.props.params.moduleId,
    };
  }

  resetState = () => {
    this.setState({
      id: '',
      title: '',
      condition: '',
      condition_logic: undefined,
      condition_json_tree: '',
      disabled: true,
      queryValue: '',
      choiceMapping: '',
      rawCondition: '',
      candidate_trigger: false,
      edit: false,
      new_trigger_content: '',
      previousTriggerContent: '',
      triggerContentTiptap: '',
      readableCondition: '',
      mannual_editing: false,
      modal: false,

      // integrated algo & calculator id
      moduleId: this.props.match.params.moduleId,
    });
  };
  helpInfoData = getHelpInfoData('Trigger');

  componentDidMount() {
    if (this.props.triggerId) {
      axios.get(TRIGGER_API_URL + this.props.triggerId + '/').then((res) => {
        this.updateState(res.data);
      });
    }

    if (this.props.createModal) {
      document.body.style.overflow = 'hidden';
    }
  }

  componentDidUpdate(prevProps) {
    const { triggerId } = this.props;

    if (triggerId !== prevProps.triggerId) {
      if (triggerId === '') {
        this.resetState();
        return;
      } else {
        axios.get(`${TRIGGER_API_URL}${triggerId}/`).then((res) => {
          this.updateState(res.data);
        });
      }
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  async updateState(trigger) {
    const readableCondition = convertCodeToText(
      mapConditionChoiceUniqueCode(this.props.variables, trigger.condition)
    );

    this.setState({
      id: trigger.id,
      title: trigger.title,
      condition: trigger.condition,
      condition_logic: trigger.condition_logic,
      condition_json_tree: trigger.condition_json_tree,
      queryValue: trigger.condition_json_tree,
      candidate_trigger: trigger.candidate_trigger,
      new_trigger_content: trigger.new_trigger_content || '',
      previousTriggerContent: trigger.new_trigger_content || '', // TODO: consider not to use it
      triggerContentTiptap: trigger.trigger_content_tiptap || '',
      readableCondition: readableCondition,
      mannual_editing: trigger.new_trigger_content || trigger.trigger_content_tiptap ? true : false,
    });
  }

  getPayload() {
    let payload = {
      title: this.state.title,
      condition: this.state.condition,
      condition_logic: this.state.condition_logic,
      condition_json_tree: this.state.queryValue,
      rawCondition: this.state.rawCondition,
      choiceMapping: this.state.choiceMapping,
      new_trigger_content: this.state.new_trigger_content,
      trigger_content_tiptap: this.state.triggerContentTiptap,
      calculator: this.props.type === 'calculator' ? this.state.moduleId : '',
      module: this.props.type !== 'calculator' ? this.state.moduleId : '',
    };
    return payload;
  }

  onChange = (e) => {
    // automatically change space into hyphen and automatically lowercased
    if (e.target.name === 'title') {
      this.setState({
        title: capitalizeWords(e.target.value),
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  createSituation = async () => {
    if (!this.state.condition) {
      toast.error(CustomToast, { data: 'Trigger with empty condition is not allowed' });
      return;
    }

    if (ifEmptyRuleFound(this.state.queryValue)) {
      toast.error(CustomToast, { data: 'Trigger with empty rule is not allowed' });
      return;
    }
    const fhirErrorMessage = validateFhirConditions(this.state.condition_logic);
    if (fhirErrorMessage) {
      toast.error(CustomToast, { data: fhirErrorMessage });
      return;
    }

    await this.props.savePosition();

    axios.post(TRIGGER_API_URL, this.getPayload()).then(async (res) => {
      // reset unique codes mapping to get latest result
      if (this.props.type === 'calculator') {
        await this.props.getUniqueCode(this.state.moduleId, this.props.type);
      } else {
        await this.props.getUniqueCode(this.state.moduleId);
      }

      if (this.props.selectTrigger) {
        this.props.selectTrigger(res.data.id);
      }
      this.props.refetchTriggers();
      this.props.toggleModal();
    });
  };

  editSituation = async () => {
    if (!this.state.condition) {
      toast.error(CustomToast, { data: 'Trigger with empty condition is not allowed' });
      return;
    }

    if (ifEmptyRuleFound(this.state.queryValue)) {
      toast.error(CustomToast, { data: 'Trigger with empty rule is not allowed' });
      return;
    }
    const fhirErrorMessage = validateFhirConditions(this.state.condition_logic);
    if (fhirErrorMessage) {
      toast.error(CustomToast, { data: fhirErrorMessage });
      return;
    }

    await this.props.savePosition();

    axios.put(TRIGGER_API_URL + this.state.id + '/', this.getPayload()).then(async (res) => {
      // reset unique codes mapping to get latest result
      if (this.props.type === 'calculator') {
        await this.props.getUniqueCode(this.state.moduleId, this.props.type);
      } else {
        await this.props.getUniqueCode(this.state.moduleId);
      }

      if (this.props.loadFlow) this.props.loadFlow();
      if (this.props.selectTrigger) {
        this.props.selectTrigger(res.data.id);
      }
      this.props.refetchTriggers();
      this.props.toggleModal();
    });
  };

  setChoiceMapping = (e) => {
    this.setState({
      choiceMapping: e,
    });
  };

  setCondition = (e) => {
    let condition = removePrefixesInCondition(e);
    let index = condition.indexOf('IN');

    while (index !== -1) {
      condition = convertCondition(condition, index);
      index = condition.indexOf('IN');
    }

    const readableCondition = convertCodeToText(
      mapConditionChoiceUniqueCode(this.props.variables, condition)
    );
    this.setState({
      condition: condition,
      rawCondition: condition,
      readableCondition: readableCondition,
    });
  };

  setConditionLogic = (e) => this.setState({ condition_logic: e });

  setQueryValue = (e) => this.setState({ queryValue: e });

  toggleModal = () => {
    this.setState((previous) => ({
      modal: !previous.modal,
      new_trigger_content: '',
      triggerContentTiptap: '',
    }));
  };

  enableMannualEditing = () => {
    if (this.state.mannual_editing === true) {
      this.setState({ previousTriggerContent: '' });
    } else {
      this.setState({
        queryValue: '',
        condition_json_tree: '',
        edit: true,
      });
    }

    this.setState((previous) => ({
      mannual_editing: !previous.mannual_editing,
      condition: '',
      readableCondition: '',
    }));
  };

  setTrigger = (e) => {
    this.setState({
      condition: e.blocks[0].text,
      new_trigger_content: e,
    });
  };

  setTriggerContentTiptap = (editor) => {
    this.setState({
      condition: editor.getText(),
      triggerContentTiptap: editor.getJSON(),
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.props.triggerId) {
      this.props.modal && this.props.toogleModalPane();
      this.editSituation();
    } else {
      this.props.modal && this.props.toogleModalPane();
      this.createSituation();
    }
  };

  render = () => {
    let trigger_content = this.state.previousTriggerContent || '';
    const helpInfoData = this.helpInfoData;

    return (
      <div className='row' style={{ height: this.props.isFullHeight ? '100%' : '97%' }}>
        <div className='side-panel-form' style={{ height: '100%' }}>
          <Form
            className='container_card flex h-full flex-col '
            autoComplete='off'
            onSubmit={this.handleSubmit}
            style={{
              minHeight: this.props.isFullHeight ? '770px' : 'auto',
            }}
          >
            {this.props.isFullHeight ? (
              <Header
                className='px-0'
                title={this.props.createModal ? `Create Condition` : `Update Condition`}
                toggleModal={this.props.toggleModal}
              />
            ) : (
              <React.Fragment>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                >
                  <ArrowBack
                    style={{ color: '#08A88E', marginRight: 20 }}
                    onClick={() => this.props.toggleModal()}
                  />
                  <Box>
                    <Typography
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        lineHeight: '25px',
                        textTransform: 'uppercase',
                        color: '#000000',
                      }}
                    >
                      {!this.props.triggerId ? `Create Condition` : `Update Condition`}
                    </Typography>
                    <Typography
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: '#989898',
                        marginTop: 7,
                      }}
                    >
                      Build an Appearing Situation
                    </Typography>
                  </Box>
                  <HighlightOffIcon
                    style={{
                      marginLeft: 'auto',
                      color: '#C7C7C7',
                      cursor: 'pointer',
                    }}
                    onClick={() => this.props.toggleModal()}
                  />
                </Box>
              </React.Fragment>
            )}

            <Box className='mb-3 ml-1' style={{ marginTop: 36 }}>
              <InputField
                name='title'
                required={true}
                value={this.state.title}
                onChange={this.onChange}
                label={helpInfoData?.title?.label}
                detail={helpInfoData?.title?.detail}
                placeholder={helpInfoData?.title?.placeholder}
                maxLength={helpInfoData?.title?.character_limit}
              />
            </Box>

            {this.state.mannual_editing ? (
              <div className='row'>
                <div className='col-md-12'>
                  {helpInfoData['mannual_condition'] && (
                    <FormGroup>
                      <FieldLabel
                        detail={helpInfoData?.mannual_condition?.detail}
                        label={helpInfoData?.mannual_condition?.label}
                      />
                      <AvoEditorTextInput
                        moduleId={this.state.moduleId}
                        moduleType={this.props.type}
                        conditionContent={trigger_content}
                        setTrigger={this.setTrigger}
                        wrapperClassNames='flex-grow !min-h-[70px]'
                        onUpdate={this.setTriggerContentTiptap}
                        suggestionsToExclude={suggestionsToExclude}
                        variablesToExclude={variablesToExclude}
                        initialContent={this.state.triggerContentTiptap}
                      />
                    </FormGroup>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div>
                      <QueryBuilder
                        // TODO: integrate moduleId & calculatorId (same state)
                        moduleId={this.state.moduleId ? this.state.moduleId : null}
                        calculatorId={this.state.moduleId}
                        type={this.props.type ? this.props.type : null}
                        setCondition={this.setCondition}
                        setConditionLogic={this.setConditionLogic}
                        setQueryValue={this.setQueryValue}
                        queryValue={
                          this.state.condition_json_tree ? this.state.condition_json_tree : null
                        }
                        setChoiceMapping={this.setChoiceMapping}
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    {helpInfoData['query_builder_condition'] &&
                      this.props.authentication.user?.is_staff && (
                        <FormGroup style={{ marginTop: 25 }}>
                          <FieldLabel
                            detail={helpInfoData?.query_builder_condition?.detail}
                            label={helpInfoData?.query_builder_condition?.label}
                          />
                          <Input
                            type='text'
                            id='readableCondition'
                            style={{
                              width: '100%',
                              paddingLeft: 10,
                              paddingRight: 10,
                              background: '#FBFBFB',
                              border: '1px dashed #E4E3E3',
                              boxSizing: 'border-box',
                              borderRadius: '10px',
                            }}
                            value={this.state.readableCondition ? this.state.readableCondition : ''}
                            disabled={true}
                          />
                        </FormGroup>
                      )}
                  </div>
                </div>
              </div>
            )}

            <div className='text-right'>
              {(this.props.authentication.user?.is_staff ||
                // allow Fresenius team to edit manual condition
                this.props.team.id === 438) && (
                <FormControlLabel
                  control={
                    <StyledSwitch
                      checked={this.state.mannual_editing}
                      onChange={this.toggleModal}
                      name='mannual_editing'
                    />
                  }
                  label='Create condition manually'
                />
              )}
              <CancelButton style={{ marginRight: '10px' }} onClick={this.props.toggleModal}>
                {CANCEL_BUTTON}
              </CancelButton>
              <CreateButton type='submit' name='action'>
                {SAVE_BUTTON}
              </CreateButton>
            </div>
          </Form>
        </div>
        <ConfirmModal
          open={this.state.modal}
          content='Are you sure you want to change the trigger creation mechanism? If you do, the previous condition will be lost.'
          toggleModal={this.toggleModal}
          performAction={() => {
            this.toggleModal();
            this.enableMannualEditing();
          }}
        />

        <ConfirmModal
          preset='unsaved'
          open={this.props.modal}
          toggleModal={this.props.toogleModalPane}
          toggleModalPanel={this.props.toggleModal}
          handleSubmit={this.handleSubmit}
          panelForm
        />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({ ...state });
const suggestionsToExclude = [
  'link',
  'reference',
  'knowledge_base',
  'conditional_text',
  'infobox',
  'ehr_order',
  NOTE_TYPE,
  LAB_DATA_TYPE,
  VITAL_SIGN_TYPE,
  MEDICATION_TYPE,
  PROBLEM_LIST_TYPE,
  DEMOGRAPHICS_TYPE,
  RADIOLOGY_REPORT_TYPE,
];
const variablesToExclude = ['message_variable', 'ambient_variable'];

export default withVariables(
  withTriggers(
    withTeam(
      withAuthentication(connect(mapStateToProps, { getUniqueCode })(SituationBuilderWrapper))
    )
  )
);
