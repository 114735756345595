import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Author, authorAPI } from '../../../api/author';
import { useTeam } from '../../../hooks/useTeam';
import { Icons } from '../../utils/Icons';
import { H2 } from '../../utils/typo';
import { AuthorCard } from './AuthorCard';
import { FeaturedAuthorModal } from './FeaturedAuthorModal';

export function FeaturedAuthorManagement() {
  const { team } = useTeam();
  const { data } = useQuery({
    queryKey: ['teamAuthors', team.id],
    queryFn: authorAPI.getAuthors,
    initialData: [],
  });
  const [open, setOpen] = useState<boolean>(false);
  const [editingProfile, setEditingProfile] = useState<Author | undefined>(undefined);

  const onModalOpen = (authorId: number | undefined = undefined) => {
    setEditingProfile(data.find((author) => author.id === authorId));
    setOpen(true);
  };

  return (
    <>
      <div className='flex items-center justify-between'>
        <H2>Featured Authors</H2>
        <div>
          <button
            className='flex h-[40px] items-center justify-center whitespace-nowrap rounded-[4px] bg-primary-500 px-[10px] py-[8px] text-button-1 text-white focus:bg-primary-500'
            onClick={() => onModalOpen()}
          >
            <Icons.Plus className='w-[23px] fill-white p-[2px]' />
            Create Featured Author
          </button>
        </div>
      </div>
      <div className='space-y-2 pt-8'>
        {data.map((author) => (
          <AuthorCard key={author.id} author={author} onOpen={onModalOpen} />
        ))}
      </div>
      <FeaturedAuthorModal open={open} onClose={() => setOpen(false)} author={editingProfile} />
    </>
  );
}
