import { Menu, Transition } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { tagAPI } from 'api/tag';
import DropdownArrowIcon from 'assets/icons/dropdownArrowIcon';
import clsx from 'clsx';
import { Fragment, useEffect, useState } from 'react';
import { useTeam } from '../../hooks/useTeam';
import AutoTaggingController from './autoTaggingController';
import TagSelectionDropdownItem from './tagSelectionDropdownItem';
import TagSelections from './tagSelections';

interface TagSelectionDropdownProps {
  selectedTags?: string[];
  selectedHiddenTags?: string[];
  onSelect?: (tagCodes: string[]) => void;
  onHiddenSelect?: (tagCodes: string[]) => void;
  title?: string;
  description?: string;
  onAutoTagged?: (on: boolean) => void;
  autoTagged: boolean;
}

const TagSelectionDropdown = ({
  selectedTags = [],
  selectedHiddenTags = [],
  onSelect,
  onHiddenSelect,
  title,
  description,
  onAutoTagged,
  autoTagged,
}: TagSelectionDropdownProps) => {
  const { team } = useTeam();
  const [tags, setTags] = useState(selectedTags);
  const [isAutoTagged, setAutoTagged] = useState(autoTagged);
  const [hiddenTags, setHiddenTags] = useState(selectedHiddenTags);
  const { data: rootTag } = useQuery({
    queryKey: ['tags', 'team', team.id],
    queryFn: tagAPI.getTagTree,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const onChecked = (code: string, checked: boolean) => {
    setAutoTagged(false);
    if (checked) {
      setTags((prev) => [...prev, code]);
    } else {
      setTags((prev) => prev.filter((item) => item !== code));
    }
  };

  useEffect(() => {
    onSelect?.(tags);
  }, [tags]);

  useEffect(() => {
    onHiddenSelect?.(hiddenTags);
  }, [hiddenTags]);

  useEffect(() => {
    onAutoTagged?.(isAutoTagged);
  }, [isAutoTagged]);

  return (
    <div>
      <AutoTaggingController
        title={title}
        description={description}
        onAutoTagging={(tagCodes) => setTags(tagCodes)}
        onHiddenTagging={(tagCodes) => setHiddenTags(tagCodes)}
        onAutoTagged={setAutoTagged}
      />
      <Menu as='div' className='relative'>
        {({ open }) => (
          <>
            <Menu.Button
              className={clsx(
                'focus:bg-transparent', // due to material ui
                'flex w-full cursor-pointer items-center justify-between rounded border border-gray-300 px-[12px] py-[10px] text-gray-500'
              )}
            >
              {!!rootTag ? (
                <>
                  Select Tags
                  <div className={clsx('transition-all', { 'rotate-180': open })}>
                    <DropdownArrowIcon />
                  </div>
                </>
              ) : (
                `Loading Tags`
              )}
            </Menu.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='absolute z-10 h-[200px] w-full space-y-2 overflow-y-scroll rounded border border-gray-500 bg-white px-[12px] py-[10px] text-gray-900'>
                {rootTag && (
                  <TagSelectionDropdownItem
                    tag={rootTag}
                    onChange={onChecked}
                    selectedTags={tags}
                  />
                )}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      <div>
        {rootTag && (
          <TagSelections
            rootTag={rootTag}
            selectedCodes={tags}
            onDelete={(code) => onChecked(code, false)}
          />
        )}
      </div>
    </div>
  );
};

export default TagSelectionDropdown;
