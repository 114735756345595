import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { resourcesAPI } from 'api/resources';
import { MentionProvider } from 'components/utils/module/MentionContext';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { ReactNode, useContext, useState } from 'react';
import { ConfirmModal } from '../../utils/modals/ConfirmModal';
import { Slide } from '../../utils/panels/FloatingSlidePanel';
import { TiptapPreview } from '../../utils/tiptap/Tiptap';
import { Caption2, H4 } from '../../utils/typo';
import { HasEditPermissionContext, ResourceFormModuleTypes, ResourceTable } from '../ResourceTable';
import { FloatingActionButton } from '../util/FloatingActionButton';
import { APIResourceForm } from './APIResourceForm';
import { APIResource } from './types';

const labels = {
  title: 'API Resources',
  input: 'API Resource',
  button: 'API Resource',
  data: 'API Resources',
};

// TODO: separate ui with business logic
export const APIResourceContainer = () => {
  const { module } = useContext(ModuleContext);

  // TODO: Dahun's comment(https://github.com/avoMD/builder-frontend/pull/870#discussion_r1643951913)
  const { data: apiResources } = useQuery({
    queryKey: [module?.type, module?.id, 'api-resources'],
    queryFn: resourcesAPI.getApiResources,
    enabled: !!module,
    initialData: [],
  });

  const [open, setOpen] = useState(false);
  const [resource, setResource] = useState<APIResource | null>(null);
  const onOpen = (resource: APIResource | null) => {
    setResource(resource);
    setOpen(true);
  };

  return (
    <>
      <ResourceTable
        moduleType={module?.type as ResourceFormModuleTypes}
        labels={labels}
        data={apiResources}
        renderCard={(resource) => ResourceCard(resource, onOpen)}
        onOpen={() => onOpen(null)}
      />
      <Slide open={open} onOpenChange={() => setOpen(false)}>
        <Slide.Panel className='w-[620px]'>
          <APIResourceForm resource={resource} onClose={() => setOpen(false)} />
        </Slide.Panel>
      </Slide>
    </>
  );
};

const ResourceCard = (
  resource: APIResource,
  onOpen: (resource: APIResource | null) => void
): ReactNode => {
  const { module } = useContext(ModuleContext);
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: resourcesAPI.deleteApiResource,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [module?.type, module?.id, 'api-resources'] }),
  });
  const [open, setOpen] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const hasEditPermission = useContext(HasEditPermissionContext);
  const onEdit = () => hasEditPermission && onOpen(resource);
  const onDelete = () => {
    if (resource.id) mutate(resource.id);
    setDeleteModal(false);
  };
  const toggleDeleteModal = () => setDeleteModal((prev) => !prev);

  return (
    <MentionProvider excludedSuggestionTypes={[]} performChoiceCodeSubstitution>
      <div
        className='group flex select-none divide-x divide-gray-200 rounded-xl bg-white py-[18px] hover:!bg-primary-200'
        onDoubleClick={onEdit}
      >
        <div className='w-1/2 space-y-2 px-[20px]'>
          <Caption2 className='text-gray-700'>Title</Caption2>
          <H4>{resource.title}</H4>
        </div>
        <div className='flex w-1/2 px-[20px]'>
          <div className='w-full space-y-2'>
            <div>
              <span className='font-bold'>URL:</span>
              <TiptapPreview content={resource.url_json} />
            </div>
            <div>
              <span className='font-bold'>method:</span> {resource.method}
            </div>
            <div className='w-full'>
              <span className='font-bold'>Header:</span>
              <TiptapPreview content={resource.header} />
            </div>
            <div>
              <span className='font-bold'>Content Type:</span> {resource.content_type}
            </div>
            <div>
              <span className='font-bold'>Body:</span>
              <TiptapPreview content={resource.body} />
            </div>
            <div>
              <span className='font-bold'>Output Type:</span> {resource.output_type}
            </div>
            {/* TODO: disclosure function (optional) */}
            <div>
              <span className='font-bold'>Output Type Definition:</span>
              <pre className='whitespace-pre-wrap'>{resource.output_type_definition}</pre>
            </div>
            <div>
              <span className='font-bold'>Output You Want to Use:</span>
              {resource.output_you_want_to_use}
            </div>
          </div>
          {hasEditPermission && (
            <FloatingActionButton
              open={open}
              onClose={() => setOpen(false)}
              onEdit={onEdit}
              onDelete={toggleDeleteModal}
            />
          )}
        </div>
      </div>
      <ConfirmModal
        preset='delete'
        open={deleteModal}
        content='Are you sure you want to delete this reference?'
        toggleModal={toggleDeleteModal}
        performAction={onDelete}
      />
    </MentionProvider>
  );
};
