import { useMutation, useQuery } from '@tanstack/react-query';
import { invitationAPI } from 'api/invitation';
import NavBar from 'components/bars/NavBar';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import Backdrop from 'components/utils/Backdrop';
import Button from 'components/utils/Button';
import { CustomToast } from 'components/utils/toast-message';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { useNavigate, useParams } from 'react-router-dom';
import { HOME } from 'routes/urls';
import { LOGIN_ROUTE, SIGNUP_ROUTE } from '../../constants';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useTeam } from '../../hooks/useTeam';

const Invitation = () => {
  const { inviteCode } = useParams();
  const navigate = useNavigate();
  const { authentication, logout, refetch } = useAuthentication();
  const { refetchTeam, refetchTeamList } = useTeam();
  const {
    isLoading,
    data,
    isError,
    refetch: refetchInvitation,
  } = useQuery({
    queryKey: ['inviteCode', inviteCode],
    queryFn: invitationAPI.getInvitation,
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    // because sometimes react-query stuck in loading status (race condition)
    // this useEffect manually refresh when it stuck.
    if (!isLoading) return;
    const timeout = setTimeout(refetchInvitation, 3000);
    return () => clearTimeout(timeout);
  }, [isLoading]);

  const {
    isPending,
    isError: isMutateError,
    mutate: acceptInvite,
  } = useMutation({
    mutationFn: invitationAPI.acceptInvitation,
    onSuccess: async () => {
      await refetchTeam();
      await refetchTeamList();
      await refetch();
      toast.success(CustomToast, { data: 'Invitation Accepted!' });
      setTimeout(() => navigate(HOME, { replace: true }));
    },
  });

  if (isError || isMutateError) {
    toast.error(CustomToast, { data: 'Not valid invitation' });
    navigate(HOME, { replace: true });
  }

  const userEmail = authentication.user?.email;
  useEffect(() => {
    if (isLoading || !data || !inviteCode) return;
    if (!!userEmail && (!data.email || data.email === userEmail)) {
      acceptInvite(inviteCode);
    } else if (!userEmail && (!data.email || data.user_exists)) {
      navigate(`${LOGIN_ROUTE}?inviteCode=${inviteCode}`, { replace: true });
    } else if (!userEmail && data.email && !data.user_exists) {
      navigate(`${SIGNUP_ROUTE}?inviteCode=${inviteCode}`, { replace: true });
    }
  }, [isLoading, data]);

  const signinup = () => {
    logout();
    navigate(`${data?.user_exists ? LOGIN_ROUTE : SIGNUP_ROUTE}?inviteCode=${inviteCode}`);
  };

  if (!inviteCode) {
    navigate(HOME, { replace: true });
    return;
  }

  if (isError || isMutateError) {
    toast.error(CustomToast, { data: 'Not valid invitation' });
    navigate(HOME, { replace: true });
  }

  return (
    <Backdrop>
      {isLoading || isPending ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className='hidden'>
            <NavBar />
          </div>
          <div className='space-y-5 rounded border border-white bg-white p-4 text-center'>
            <div className='text-3xl text-red-500'>
              Oops! This invitation is not intended for you.
            </div>
            <div>
              {data?.user_exists ? 'Signin' : 'Signup'} with <strong>{data?.email}</strong> to
              accept this invitation.
            </div>
            <div className='flex justify-center space-x-4'>
              <Button className='uppercase' onClick={() => navigate('/')}>
                Go back to main menu
              </Button>
              <Button className='uppercase' onClick={signinup}>
                {data?.user_exists ? 'Sign in' : 'Sign up'}
              </Button>
            </div>
          </div>
        </>
      )}
    </Backdrop>
  );
};

export default Invitation;
