import { atom, useAtom } from 'jotai';
import { useLocation } from 'react-router-dom';
import { SIMULATE_ROUTE } from '../constants';

const newMemberAtom = atom<boolean>(false);
const visibleAtom = atom<boolean>(false);

interface ManualButtonHook {
  isVisible: boolean;
  isNewMember: boolean;
  setVisible: (isLoggedIn: boolean) => void;
  onNewMember: () => void;
}

export const useManualButton = (): ManualButtonHook => {
  const [isVisible, setVisible] = useAtom(visibleAtom);
  const [isNewMember, setNewMember] = useAtom(newMemberAtom);
  const { pathname } = useLocation();

  return {
    isVisible,
    isNewMember,
    setVisible: (isLoggedIn) => {
      const isSimulate = pathname.startsWith(SIMULATE_ROUTE);
      setVisible(!isSimulate && isLoggedIn);
      setNewMember(false);
    },
    onNewMember: () => {
      setNewMember(true);
    },
  };
};
