import { useMutation } from '@tanstack/react-query';
import { auth } from 'api/firebase';
import { profileApi } from 'api/profile';
import AVOMDIconGreen from 'assets/icons/avomdIconGreen';
import Button from 'components/utils/Button';
import Input from 'components/utils/Input';
import { CustomToast } from 'components/utils/toast-message';
import { H2 } from 'components/utils/typo';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LOGIN_ROUTE } from '../../../constants';

export interface ResetPasswordFormVariable {
  email: string;
}

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<ResetPasswordFormVariable>();
  const [sending, setSending] = useState(false);
  const { mutateAsync } = useMutation({
    mutationFn: profileApi.resetPassword,
    onSuccess: () => {
      toast.success(CustomToast, { data: 'Sent password reset link email' });
    },
    onError: () => {
      toast.error(CustomToast, { data: 'Fail to send email' });
    },
  });

  const onSubmit = (data: ResetPasswordFormVariable) => {
    if (sending || !data.email) return;
    setSending(true);
    sendPasswordResetEmail(auth, data.email)
      .then(() => {
        toast.success(CustomToast, { data: 'Sent password reset link email' });
        navigate(LOGIN_ROUTE);
      })
      .catch(async () => {
        await mutateAsync(data);
      })
      .finally(() => setSending(false));
  };
  return (
    <div className='flex h-screen items-center justify-center'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex justify-center'>
          <AVOMDIconGreen />
        </div>
        <H2 className='mt-2'>Forgot Password</H2>
        <Input
          {...register('email', { required: true })}
          placeholder='Please enter registered email'
          className='mt-10'
          required
        />
        <Button className='mt-3' disabled={sending}>
          {sending ? 'Sending..' : 'Reset Password'}
        </Button>
        <Button.Reverse type='button' onClick={() => navigate(LOGIN_ROUTE)} className='mt-2'>
          Cancel
        </Button.Reverse>
      </form>
    </div>
  );
};

export default ForgotPassword;
