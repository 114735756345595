export const commonSubfieldsAdvancedPhenotyping = (label: string, numbers) => ({
  name: {
    label: `${label} Name`,
    type: 'select',
    operators: ['select_equals'],
    defaultValue: numbers[0]?.unique_code ?? '',
    fieldSettings: {
      listValues: numbers.map((n) => ({ value: n.unique_code, title: n.title })),
    },
  },
  period: {
    label: 'Data Period',
    type: '!struct',
    subfields: {
      days: {
        label: 'Days',
        type: 'number',
        operators: ['equal'],
      },
      latest: {
        label: `Number of latest ${label.toLowerCase()} data`,
        type: 'number',
        operators: ['equal'],
      },
    },
  },
  operator: {
    label: 'Operator',
    type: 'select',
    operators: ['select_equals'],
    listValues: [
      { value: '>', title: 'Greater than' },
      { value: '<', title: 'Less than' },
      { value: '=', title: 'Equal to' },
      { value: '>=', title: 'Greater than or equal to' },
      { value: '<=', title: 'Less than or equal to' },
      { value: 'up_trending', title: 'Up Trending' },
      { value: 'down_trending', title: 'Down Trending' },
    ],
  },
  operatorValue: {
    label: 'Operator Value',
    type: 'number',
    operators: ['equal'],
  },
  matchScope: {
    label: 'Match Scope',
    type: 'select',
    operators: ['select_equals'],
    listValues: [
      { value: 'all', title: 'All' },
      { value: 'some_exact', title: 'Some: Exactly X out of total' },
      { value: 'some_at_least', title: 'Some: At least X out of total' },
      { value: 'none', title: 'None' },
    ],
  },
  matchCount: {
    label: 'Match Count (when scope is "some")',
    type: 'number',
    operators: ['equal'],
  },
});
