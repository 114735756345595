import Main from 'pages';
import { Profile } from 'pages/profile';
import { HOME, PROFILE } from 'routes/urls';

export const homeRoutes = [
  {
    type: 'PRIVATE',

    path: HOME,
    component: Main,
  },
  {
    type: 'PRIVATE',

    path: PROFILE,
    component: Profile,
  },
];
