import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';
import { tagAPI } from 'api/tag';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import Button from 'components/utils/Button';
import { useState } from 'react';

interface AutoTaggingControllerProps {
  title?: string;
  description?: string;
  isActive?: (active: boolean) => void;
  onAutoTagging?: (tags: string[]) => void;
  onHiddenTagging?: (tags: string[]) => void;
  onAutoTagged?: (on: boolean) => void;
}

const AutoTaggingController = ({
  title,
  description,
  onAutoTagging,
  onHiddenTagging,
  onAutoTagged,
}: AutoTaggingControllerProps) => {
  const [failReason, setFailReason] = useState<string>();
  const { mutate, isPending: isMutating } = useMutation({
    mutationFn: tagAPI.postAutoTag,
    onSuccess: (data) => {
      if (data.tagging_success) {
        onAutoTagged?.(true);
        onAutoTagging?.(data.tag_codes || []);
        onHiddenTagging?.(data.hidden_tag_codes || []);
      } else {
        setFailReason(data.fail_reason || 'There is an error. Please try again later.');
      }
    },
  });

  const autoTagging = () => {
    if (!title || !description || isMutating) return;
    setFailReason(undefined);
    mutate({ title: title, description: description });
  };

  return (
    <div>
      <div className='mb-3 flex items-center gap-4'>
        <div>
          <Button
            className='font-bold'
            type='button'
            disabled={!title || !description || isMutating}
            onClick={autoTagging}
          >
            Auto-tagging
          </Button>
        </div>
        <div>{isMutating && <LoadingSpinner />}</div>
      </div>

      {failReason && !isMutating && (
        <div className='mb-3 flex items-center space-x-2 text-orange-400'>
          <div className='shrink-0'>
            <ExclamationTriangleIcon className='h-[20px] w-[20px]' />
          </div>
          <div className='flex-wrap'>{failReason}</div>
        </div>
      )}
    </div>
  );
};

export default AutoTaggingController;
