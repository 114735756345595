import { useMutation, useQuery } from '@tanstack/react-query';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { ROLES } from 'api/team';
import { IChannelList, teamChannelAPI } from 'api/teamchannel';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import Button from 'components/utils/Button';
import Checkbox from 'components/utils/Checkbox';
import ConfirmationModal from 'components/utils/ConfirmationModal';
import { Icons } from 'components/utils/Icons';
import { H2 } from 'components/utils/typo';
import { useTeam } from 'hooks/useTeam';
import { Fragment, ReactNode, useMemo, useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { CHANNEL_CREATE_ROUTE, CHANNEL_ROUTE } from '../../constants';
import { SendNotificationModal } from '../notification/SendNotificationModal';
import { ChannelDelete } from './ChannelDelete';
import { ChannelShare } from './ChannelShare';
import { Skeleton } from '../utils/Skeleton';

export const ChannelList = () => {
  const { team, isTeamLoading } = useTeam();
  const [notificationModal, setNotificationModal] = useState(false);
  const [channels, setChannels] = useState<number[]>([]);
  const [bulkDeleteModal, setBulkDeleteModal] = useState(false);
  const isTeamAdmin = team.current_teammate?.role === ROLES.Admin;
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['team', 'channels'],
    queryFn: teamChannelAPI.getTeamChannels,
  });
  const columns = useMemo<ColumnDef<IChannelList>[]>(
    () => [
      ...(isTeamAdmin
        ? [
            {
              id: 'select-col',
              header: ({ table }) => (
                <Checkbox
                  checked={table.getIsAllRowsSelected()}
                  indeterminate={table.getIsSomeRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              ),
              cell: ({ row }) => (
                <Checkbox
                  checked={row.getIsSelected()}
                  disabled={!row.getCanSelect()}
                  onChange={row.getToggleSelectedHandler()}
                />
              ),
            },
          ]
        : []),
      {
        id: 'name',
        header: 'Name',
        accessorKey: 'name',
        cell: (cell) => <div className='whitespace-nowrap'>{cell.renderValue() as ReactNode}</div>,
      },
      {
        id: 'channelCode',
        header: 'Code',
        accessorKey: 'channel_code',
        cell: (cell) => (
          <div className='line-clamp-1' title={cell.getValue() + ''}>
            {cell.renderValue() as ReactNode}
          </div>
        ),
      },
      {
        id: 'description',
        header: 'Description',
        accessorKey: 'description',
        cell: (cell) => <div className='line-clamp-2'>{cell.renderValue() as ReactNode}</div>,
      },
      {
        id: 'institutional',
        header: 'Institutional',
        accessorFn: (row) => (row.is_institutional ? 'Yes' : 'No'),
      },
      { id: 'password', accessorKey: 'password' },
      {
        id: 'action',
        header: 'Action',
        accessorKey: 'id',
        cell: (cell) => {
          const channelCode = cell.row.getValue('channelCode') + '';
          const password = cell.row.getValue('password') + '';
          const channelId = parseInt(cell.row.id);

          return (
            <div className='flex space-x-[12px]'>
              {isTeamAdmin && (
                <>
                  <Link
                    title='Edit'
                    className='hover:text-black'
                    to={generatePath(CHANNEL_ROUTE, { channelId: cell.getValue() + '' })}
                  >
                    <Icons.Edit />
                  </Link>
                  <div
                    title='Push Notification'
                    className='cursor-pointer'
                    onClick={() => onNotificationModal([channelId])}
                  >
                    <Icons.Chat />
                  </div>
                  <ChannelDelete channelId={cell.getValue() as number} />
                </>
              )}
              <ChannelShare channelCode={channelCode} password={password} />
            </div>
          );
        },
      },
    ],
    [isTeamLoading]
  );

  const tableColumns = useMemo(
    () =>
      isTeamLoading
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton className='h-[24px] bg-gray-200' />,
          }))
        : columns,
    [columns, isTeamLoading]
  );

  const tableData = useMemo(
    () => (isTeamLoading ? Array(1).fill({}) : data || []),
    [isTeamLoading, data]
  );

  const { getHeaderGroups, getRowModel, getSelectedRowModel } = useReactTable({
    columns: tableColumns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id + '',
    initialState: { columnVisibility: { password: false } },
  });

  const { mutateAsync: bulkDelete, isPending: isBulkDeleting } = useMutation({
    mutationFn: teamChannelAPI.bulkDeleteChannel,
  });

  const selectedRows = getSelectedRowModel();

  const onBulkDelete = async () => {
    if (selectedRows.rows.length === 0) return;
    await bulkDelete({ ids: Object.keys(selectedRows.rowsById) });
    refetch();
  };

  const onNotificationModal = (channels: number[]) => {
    setChannels(channels);
    setNotificationModal(true);
  };

  return (
    <div>
      <div className='flex items-center justify-between py-[40px]'>
        <H2>Distribute Channels</H2>
        <div className='flex'>
          <Link to={CHANNEL_CREATE_ROUTE}>
            <Button>
              <div className='flex gap-[2px]'>
                <Icons.Plus />
                <span>Create Channel</span>
              </div>
            </Button>
          </Link>
        </div>
      </div>
      <div className='space-y-[12px]'>
        <div className='flex items-center justify-between'>
          <div>{selectedRows.rows.length} Channel Selected</div>
          <div className='flex gap-[8px]'>
            <div className='flex'>
              <Button.Outline
                disabled={selectedRows.rows.length === 0}
                onClick={() =>
                  onNotificationModal(
                    Object.keys(selectedRows.rowsById)
                      .map(Number)
                      .filter((id) => selectedRows.rowsById[id].getIsSelected())
                  )
                }
              >
                <div className='flex gap-[2px]'>
                  <Icons.Chat />
                  <span>Push Notification</span>
                </div>
              </Button.Outline>
            </div>
            <div className='flex'>
              <Button.Outline
                disabled={selectedRows.rows.length === 0 || isBulkDeleting}
                onClick={() => setBulkDeleteModal(true)}
              >
                {isBulkDeleting ? (
                  <LoadingSpinner size='small' />
                ) : (
                  <div className='flex gap-[2px]'>
                    <Icons.Edit />
                    <span>Delete</span>
                  </div>
                )}
              </Button.Outline>
            </div>
          </div>
        </div>
        <div>
          <table className='w-full'>
            {getHeaderGroups().map((headerGroup, idx) => (
              <thead className='border-b-2 border-gray-300' key={idx}>
                {headerGroup.headers.map((header) =>
                  header.isPlaceholder ? null : (
                    <th key={header.id} className='px-[8px] py-[16px] text-left'>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  )
                )}
              </thead>
            ))}
            <tbody>
              {!isTeamLoading && getRowModel().rows.length == 0 ? (
                <div className='text-center'>No data</div>
              ) : (
                getRowModel().rows.map((row) => (
                  <Fragment key={row.id}>
                    <tr className='border-b border-gray-300'>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className='px-[8px] py-[12px]'>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  </Fragment>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <SendNotificationModal
        open={notificationModal}
        onClose={() => setNotificationModal(false)}
        channels={channels}
      />
      <ConfirmationModal
        open={bulkDeleteModal}
        onClose={() => setBulkDeleteModal(false)}
        action={onBulkDelete}
        title='Delete ?'
        text='Are you sure you want to delete these Channels?'
      />
    </div>
  );
};
