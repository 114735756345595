import { ButtonHTMLAttributes, forwardRef } from 'react';
import { twJoin } from 'tailwind-merge';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, disabled, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        className={twJoin(
          'flex w-full justify-center rounded px-[10px] py-[8px] text-button-1 text-white',
          'focus:bg-primary-500', //due to material
          disabled ? 'bg-gray-200' : 'bg-primary-500 transition-all hover:bg-primary-600',
          className
        )}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

const ButtonReverse = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, disabled, ...rest }: ButtonProps, ref) => {
    return (
      <button
        ref={ref}
        className={twJoin(
          'flex w-full justify-center rounded px-[10px] py-[8px] text-button-1 text-primary-500',
          'focus:bg-transparent', //due to material
          disabled && 'opacity-50',
          className
        )}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

const ButtonOutline = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, disabled, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        className={twJoin(
          'flex w-full justify-center  rounded border px-[10px] py-[8px] text-button-1',
          'focus:bg-transparent', //due to material
          disabled
            ? '!border-gray-300 text-gray-text-disabled'
            : '!border-primary-500 text-primary-500 transition-all hover:bg-gray-50 hover:text-primary-600',
          className
        )}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

const ButtonRed = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, disabled, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        className={twJoin(
          'flex w-full justify-center rounded px-[10px] py-[8px] text-button-1 text-white',
          'focus:bg-error', //due to material
          disabled ? 'bg-gray-200' : 'bg-error transition-all hover:bg-red-600',
          className
        )}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

const ButtonNamespace = Object.assign(Button, {
  Reverse: ButtonReverse,
  Outline: ButtonOutline,
  Warning: ButtonRed,
});

export default ButtonNamespace;
