import { Divider, Typography } from '@material-ui/core';
import { AddCircleOutline, PermDataSetting } from '@material-ui/icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DistributionChannelIcon from 'assets/icons/distributionChannelIcon';
import MemberManagementIcon from 'assets/icons/memberManagementIcon';
import PushNotificationsIcon from 'assets/icons/pushNotificationIcon';
import TagManagementIcon from 'assets/icons/tagManagementIcon';
import TeamSettingsIcon from 'assets/icons/teamSettingIcon';
import { StyledMenu } from 'components/bars/styles';
import PushNotificationModal from 'components/utils/firebase/pushNotificationsModal';
import { StyledMenuItem } from 'components/utils/styled-components/FormStyle';
import { ROLES } from '../../../../api/team';
import {
  CHANNELS_ROUTE,
  CREATE_TEAM_ROUTE,
  EHR_CONFIG_ROUTE,
  MEM_MANGEMENT_ROUTE,
  OLD_EHR_ORDER_INDIVIDUAL_ROUTE,
  TAG_MANAGEMENT_ROUTE,
} from '../../../../constants';
import { useAuthentication } from '../../../../hooks/useAuthentication';
import { useTeam } from '../../../../hooks/useTeam';
import { Icons } from '../../../utils/Icons';
import { StaffOnlyBadgeType, StaffOnlyWrapper } from '../../../utils/StaffOnlyWrapper';
import TeamSettingModal from './teamSettingModal';
interface TeamMenuProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}
const TeamMenu: React.FC<TeamMenuProps> = ({ anchorEl, handleClose }) => {
  const { authentication } = useAuthentication();
  const { team } = useTeam();
  const isTeamAdmin = team.current_teammate?.role === ROLES.Admin;
  const isTrialUser = authentication.user?.user_type === 'trial';
  const isStaff = authentication.user?.is_staff;
  const navigate = useNavigate();
  const [teamSettingModal, setTeamSettingModal] = useState(false);
  const [pushNotificationModal, setPushNotificationModal] = useState(false);

  const closePushNotificationModal = () => {
    setPushNotificationModal(false);
    handleClose();
  };

  const openTeamSetting = () => {
    handleClose();
    setTeamSettingModal(true);
  };

  const closeTeamSetting = () => {
    setTeamSettingModal(false);
    handleClose();
  };

  const pushNotificationProps = {
    modal: pushNotificationModal,
    toggleModal: closePushNotificationModal,
  };

  return (
    <>
      <StyledMenu
        keepMounted
        id='simple-menu'
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        style={{ marginTop: 50 }}
      >
        <StyledMenuItem
          disabled={isTrialUser}
          onClick={() => navigate(CREATE_TEAM_ROUTE, { replace: true })}
        >
          <AddCircleOutline style={{ color: 'black', fontSize: '16px' }} />
          <Typography style={{ marginLeft: '15px', fontSize: '17px' }}>Create Team</Typography>
        </StyledMenuItem>
        <Divider variant='middle' light />

        <StyledMenuItem onClick={() => navigate(MEM_MANGEMENT_ROUTE, { replace: true })}>
          <MemberManagementIcon />
          <Typography style={{ marginLeft: '15px', fontSize: '17px' }}>
            Member Management
          </Typography>
        </StyledMenuItem>
        <Divider variant='middle' light />

        <StyledMenuItem
          disabled={isTrialUser}
          onClick={() => navigate(CHANNELS_ROUTE, { replace: true })}
        >
          <DistributionChannelIcon />
          <Typography style={{ marginLeft: '15px', fontSize: '17px' }}>
            Distribution Channels
          </Typography>
        </StyledMenuItem>
        <Divider variant='middle' light />

        <StyledMenuItem onClick={() => navigate(TAG_MANAGEMENT_ROUTE, { replace: true })}>
          <TagManagementIcon />
          <Typography style={{ marginLeft: '15px', fontSize: '17px' }}>Tag Management</Typography>
        </StyledMenuItem>
        <Divider variant='middle' light />

        <StyledMenuItem disabled={isTrialUser} onClick={() => setPushNotificationModal(true)}>
          <PushNotificationsIcon />
          <Typography style={{ marginLeft: '15px', fontSize: '17px' }}>
            Push Notifications{' '}
          </Typography>
        </StyledMenuItem>

        {isTeamAdmin && <Divider variant='middle' light />}

        {isTeamAdmin && (
          <StyledMenuItem onClick={openTeamSetting}>
            <TeamSettingsIcon />
            <Typography style={{ marginLeft: '15px', fontSize: '17px' }}>Team Settings</Typography>
          </StyledMenuItem>
        )}

        {isStaff && (
          <>
            <Divider variant='middle' light />
            <StaffOnlyWrapper type={StaffOnlyBadgeType.STAFF}>
              <StyledMenuItem onClick={() => navigate(EHR_CONFIG_ROUTE, { replace: true })}>
                <PermDataSetting />
                <Typography style={{ marginLeft: '15px', fontSize: '17px' }}>
                  EHR Configs
                </Typography>
              </StyledMenuItem>
            </StaffOnlyWrapper>

            <Divider variant='middle' light />
            <StaffOnlyWrapper type={StaffOnlyBadgeType.STAFF}>
              <StyledMenuItem
                onClick={() => navigate(OLD_EHR_ORDER_INDIVIDUAL_ROUTE, { replace: true })}
              >
                <Icons.EHROrder />
                <Typography style={{ marginLeft: '15px', fontSize: '17px' }}>
                  EHR Order (Individual)
                </Typography>
              </StyledMenuItem>
            </StaffOnlyWrapper>
          </>
        )}
      </StyledMenu>

      <PushNotificationModal {...pushNotificationProps} />

      <TeamSettingModal open={teamSettingModal} onClose={closeTeamSetting} />
    </>
  );
};

export default TeamMenu;
