import { Box, CircularProgress } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';
import AppearingSituation from 'components/utils/AppearingSituation';
import { initialValuesForTextInputCard } from 'components/utils/ConversationBoardUtil';
import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import { ToolbarButton } from 'components/utils/draftJS/utils';
import InputField from 'components/utils/form-input/field';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { Header } from 'components/utils/panels/Header';
import { GreenCheckbox } from 'components/utils/styled-components/FormStyle';
import {
  RichTextMenuButtonTypes,
  SuggestionTypes,
  VariableTypes,
} from 'components/utils/tiptap/tiptapInterfaces';
import { CustomToast } from 'components/utils/toast-message';
import VariableAutoGenerator from 'components/utils/variable-generator';
import { CANCEL_BUTTON, CARD_TEXT_INPUT, SAVE_BUTTON } from 'constants/variables';
import { debounce } from 'lodash';
import React, { Component, ComponentType, FormEvent } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, FormGroup } from 'reactstrap';
import { getVariableName } from 'utils/gptUtils';
import { TEXT_INPUT_API_URL } from '../../constants';
import FieldLabel from '../utils/form-input/fieldLabel';
import { CancelButton, CreateButton, FixedRow, UnderlyingElement } from './ChoicePanelForm';
import { RADIOLOGY_REPORT_TYPE } from '../../hooks/useEHRVariables';
import { withTriggers } from '../../hooks/module/resources/useTriggers';
import { flushSync } from 'react-dom';

const richTextButtonsShowList: RichTextMenuButtonTypes[] = ['more'];

interface TextInputFormState {
  title: string;
  subtitle: string;
  variable: string;
  trigger: any;
  module: any;
  containerCard: any;
  position: number | null;
  isLoading: boolean;
  isOptional: boolean;
  defaultValue: string | null;
  defaultValueTiptap: any;
  prevDefaultValue: string | null;
}

interface TextInputFormProps {
  textInputId: string | number;
  formState?: any;
  triggerState?: any;
  position: number;
  handleTextInputOptional: (isOptional: boolean) => void;
  handleAddTitle: (title: string) => void;
  handleSelectedItem: () => void;
  handleAddTrigger: (trigger: any) => void;
  handleAddSubtitle: (subtitle: string) => void;
  handleAddVariable?: (variable: string) => void;
  moduleId?: string | number;
  resetState: () => void;
  container?: number;
  toggleModalOnCreate: (command?: string) => void;
  toggleModalOnEdit: (commmand?: string) => void;
  modal?: boolean;
  closeUiElementPane?: (type?: any) => void;
  handleRichText: (value: any) => void;
  handleDefaultValueTiptap: (value: any) => void;
}

class TextInputForm extends Component<TextInputFormProps, TextInputFormState> {
  debOnDefaultValTiptapChange: any;
  constructor(props: TextInputFormProps) {
    super(props);

    this.state = {
      title: '',
      subtitle: '',
      variable: '',
      trigger: null,
      module: null,
      containerCard: '',
      position: null,

      isLoading: false,
      isOptional: false,
      defaultValue: '',
      defaultValueTiptap: {},
      prevDefaultValue: '',
    };
    this.debOnDefaultValTiptapChange = debounce(this.onDefaultValTiptapChange, 300);
  }

  helpInfoData = getHelpInfoData('TextInput');

  onDefaultValTiptapChange = (editor) => {
    this.setState({ defaultValueTiptap: editor.getJSON() });
    this.props.handleDefaultValueTiptap(editor.getJSON());
  };

  componentDidMount() {
    if (this.checkIfCreateMode()) {
      let alwayOnTrigger = this.props.triggerState.triggers.find(
        (data) => data.title === 'Always On'
      );

      this.setState({
        trigger: alwayOnTrigger?.id || '',
        position: this.props.position,
      });

      this.props.handleSelectedItem();
      this.props.handleAddTrigger(alwayOnTrigger ? alwayOnTrigger.id : '');
    } else {
      axios.get(TEXT_INPUT_API_URL + this.props.textInputId + '/').then((res) => {
        this.setState({
          title: res.data?.title || '',
          subtitle: res.data?.subtitle || '',
          variable: res.data?.variable_item?.name,
          position: res.data?.position,
          trigger: res.data?.trigger || '',
          module: res.data?.module,
          isOptional: res.data?.is_optional,
          prevDefaultValue: res.data?.default_value,
          defaultValue: res.data?.default_value,
          defaultValueTiptap: res.data?.default_value_tiptap,
        });
        this.props.handleTextInputOptional(res.data?.is_optional);
        this.props.handleAddTitle(res.data.title);
        this.props.handleAddTrigger(res.data.trigger || '');
      });
    }
  }

  // updates the state on field input
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'title') {
      this.props.handleAddTitle(e.target.value);
    } else if ((e.target.name = 'subtitle')) {
      this.props.handleAddSubtitle(e.target.value);
    }

    this.setState({ [e.target.name]: e.target.value } as Pick<TextInputFormState, any>);
  };

  setVariableName = (variable: string) => {
    this.setState({ variable });
    this.props.handleAddVariable?.(variable);
  };

  toggleCheckbox = () => {
    this.props.handleTextInputOptional(!this.state.isOptional);
    this.setState((prev) => ({ isOptional: !prev.isOptional }));
  };

  getPayload = () => {
    return {
      title: this.state.title,
      subtitle: this.state.subtitle,
      variable: this.state.variable,
      container: this.props.container,
      trigger: this.state.trigger === 'notAssigned' ? null : this.state.trigger,
      module: this.props.moduleId,
      position: this.state.position,
      is_optional: this.state.isOptional,
      default_value: this.state.defaultValue,
      default_value_tiptap: this.state.defaultValueTiptap,
    };
  };

  createTextInput = () => {
    this.props.toggleModalOnCreate('apply_from_create');
    axios.post(TEXT_INPUT_API_URL, this.getPayload()).then(() => {
      this.props.resetState();
    });
  };

  editTextInput = () => {
    this.props.toggleModalOnEdit('apply_from_edit');
    axios.put(TEXT_INPUT_API_URL + this.props.textInputId + '/', this.getPayload()).then(() => {
      this.props.resetState();
    });
  };

  getSelectedTrigger = (data: any) => {
    this.setState({ trigger: data });
    this.props.handleAddTrigger(data);
  };

  onKeyPress = (e: any) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  checkIfCreateMode = () => {
    return this.props.textInputId === initialValuesForTextInputCard.id || !this.props.textInputId;
  };

  cancel = () => {
    if (this.checkIfCreateMode()) {
      this.props.toggleModalOnCreate('cancel_from_create');
    } else {
      this.props.toggleModalOnEdit('cancel_from_edit');
    }
  };

  handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { variable, title } = this.state;
    const { modal, closeUiElementPane } = this.props;
    if (title === '') {
      toast.error(CustomToast, { data: 'Please fill out the Number panle title field.' });
    } else {
      this.setState({ isLoading: true });
      if (!variable) {
        const variable = await getVariableName(title);
        flushSync(() => {
          this.setState({ variable });
        });
      }

      if (this.checkIfCreateMode()) {
        this.createTextInput();
      } else {
        this.editTextInput();
      }
    }

    modal && closeUiElementPane?.();
  };

  onTextChange = (e) => {
    const value = !!e.blocks.map((block) => block.text).join('\n') ? e : null;
    this.setState({
      defaultValue: value,
    });
    this.props.handleRichText(value);
  };

  render() {
    const helpInfoData = this.helpInfoData;
    const triggers = [
      ...this.props.triggerState.triggers,
      ...this.props.triggerState.candidate_triggers,
    ];

    const greenCheckboxProps = {
      checked: this.state.isOptional,
      onChange: this.toggleCheckbox,
    };

    return (
      <div className='row mt-14 h-[97%] overflow-visible'>
        <div className='side-panel-form' style={{ overflow: 'visible' }}>
          <Header title={CARD_TEXT_INPUT} toggleModal={this.cancel} />
          <Form
            onKeyPress={this.onKeyPress}
            autoComplete='off'
            className='sectionDivider flex h-full flex-col px-3 pb-0'
            onSubmit={this.handleSubmit}
          >
            <Box>
              <AppearingSituation
                triggerOptions={triggers}
                onInputChange={this.getSelectedTrigger}
                defaultValue={this.state.trigger}
                moduleId={this.props.moduleId}
              />
            </Box>

            <Box style={{ marginTop: '30px' }}>
              <InputField
                name='title'
                required={true}
                value={this.state.title}
                onChange={this.onChange}
                label={helpInfoData?.title?.label}
                detail={helpInfoData?.title?.detail}
                placeholder={helpInfoData?.title?.placeholder}
                maxLength={helpInfoData?.title?.character_limit}
              />
            </Box>

            <VariableAutoGenerator
              tagTitle='Text'
              variable={this.state.variable}
              question={this.state.title}
              setVariableName={(variable) => this.setVariableName(variable)}
            />

            <Box style={{ marginTop: '20px' }}>
              <InputField
                name='subtitle'
                onChange={this.onChange}
                value={this.state.subtitle}
                label={helpInfoData?.subtitle?.label}
                detail={helpInfoData?.subtitle?.detail}
                placeholder={helpInfoData?.subtitle?.placeholder}
                maxLength={helpInfoData?.subtitle?.character_limit}
              />
            </Box>

            <FormGroup>
              <FieldLabel label='Default Value' />
              <AvoEditor
                maintainVariables
                moduleId={this.props.moduleId}
                setValue={this.onTextChange}
                prevValue={this.state.prevDefaultValue}
                disableConditionalText={true}
                isTextInput={true}
                suggestions={[]}
                richTextButtonShowList={[ToolbarButton.VARIABLES]}
                wrapperClassNames='flex-grow !min-h-[80px]'
                onUpdate={this.debOnDefaultValTiptapChange}
                initialContent={this.state.defaultValueTiptap}
                suggestionsToExclude={suggestionsToExclude}
                variablesToExclude={variablesToExclude}
                richTextButtonsShowListTiptap={richTextButtonsShowList}
              />
            </FormGroup>

            <FormControlLabel
              control={<GreenCheckbox {...greenCheckboxProps} />}
              label='Make this input optional'
            />

            <UnderlyingElement />
            <FixedRow>
              <div
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 30,
                  marginBottom: 10,
                }}
              >
                <CancelButton onClick={this.cancel} style={{ marginRight: '40px' }}>
                  {CANCEL_BUTTON}
                </CancelButton>
                <CreateButton disabled={!this.state.title} type='submit' name='action'>
                  {this.state.isLoading ? (
                    <CircularProgress id='spinner' style={{ color: '#5adfc9' }} />
                  ) : (
                    SAVE_BUTTON
                  )}
                </CreateButton>
              </div>
            </FixedRow>
          </Form>
        </div>
        <ConfirmModal
          preset='unsaved'
          open={this.props.modal!}
          toggleModal={this.props.closeUiElementPane!}
          toggleModalPanel={this.cancel}
          handleSubmit={this.handleSubmit}
          panelForm
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({ ...state });

const suggestionsToExclude: SuggestionTypes[] = [
  'knowledge_base',
  'reference',
  'conditional_text',
  'infobox',
  'link',
  'media',
  'contact_number',
  'ehr_order',
  // TODO: Need to make sure all EHR types are included
  RADIOLOGY_REPORT_TYPE,
];

const variablesToExclude: VariableTypes[] = [
  'formula',
  'number',
  'choice_variable',
  'multi_variable',
  'gptbox_variable',
  'text_input_variable',
  'message_variable',
  'ambient_variable',
  'variable',
];

export default withTriggers(
  connect(mapStateToProps)(TextInputForm)
) as ComponentType<TextInputFormProps>;
