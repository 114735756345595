import TagsLitsing from 'components/tag-management/tagsLitsing';
import { TAG_MANAGEMENT_ROUTE } from 'constants';

const TeamRoutes = [
  {
    type: 'PRIVATE',
    path: TAG_MANAGEMENT_ROUTE,
    component: TagsLitsing,
  },
];

export default TeamRoutes;
