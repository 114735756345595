import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PostModuleData, PostModuleVariable, moduleAPI } from 'api/module';
import { ROLES } from 'api/team';
import AddAuthorController from 'components/featured-authors/AddAuthorController';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import TagSelectionDropdown from 'components/tag-management/tagSelectionDropdown';
import Button from 'components/utils/Button';
import Checkbox from 'components/utils/Checkbox';
import Input from 'components/utils/Input';
import Label from 'components/utils/Label';
import SectionCard from 'components/utils/SectionCard';
import { StaffOnlyBadgeType, StaffOnlyWrapper } from 'components/utils/StaffOnlyWrapper';
import VerticalDivider from 'components/utils/VerticalDivider';
import RequiredMark from 'components/utils/requiredMark';
import Textarea from 'components/utils/textarea';
import { Body1, Body2, H2, H3, H5 } from 'components/utils/typo';
import { useAuthentication } from 'hooks/useAuthentication';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Author } from '../../api/author';
import {
  ALGO_FLOW_ROUTE,
  CALC_FLOW_ROUTE,
  CREATE,
  KB_CONTENT_ROUTE,
  MODULE_TYPES,
  MODULE_TYPES_MAP,
  PermissionMap,
  UPDATE,
} from '../../constants';
import { useTeam } from '../../hooks/useTeam';
import { CustomToast } from '../utils/toast-message';
import MemberPermissionDropdown, {
  IMemberPermission,
} from './member-permission/MemberPermissionDropdown';
import SourceController from './module-source/SourceController';
import { Intervals, VerificationIntervalListBox } from './verification/VerificationIntervalListBox';
import { VerifierComboBox } from './verification/VerifierComboBox';

interface RegistIconVariable {
  moduleId: number;
  file: File;
}

interface ModuleFormVariable {
  title: string;
  description: string;
  isGoogleSearchable: boolean;
  featuredByAdmin: boolean;
  isUniversal: boolean;
  icon?: File;
  tags: string[];
  hiddenTags: string[];
  members: IMemberPermission[];
  permissionType: string;
  team: number;
  authors: Author[];
  moduleSources: number[];
  isAutoTagged: boolean;
  reviewer: number | null;
  review_interval: Intervals | null;
  review_expire_at: Date | null;
}

interface ModuleFormProps {
  moduleType: MODULE_TYPES;
}

const getModuleFlowUrl = (moduleType: MODULE_TYPES) => {
  switch (moduleType) {
    case MODULE_TYPES.ALGO:
      return ALGO_FLOW_ROUTE;
    case MODULE_TYPES.CALCULATOR:
      return CALC_FLOW_ROUTE;
    default:
    case MODULE_TYPES.KNOWLEDGE_BASE:
      return KB_CONTENT_ROUTE;
  }
};

export const ModuleForm = ({ moduleType }: ModuleFormProps) => {
  const { team } = useTeam();
  const teamAdmin = team.current_teammate?.role === ROLES.Admin;
  const navigate = useNavigate();
  const [iconPreview, setIconPreview] = useState<string>();
  const { moduleId }: any = useParams();
  const { authentication } = useAuthentication();
  const queryClient = useQueryClient();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { data: moduleData, isLoading: isModuleLoading } = useQuery({
    queryKey: [moduleType, parseInt(moduleId)],
    queryFn: moduleAPI.getModule,
    enabled: !!moduleId,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const defaultValues: ModuleFormVariable = {
    title: '',
    description: '',
    isGoogleSearchable: false,
    featuredByAdmin: false,
    isUniversal: false,
    icon: undefined,
    tags: [],
    hiddenTags: [],
    members: [
      {
        team_member_id: team.current_teammate?.id as number,
        name: authentication.user?.name || '',
        permission_type: 'owner',
      },
    ],
    permissionType: PermissionMap.can_view.value,
    team: team.id,
    authors: [],
    moduleSources: [],
    isAutoTagged: false,
    reviewer: null,
    review_interval: null,
    review_expire_at: null,
  };

  const { watch, register, handleSubmit, setValue, control, reset } = useForm<ModuleFormVariable>({
    defaultValues,
  });
  const { ref, ...restRegister } = register('icon', { onChange: (e) => setPreview(e) });

  useEffect(() => {
    if (!moduleData || isModuleLoading) return;
    reset({
      title: moduleData.name,
      description: moduleData.description,
      isGoogleSearchable: moduleData.is_google_searchable,
      featuredByAdmin: !!moduleData.featured_by_admin_at,
      isUniversal: moduleData.is_universal,
      moduleSources: moduleData.sources.map((source) => source.id),
      tags: moduleData.tags.map((tag) => tag.code),
      hiddenTags: moduleData.hidden_tags.map((tag) => tag.code),
      members: moduleData.members,
      permissionType: moduleData.permission_type,
      authors: moduleData.authors,
      isAutoTagged: moduleData.is_auto_tagged,
      reviewer: moduleData.reviewer,
      review_interval: moduleData.review_interval,
      review_expire_at: moduleData.review_expire_at,
      team: team.id,
    });
    setIconPreview(moduleData.icon || undefined);
  }, [isModuleLoading, moduleData]);

  const { mutateAsync: uploadIcon } = useMutation({
    mutationFn: moduleAPI.uploadIconPresignedPost,
  });

  const { mutateAsync: RegistIcon } = useMutation({
    mutationFn: (data: RegistIconVariable) =>
      moduleAPI.getIconPresignedPost({
        moduleId: data.moduleId,
        payload: { file_name: data.file.name },
      }),
    onSuccess: async (presignedPostData, variables) => {
      // upload image
      const formData = new FormData();
      Object.keys(presignedPostData.fields).forEach((key) => {
        formData.append(key, presignedPostData.fields[key]);
      });

      // append the image in formData
      formData.append('file', variables.file);
      await uploadIcon({ url: presignedPostData.url, formData });
    },
    onError: () => {
      toast.error(CustomToast, { data: 'Icon upload Failed' });
    },
  });

  const { mutateAsync, isPending: isMutating } = useMutation<
    PostModuleData,
    null,
    PostModuleVariable
  >({
    mutationFn: (data) =>
      data.id
        ? moduleAPI.patchModule(data, moduleType, data.id)
        : moduleAPI.postModule(data, moduleType),
  });

  const onSubmit = async (formData: ModuleFormVariable) => {
    if (isMutating) return;
    if (formData.review_interval === Intervals.CUSTOM && !formData.review_expire_at) {
      toast.error(CustomToast, { data: 'Please choose a specific date' });
      return;
    }
    let requestData = {
      id: moduleId,
      name: formData.title,
      description: formData.description,
      tags: formData.tags,
      hidden_tags: formData.hiddenTags,
      is_google_searchable: formData.isGoogleSearchable,
      featured_by_admin_at: formData.featuredByAdmin ? new Date() : null,
      permission_type: formData.permissionType,
      is_auto_tagged: formData.isAutoTagged,
      module_sources: formData.moduleSources,
      is_universal: formData.isUniversal,
      members: formData.members,
      team: formData.team,
      authors: formData.authors,
      reviewer: formData.reviewer,
      review_interval: formData.review_interval,
      review_expire_at: formData.review_expire_at,
    };

    const icon = watch('icon');
    if (icon && icon instanceof FileList && icon.length > 0) {
      // if icon exist, execute post first (to get module id)
      if (!requestData['id']) {
        const data = await mutateAsync(requestData);
        requestData['id'] = data.id;
      }
      // upload icon
      const iconData = await RegistIcon({ moduleId: requestData['id'], file: icon[0] });
      requestData['icon'] = iconData.url + iconData.fields.key;
    }

    // if isUniversal is False, icon to null
    if (!formData.isUniversal || !iconPreview) {
      requestData['icon'] = null;
    }

    const result = await mutateAsync(requestData);
    queryClient.invalidateQueries({ queryKey: [moduleType, parseInt(moduleId)] });
    if (!isMutating) {
      window.scrollTo(0, 0);
      navigate(generatePath(getModuleFlowUrl(moduleType), { moduleId: result.id }));
      // Navigates to the generated path
    }
  };

  const setPreview = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      setIconPreview(undefined);
      return;
    }
    const reader = new FileReader();

    reader.onloadend = () => {
      setIconPreview(URL.createObjectURL(file));
    };
    reader.readAsDataURL(file);
  };

  const clearIcon = () => {
    setValue('icon', undefined, { shouldDirty: true });
    setIconPreview(undefined);
  };

  return !!moduleId && isModuleLoading ? (
    <div className='flex min-h-screen items-center justify-center'>
      <LoadingSpinner />
    </div>
  ) : (
    <div className='mx-auto max-w-[1040px] px-4 py-10'>
      <div className='px-4'>
        <H2>
          {!!moduleId ? UPDATE : CREATE} {MODULE_TYPES_MAP[moduleType]}
        </H2>
        <Body1>
          Create your virtual clinical consultant and share it with other clinicians or across your
          institutions.
        </Body1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SectionCard>
          <div className='space-y-[30px]'>
            <div>
              <div className='flex items-center justify-between'>
                <H3>Introduction</H3>
                {!!authentication.user?.is_staff && !!moduleData && (
                  <div className='rounded-lg bg-gray-200 px-6 py-1'>{moduleData.code}</div>
                )}
              </div>
              <div className='mt-[24px] max-w-[600px] space-y-4'>
                <div className='space-y-[5px]'>
                  <div className='flex gap-[4px]'>
                    <H5>Title</H5>
                    <RequiredMark />
                  </div>
                  <Input
                    placeholder='Enter Name'
                    maxLength={255}
                    required
                    {...register('title', {
                      maxLength: 255,
                      required: true,
                      onChange: (e: ChangeEvent<HTMLInputElement>) => {
                        setValue('isAutoTagged', false);
                      },
                    })}
                  />
                  <div className='text-right text-[12px]'>
                    {watch('title').length}/255 characters
                  </div>
                </div>
                <div className='space-y-[5px]'>
                  <div className='flex gap-[4px]'>
                    <H5>Description</H5>
                    <RequiredMark />
                  </div>
                  <Textarea
                    required
                    className='h-[100px]'
                    maxLength={500}
                    placeholder='Describe the usage of the module. When do we use it?'
                    {...register('description', {
                      required: true,
                      maxLength: 500,
                      onChange: (e: ChangeEvent<HTMLTextAreaElement>) => {
                        setValue('isAutoTagged', false);
                      },
                    })}
                  />
                  <div className='text-right text-[12px]'>
                    {watch('description').length}/500 characters
                  </div>
                </div>
                {/* <Label className='flex cursor-pointer items-center space-x-[8px]'>
                      <Checkbox
                        {...register('isGoogleSearchable')}
                        defaultChecked={
                          !!moduleData
                            ? moduleData.is_google_searchable
                            : defaultValues.isGoogleSearchable
                        }
                      />
                      <span className='text-base text-gray-900'>
                        Allow module to be searched in Google
                      </span>
                    </Label> */}
                <div className='flex flex-col space-y-2'>
                  {teamAdmin && (
                    <Label className='flex cursor-pointer items-center space-x-[8px]'>
                      <Checkbox
                        {...register('featuredByAdmin')}
                        defaultChecked={
                          !!moduleData
                            ? !!moduleData.featured_by_admin_at
                            : defaultValues.featuredByAdmin
                        }
                      />
                      <span className='text-base text-gray-900'>Featured By Admin</span>
                    </Label>
                  )}

                  {moduleType === MODULE_TYPES.ALGO && (
                    <StaffOnlyWrapper type={StaffOnlyBadgeType.STAFF}>
                      <div className='flex flex-col space-y-2'>
                        <Label className='flex cursor-pointer items-center space-x-[8px]'>
                          <Checkbox
                            {...register('isUniversal')}
                            defaultChecked={
                              !!moduleData ? moduleData.is_universal : defaultValues.isUniversal
                            }
                          />
                          <span className='text-base text-gray-900'>Display as Avo Assistant</span>
                        </Label>
                        {watch('isUniversal') && (
                          <div className='flex flex-col space-y-4 pl-8 pt-2'>
                            {iconPreview ? (
                              <div className='flex items-end space-x-4'>
                                <img
                                  src={iconPreview}
                                  alt={'algo preview'}
                                  className='h-[80px] w-[80px] overflow-hidden rounded object-cover'
                                />
                                <div
                                  className='cursor-pointer font-bold text-primary-600'
                                  onClick={clearIcon}
                                >
                                  Delete
                                </div>
                              </div>
                            ) : (
                              <Label className='flex flex-col space-y-4'>
                                <div className='flex'>
                                  <div>
                                    <Button.Outline
                                      className='py-2 font-bold'
                                      type='button'
                                      onClick={() => fileInputRef.current?.click()}
                                    >
                                      Choose File
                                    </Button.Outline>
                                  </div>
                                </div>
                                <span className='text-sm text-gray-text-weak'>
                                  *png, jpeg format allowed, image size 00*00 recommended
                                </span>
                                <input
                                  ref={(e) => {
                                    ref(e);
                                    fileInputRef.current = e;
                                  }}
                                  type='file'
                                  className='hidden'
                                  accept='image/*'
                                  {...restRegister}
                                />
                              </Label>
                            )}
                          </div>
                        )}
                      </div>
                    </StaffOnlyWrapper>
                  )}
                </div>
              </div>
            </div>
            <VerticalDivider />
            <div>
              <H3>Source</H3>
              <div className='mt-[24px] max-w-[600px] space-y-4'>
                <Body2>
                  Choose the main source references (e.g., guideline website or protocol PDFs).
                </Body2>
                <SourceController
                  onChange={(sources) =>
                    setValue(
                      'moduleSources',
                      sources.map((source) => source.id)
                    )
                  }
                  defaultSources={moduleData?.sources}
                />
              </div>
            </div>
            <VerticalDivider />
            <div>
              <H3>Tag</H3>
              <div className='mt-[24px] max-w-[600px] space-y-4'>
                <div>
                  <Body2>Add tags to allow users to quickly find relevant modules.</Body2>
                  <Body2>
                    Tags can also automatically be generated based upon the title and description
                    provided above.
                  </Body2>
                </div>
                <TagSelectionDropdown
                  title={watch('title')}
                  description={watch('description')}
                  onSelect={(tagCodes) => setValue('tags', tagCodes)}
                  onHiddenSelect={(tagCodes) => setValue('hiddenTags', tagCodes)}
                  autoTagged={moduleData?.is_auto_tagged || false}
                  onAutoTagged={(isAutoTagged) => setValue('isAutoTagged', isAutoTagged)}
                  selectedTags={moduleData?.tags.map((tag) => tag.code)}
                />
              </div>
            </div>
            <VerticalDivider />
            <div>
              <H3>Permission</H3>
              <div className='mt-[24px] max-w-[600px] space-y-4'>
                <MemberPermissionDropdown
                  defaultMembers={moduleData?.members || defaultValues.members}
                  defaultPermission={moduleData?.permission_type}
                  onChange={(members) => setValue('members', members)}
                  onDefaultPermissionChange={(permission) => setValue('permissionType', permission)}
                />
              </div>
            </div>
            <VerticalDivider />
            <div>
              <H3>Verifier</H3>
              <div className='mt-[12px]'>
                <Body2>Select a team member responsible for verifying this module.</Body2>
              </div>
              <div className='mt-[24px] space-y-[5px]'>
                <div className='flex flex-col'>
                  <H5>Verifier</H5>
                  <VerifierComboBox
                    onChange={(userId) => setValue('reviewer', userId)}
                    reviewer={watch('reviewer')}
                  />
                </div>
              </div>
            </div>
            <VerticalDivider />
            {watch('reviewer') && (
              <>
                <div>
                  <H3>Verification Interval</H3>
                  <div className='mt-[12px]'>
                    <Body2>
                      Set the frequency or specific time duration for verifying this module.
                    </Body2>
                  </div>
                  <div className='mt-[24px] space-y-[5px]'>
                    <div className='flex flex-col'>
                      <H5>Verification Interval</H5>
                      <VerificationIntervalListBox
                        onIntervalChange={(interval) => setValue('review_interval', interval)}
                        onExpireAtChange={(expireAt) =>
                          setValue('review_expire_at', expireAt ?? null)
                        }
                        reviewInterval={watch('review_interval')}
                        reviewExpireAt={watch('review_expire_at')}
                      />
                    </div>
                  </div>
                </div>
                <VerticalDivider />
              </>
            )}
            <div>
              <H3>Featured Author</H3>
              <div className='mt-[24px] space-y-4'>
                <Body2>Add authors for this module.</Body2>
                <AddAuthorController
                  onChange={(authors) => setValue('authors', authors)}
                  defaultAuthors={moduleData?.authors}
                />
              </div>
            </div>
            <div className='flex justify-end'>
              <div className='flex'>
                <Button.Reverse
                  onClick={() => navigate(-1)}
                  className='px-5 font-bold uppercase'
                  type='button'
                >
                  Cancel
                </Button.Reverse>
                <Button className='px-5 font-bold uppercase' type='submit' disabled={isMutating}>
                  {isMutating ? <LoadingSpinner size='small' /> : !!moduleId ? UPDATE : CREATE}
                </Button>
              </div>
            </div>
          </div>
        </SectionCard>
      </form>
    </div>
  );
};
