import { getCustomNumerics } from 'actions/resources/getCustomNumerics';
import { getNoteGenerator } from 'actions/resources/getNoteGenerator';
import { getInfoBoxes } from 'actions/resources/infoBoxes';
import getUniqueCode from 'actions/unique-code/getUniqueCodeAction';
import axios from 'axios';
import InputField from 'components/utils/form-input/field';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { Header } from 'components/utils/panels/Header';
import { CancelButton, CreateButton } from 'components/utils/styled-components/FormStyle';
import { CustomToast } from 'components/utils/toast-message';
import { CANCEL_BUTTON, CARD_CUSTOM_NUMERIC, SAVE_BUTTON } from 'constants/variables';
import { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'reactstrap';
import { CUSTOM_NUMERIC_API_URL } from '../../../constants';

interface CustomNumericFormProps {
  formState?: any;
  customNumericId: any;
  type: any;
  toggleCreateModal: Function;
  toggleModal?: (id?: any) => void;
  createModal: Function;
  savePosition: Function;
  getCustomNumerics?: Function;
  selectValue?: Function;
  getUniqueCode: Function;
  addNumeric: Function;
  toggleEditModal: Function;
  getInfoBoxes: Function;
  getNoteGenerator: Function;
  editCustomNumeric: Function;
  isFullHeight: boolean;
  addToPanel: boolean;
  modal?: boolean;
  closeUiElementPane?: () => void;

  // for withRouter
  params: any;
}

interface CustomNumericFormState {
  name: any;
  unit: any;
  upperLimit: any;
  lowerLimit: any;

  showError: boolean;

  moduleId: string;
}
// The Wrapper functional component
const CustomNumericFormWrapper = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  return (
    <CustomNumericForm
      {...props} // Pass down any props from Redux connect
      navigate={navigate}
      params={params}
      location={location}
    />
  );
};

class CustomNumericForm extends Component<CustomNumericFormProps, CustomNumericFormState> {
  constructor(props: CustomNumericFormProps) {
    super(props);

    // state element name should be same as the name of input elements in form
    this.state = {
      name: '',
      unit: '',
      upperLimit: '',
      lowerLimit: '',

      showError: false,

      moduleId: this.props.params.moduleId,
    };
  }

  helpInfoData = getHelpInfoData('CustomNumeric');

  componentDidMount() {
    if (this.props.customNumericId) {
      axios.get(CUSTOM_NUMERIC_API_URL + this.props.customNumericId + '/').then((res) => {
        this.setState({
          name: res.data.name || '',
          unit: res.data.unit || '',
          upperLimit: res.data.upper_limit || '',
          lowerLimit: res.data.lower_limit || '',
          showError: res.data.upper_limit < res.data.lower_limit ? true : false,
        });
      });
    }
  }

  // updates the state on field input
  onChange = (e) => {
    if (e.target.name === 'lowerLimit') {
      if (parseFloat(this.state.upperLimit) < parseFloat(e.target.value))
        this.setState({ showError: true });
      else if (parseFloat(this.state.upperLimit) > parseFloat(e.target.value))
        this.setState({ showError: false });
    } else if (e.target.name === 'upperLimit') {
      if (parseFloat(this.state.lowerLimit) > parseFloat(e.target.value))
        this.setState({ showError: true });
      else if (parseFloat(this.state.lowerLimit) < parseFloat(e.target.value))
        this.setState({ showError: false });
    }

    this.setState({ [e.target.name]: e.target.value } as Pick<CustomNumericFormState, any>);
  };

  getPayload = () => {
    let payload = {
      name: this.state.name,
      unit: this.state.unit,
      upper_limit: this.state.upperLimit || null,
      lower_limit: this.state.lowerLimit || null,
      calculator: this.props.type === 'calculator' ? this.state.moduleId : '',
      module: this.props.type !== 'calculator' ? this.state.moduleId : '',
    };
    return payload;
  };

  createCustomNumeric = async () => {
    if (parseFloat(this.state.upperLimit) < parseFloat(this.state.lowerLimit)) {
      toast.error(CustomToast, { data: 'Lower limit should be less than upper limit' });
      return;
    }

    await this.props.savePosition();

    axios.post(CUSTOM_NUMERIC_API_URL, this.getPayload()).then(async (res) => {
      this.props.getCustomNumerics &&
        (await this.props.getCustomNumerics(this.props.type, null, this.state.moduleId));

      // select newly created value in Number panel form
      if (this.props.selectValue) {
        this.props.selectValue(res.data);
      }

      // reset unique codes mapping to get latest result
      if (this.props.type) {
        await this.props.getUniqueCode(this.state.moduleId, this.props.type);
      } else {
        await this.props.getUniqueCode(this.state.moduleId);
      }
      if (this.props.addToPanel) this.props.addNumeric(res.data, 'custom');

      // close modal
      if (this.props.toggleCreateModal) {
        await this.props.toggleCreateModal();
      } else {
        this.props.toggleModal && (await this.props.toggleModal());
      }
    });
  };

  editCustomNumeric = async () => {
    if (parseFloat(this.state.upperLimit) < parseFloat(this.state.lowerLimit)) {
      toast.error(CustomToast, { data: 'Lower limit should be less than upper limit' });
      return;
    }

    await this.props.savePosition();

    axios
      .put(CUSTOM_NUMERIC_API_URL + this.props.customNumericId + '/', this.getPayload())
      .then(async () => {
        this.props.getCustomNumerics &&
          this.props.getCustomNumerics(this.props.type, null, this.state.moduleId);

        // refresh infoboxes list to get update the name of custom numerics used in infoboxes
        this.props.getInfoBoxes(this.props.type, null, this.state.moduleId);
        this.props.getNoteGenerator(this.props.type, null, this.state.moduleId);

        // reset unique codes mapping to get latest result
        if (this.props.type) {
          await this.props.getUniqueCode(this.state.moduleId, this.props.type);
        } else {
          await this.props.getUniqueCode(this.state.moduleId);
        }

        // close modal
        if (this.props.toggleEditModal) {
          await this.props.toggleEditModal(this.props.customNumericId);
        } else {
          this.props.toggleModal && (await this.props.toggleModal(this.props.customNumericId));
        }
      });
  };

  onKeyPress = (e) => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  /* TODO:
    Some parents pass toggleEditModal & toggleCreateModal
    Somp parents only pass toggleModal
    So, used if statements for hotfix but should be refactored
  */
  closeModal = () => {
    if (this.props.customNumericId) {
      if (this.props.toggleEditModal) {
        this.props.toggleEditModal(this.props.customNumericId);
      } else {
        this.props.toggleModal && this.props.toggleModal(this.props.customNumericId);
      }
    } else {
      if (this.props.toggleCreateModal) {
        this.props.toggleCreateModal();
      } else {
        this.props.toggleModal && this.props.toggleModal();
      }
    }
  };

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { name, unit } = this.state;
    const { customNumericId, modal, closeUiElementPane } = this.props;
    if (name === '' || unit === '') {
      toast.error(CustomToast, { data: 'Please fill out the name and unit field.' });
    } else if (customNumericId) {
      this.editCustomNumeric();
    } else {
      this.createCustomNumeric();
    }
    modal && closeUiElementPane?.();
  };

  render() {
    const helpInfoData = this.helpInfoData;

    return (
      <>
        <Form
          onKeyPress={this.onKeyPress}
          className='trigger flex flex-col'
          autoComplete='off'
          onSubmit={this.handleSubmit}
        >
          <div className='row'>
            {this.props.isFullHeight ? ( // TODO: isFullHeight is always true
              <Header
                title={
                  this.props.createModal
                    ? `Create ${CARD_CUSTOM_NUMERIC}`
                    : `Update ${CARD_CUSTOM_NUMERIC}`
                }
                className='px-3'
                toggleModal={this.closeModal}
              />
            ) : (
              ''
            )}
            <div className='col-md-12'>
              <InputField
                name='name'
                required={true}
                value={this.state.name}
                onChange={this.onChange}
                label='Name'
                detail='Internal name of the numeric'
                placeholder='Name'
                maxLength={70}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <InputField
                name='unit'
                required={true}
                value={this.state.unit}
                onChange={this.onChange}
                label={helpInfoData?.unit?.label}
                detail={helpInfoData?.unit?.detail}
                placeholder={helpInfoData?.unit?.placeholder}
                maxLength={helpInfoData?.unit?.character_limit}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <InputField
                type='number'
                name='upperLimit'
                value={this.state.upperLimit}
                onChange={this.onChange}
                label={helpInfoData?.upper_limit?.label}
                detail={helpInfoData?.upper_limit?.detail}
                placeholder={helpInfoData?.upper_limit?.placeholder}
                maxLength={helpInfoData?.upper_limit?.character_limit}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <InputField
                type='number'
                name='lowerLimit'
                value={this.state.lowerLimit}
                onChange={this.onChange}
                label={helpInfoData?.lower_limit?.label}
                detail={helpInfoData?.lower_limit?.detail}
                placeholder={helpInfoData?.lower_limit?.placeholder}
                maxLength={helpInfoData?.lower_limit?.character_limit}
              />

              {this.state.showError && (
                <p style={{ float: 'right', color: 'red', fontSize: '12px' }}>
                  Lower limit should be less than upper limit
                </p>
              )}
            </div>
          </div>

          <div className='row' style={{ marginLeft: 'auto', marginTop: '60px' }}>
            <CancelButton style={{ color: 'black', marginRight: '10px' }} onClick={this.closeModal}>
              {CANCEL_BUTTON}
            </CancelButton>

            <CreateButton type='submit' name='action'>
              {SAVE_BUTTON}
            </CreateButton>
          </div>
          <ConfirmModal
            preset='unsaved'
            open={this.props.modal!}
            toggleModal={this.props.closeUiElementPane!}
            toggleModalPanel={this.props.toggleModal}
            handleSubmit={this.handleSubmit}
            panelForm
          />
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch, ValuePanelFormProps) => ({
  getUniqueCode: (moduleId, moduleType) => dispatch(getUniqueCode(moduleId, moduleType)),
  getCustomNumerics: (moduleType, mirrorId, moduleId) =>
    dispatch(getCustomNumerics(moduleType, mirrorId, moduleId)),
  getInfoBoxes: (moduleType, mirrorId, moduleId) =>
    dispatch(getInfoBoxes(moduleType, mirrorId, moduleId)),
  getNoteGenerator: (moduleType, mirrorId, moduleId) =>
    dispatch(getNoteGenerator(moduleType, mirrorId, moduleId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomNumericFormWrapper);
