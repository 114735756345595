import { simulateInWebapp } from 'components/module-detail/ConversationBoardUtils';
import { Body1, H4 } from 'components/utils/typo';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, Navigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import {
  ALGO_FLOW_ROUTE,
  CALC_FLOW_ROUTE,
  KB_CONTENT_ROUTE,
  MODULE_TYPES,
  MODULE_TYPES_MAP,
} from '../../constants';
import { ModuleWithMirror } from '../../hooks/useModules';
import { Icons } from '../utils/Icons';
import { getUniqueModuleKey } from '../utils/module';
import { ModulePopover } from './ModulePopover';
import { ModuleSetting } from './ModuleSetting';
import { ModuleStatus } from './ModuleStatus';

interface ModuleRowProps {
  module: ModuleWithMirror;
}

export const ModuleRow: React.FC<ModuleRowProps> = ({ module }) => {
  const [showIcon, setShowIcon] = useState(false);
  const [showVerifiedTooltip, setShowVerifiedTooltip] = useState(false);
  const [redirectLink, setRedirectLink] = useState('');
  const dispatch = useDispatch();

  const openModule = () => {
    const isMirror = module?.type === MODULE_TYPES.MIRROR;
    const id = isMirror ? module.mirroredModuleId! : (module?.id as any); // TODO temporary fix
    const type = isMirror ? module.mirrorOf! : module?.type;

    if (isMirror && type !== MODULE_TYPES.KNOWLEDGE_BASE) {
      simulateInWebapp(id, type);
    } else {
      let link = '';
      switch (type) {
        case MODULE_TYPES.CALCULATOR:
          link = generatePath(CALC_FLOW_ROUTE, { moduleId: id });
          break;
        case MODULE_TYPES.KNOWLEDGE_BASE:
          link = generatePath(KB_CONTENT_ROUTE, { moduleId: id });
          break;
        case MODULE_TYPES.ALGO:
          link = generatePath(ALGO_FLOW_ROUTE, { moduleId: id });
          break;
        default:
          break;
      }

      setRedirectLink(link);
    }
  };

  useEffect(() => {
    if (redirectLink) {
      window.scrollTo(0, 0);
    }
  }, [redirectLink]);

  const isMirror = module?.type === MODULE_TYPES.MIRROR;
  const type = isMirror ? module.mirrorOf! : module.type;

  // TODO: change Redirect to Link
  if (redirectLink) {
    return (
      <Navigate
        to={redirectLink}
        state={{
          isMirror: isMirror,
          mirrorId: isMirror ? module.id : null,
          mirrorName: isMirror ? module.title : null,
        }}
      />
    );
  }

  return (
    <>
      <tr
        className={twMerge(
          'cursor-pointer border-b border-gray-300 py-[12px]',
          'hover:bg-primary-100'
        )}
        data-testid={getUniqueModuleKey(module)}
        key={module.id}
        onClick={openModule}
        onMouseEnter={() => setShowIcon(true)}
        onMouseLeave={() => setShowIcon(false)}
      >
        <td className='w-full px-[12px] py-[16px] first:pl-2 last:pr-0' align='left'>
          <div className='flex-no-wrap flex flex-row items-center'>
            {module.last_verified && (
              <div>
                <Icons.CheckCircle
                  className='h-8 w-8 pr-2'
                  onMouseOver={() => setShowVerifiedTooltip(true)}
                  onMouseLeave={() => setShowVerifiedTooltip(false)}
                />
                <ModulePopover
                  open={showVerifiedTooltip}
                  onClose={() => setShowVerifiedTooltip(false)}
                  sectionInfoList={[
                    {
                      head: 'Verified',
                      description: format(new Date(module.last_verified), 'MMM d, y, HH:mm a'),
                      owner: module.reviewed_by?.name ? `by ${module.reviewed_by.name}` : '',
                    },
                    ...(module.review_expire_at
                      ? [
                          {
                            head: 'Expired date',
                            //TODO: add expired date
                            description: format(new Date(module.review_expire_at), 'MMM d, y'),
                          },
                        ]
                      : []),
                  ]}
                />
              </div>
            )}
            <H4 className='line-clamp-2 !text-black'>{module.title}</H4>
          </div>
        </td>

        <td className='w-1 whitespace-nowrap px-[12px] py-[16px] first:pl-0 last:pr-0'>
          <Body1>{module.ownerName}</Body1>
        </td>
        <td className='w-1 whitespace-nowrap px-[12px] py-[16px] first:pl-0 last:pr-0'>
          <p className='text-body-1'>{MODULE_TYPES_MAP[type]}</p>
        </td>
        <td className='w-1 whitespace-nowrap px-[12px] py-[16px] first:pl-0 last:pr-0'>
          <ModuleStatus module={module} />
        </td>
        <td
          className='w-1 whitespace-nowrap !px-[12px] py-[16px] first:pl-0 last:pr-0'
          align='left'
        >
          <div className='flex items-center'>
            <div className='grow'>
              <Body1 className='text-gray-700'>
                {module.last_updated && format(new Date(module.last_updated), 'MM/dd/y')}
              </Body1>
            </div>
            <div onClick={(e) => e.stopPropagation()}>
              <ModuleSetting module={module} show={showIcon} closeIcon={() => setShowIcon(false)} />
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};
