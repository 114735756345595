import setPrev from 'actions/miscellaneous/setPrevPath';
import axios from 'axios';
import { isAfter } from 'date-fns';
import { connect } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { LOGIN_ROUTE, MODULE_TYPES } from '../../constants';
import { useAuthentication } from '../../hooks/useAuthentication';

const PrivateRoute = ({
  component: Component,
  componentProps,
  auth,
  clearState,
  setPrev,
  ...rest
}) => {
  const { authentication, isLoggedIn, reset } = useAuthentication();
  const { moduleType } = useParams(); // Extract dynamic path parameters
  // List of valid module types
  const validModuleTypes = [MODULE_TYPES.ALGO, MODULE_TYPES.CALCULATOR];

  // Check if the user is logged in
  if (!isLoggedIn) {
    // If the user is not logged in, set the previous path in redux
    if (rest.path.includes('/invitation/')) {
      setPrev(rest.path);
    } else {
      setPrev('/');
    }
    return <Navigate to={LOGIN_ROUTE} replace />;
  }

  // Check if the authentication token is expired
  if (authentication.token_expiry && isAfter(new Date(), new Date(authentication.token_expiry))) {
    // If the token is expired, remove the token from axios headers
    delete axios.defaults.headers.common['Authorization'];

    // Set the previous path and reset authentication
    if (rest.path.includes('/invitation/')) {
      setPrev(rest.path);
    } else {
      setPrev('/');
      reset();
    }
    return <Navigate to={LOGIN_ROUTE} replace />;
  }
  // Check if the path includes "flow" and validate moduleType
  if (rest.path.includes('flow') && !validModuleTypes.includes(moduleType as MODULE_TYPES)) {
    // Redirect to a 404 page if moduleType is invalid
    return <Navigate to='/404' replace />;
  }
  // If everything is fine, render the requested component
  return <Component {...componentProps} {...rest} />;
};

export default connect(null, { setPrev })(PrivateRoute);
