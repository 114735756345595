import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { createContext, HTMLAttributes, useContext, useEffect, useRef, useState } from 'react';

interface SlidePanel extends HTMLAttributes<HTMLDivElement> {
  open: boolean;
  onClose?: () => void;
  size?: 'small' | 'medium' | 'large';
  scorllTarget?: string;
}

const NestedContext = createContext(false);

export const SlidePanel = ({
  open,
  onClose,
  children,
  scorllTarget = 'module-layout-scroll-target',
}: SlidePanel) => {
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const isNested = useContext(NestedContext);
  const [openState, setOpenState] = useState(open);
  const openRef = useRef(open);
  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOpenState(open);
    openRef.current = open;
  }, [open]);
  const closePanel = () => {
    setOpenState(false);
    openRef.current = false;
  };

  useEffect(() => {
    if (!scorllTarget || !openRef.current || !overlayRef.current) return;

    const handleDocumentScroll = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      if (openRef.current) {
        const target = document.querySelector(`#${scorllTarget}`);
        if (target) {
          const deltaY = (event as WheelEvent).deltaY;
          target.scrollBy(0, deltaY);
        }
      }
    };

    overlayRef.current.addEventListener('wheel', handleDocumentScroll, { passive: false });

    return () => {
      overlayRef.current?.removeEventListener('wheel', handleDocumentScroll);
    };
  }, [openRef.current, overlayRef.current]);

  return (
    <NestedContext.Provider value={true}>
      {openState && (
        <div
          ref={overlayRef}
          className={clsx('fixed inset-0 z-20', !isNested && 'bg-gray-bg-bright')}
          // onClick={() => setCloseModalOpen(true)}
          // onScroll={handleScroll}
        />
      )}
      <AnimatePresence>
        {openState && (
          <>
            <motion.div
              className={clsx(
                'fixed top-0 z-30 h-screen w-[424px] bg-white pt-[51px]',
                isNested ? '-translate-x-[424px]' : 'right-0'
              )}
              initial={{ right: '-424px', opacity: 0 }}
              animate={{
                right: 0,
                opacity: 1,
                transition: { ease: 'easeInOut' },
              }}
              exit={{
                right: '-424px',
                opacity: 0,
                transition: { ease: 'easeInOut' },
              }}
            >
              <div className='h-full w-full overflow-hidden shadow-08'>{children}</div>
            </motion.div>
            {isNested && (
              <div className='pointer-events-none fixed right-0 top-0 z-20 h-full w-[424px] bg-black opacity-30' />
            )}
          </>
        )}
      </AnimatePresence>
    </NestedContext.Provider>
  );
};
