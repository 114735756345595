import { useMutation } from '@tanstack/react-query';
import getUniqueCode from 'actions/unique-code/getUniqueCodeAction';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { Trigger, resourcesAPI } from '../../../api/resources';
import { useTriggers } from '../../../hooks/module/resources/useTriggers';
import { useVariables } from '../../../hooks/module/useVariables';
import { mapConditionChoiceUniqueCode } from '../../../utils/utilityFunctions';
import { ConfirmModal } from '../../utils/modals/ConfirmModal';
import TextHighlighter from '../../utils/text-higlighter/textHighlighter';
import { Caption2, H4 } from '../../utils/typo';
import { HasEditPermissionContext, ResourceFormModuleTypes, ResourceTable } from '../ResourceTable';
import { FloatingActionButton } from '../util/FloatingActionButton';
import SituationBuilder from './SituationBuilder';
import TriggersUsedIn from './TriggersUsedIn';

interface RequiredResourceFormProps {
  triggerId?: string;
  toggleModal: () => void;
  type: ResourceFormModuleTypes;
  isFullHeight: boolean;
  createModal: boolean;
  toogleModalPane: () => void;
  modal: boolean;

  savePosition: () => void;
}

const labels = {
  title: 'Triggers',
  input: 'trigger',
  button: 'Trigger',
  data: 'triggers',
};

export const TriggerResourceTable = () => {
  const { module } = useContext(ModuleContext);
  const {
    data: { triggers },
  } = useTriggers();

  const [open, setOpen] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [resourceId, setResourceId] = useState<string | null>(null);
  const onOpen = (resourceId: string | null) => {
    setOpen(true);
    setResourceId(resourceId);
  };
  const onClose = () => {
    setOpen(false);
  };

  const resourceFormProps: RequiredResourceFormProps = useMemo(
    () => ({
      triggerId: resourceId ?? undefined,
      toggleModal: onClose,
      type: module?.type as ResourceFormModuleTypes,
      isFullHeight: true,
      createModal: !resourceId,
      toogleModalPane: () => setModal(false),
      modal: modal,

      savePosition: () => {},
    }),
    [open, modal, module]
  );

  // TODO: remove when redux resource data is depracated
  const dispatch = useDispatch();
  useEffect(() => {
    if (!module) return;
    dispatch(getUniqueCode(module.id, module.type));
  }, [triggers, module]);

  return (
    <>
      <ResourceTable
        moduleType={module?.type as ResourceFormModuleTypes}
        labels={labels}
        data={triggers.filter((trigger) => trigger.title !== 'Always On')}
        renderCard={(trigger) => ResourceCard(trigger, onOpen)}
        onOpen={onOpen}
      />
      <SlidingPane
        isOpen={open}
        onRequestClose={() => {
          setModal(true);
        }}
        from='right'
        hideHeader
        className='no-padding sliding-panel-shadow'
        width='622px'
      >
        <div className='mt-14'>
          <SituationBuilder {...resourceFormProps} />
        </div>
      </SlidingPane>
    </>
  );
};

const ResourceCard = (
  resource: Trigger,
  onOpen: (resourceId: string | null) => void
): ReactNode => {
  const { refetch } = useTriggers();
  const { data: variables } = useVariables();
  const { mutate } = useMutation({
    mutationFn: resourcesAPI.deleteTrigger,
    onSuccess: () => refetch(),
  });
  const [open, setOpen] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const hasEditPermission = useContext(HasEditPermissionContext);
  const onEdit = () => hasEditPermission && onOpen(resource.id);
  const onDelete = () => {
    mutate(resource.id);
    setDeleteModal(false);
  };
  const toggleDelete = () => setDeleteModal((prev) => !prev);

  return (
    <>
      <div
        className='group flex select-none divide-x divide-gray-200 rounded-xl bg-white py-[18px] hover:!bg-primary-200'
        onDoubleClick={onEdit}
      >
        <div className='w-[70%] space-y-2 px-[20px]'>
          <H4>{resource.title}</H4>
          <Caption2 className='mt-4 text-gray-700'>Condition</Caption2>
          <div>
            {resource.condition && (
              <TextHighlighter text={mapConditionChoiceUniqueCode(variables, resource.condition)} />
            )}
          </div>
        </div>
        <div className='flex w-[30%] px-[20px]'>
          <div className='w-full'>
            <Caption2 className='text-gray-700'>Used In</Caption2>
            <div className='mt-2.5'>
              <TriggersUsedIn trigger={resource} onlyThree={true} />
              <TriggersUsedIn trigger={resource} runMore={true} />
            </div>
          </div>
          {hasEditPermission && (
            <FloatingActionButton
              open={open}
              onClose={() => setOpen(false)}
              onEdit={onEdit}
              onDelete={toggleDelete}
            />
          )}
        </div>
      </div>
      <ConfirmModal
        preset='delete'
        open={deleteModal}
        content='Are you sure you want to delete this trigger?'
        toggleModal={toggleDelete}
        performAction={onDelete}
      />
    </>
  );
};
