import { Listbox } from '@headlessui/react';
import { rankItem } from '@tanstack/match-sorter-utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ReactNode, useMemo, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { ehrAPI, Medication } from '../../../api/ehr';
import { ROLES } from '../../../api/team';
import { MainSearchIcon } from '../../../assets/icons/MainSearchIcon';
import { useTeam } from '../../../hooks/useTeam';
import Button from '../../utils/Button';
import { Icons } from '../../utils/Icons';
import { ConfirmModal } from '../../utils/modals/ConfirmModal';
import { SlidePanel } from '../../utils/panels/SlidePanel';
import { Skeleton } from '../../utils/Skeleton';
import { Body1, Body2, H2, H4 } from '../../utils/typo';
import { EHROrderForm } from './EHROrderForm';

enum SortOptions {
  LATEST = 'Updated: Latest',
  OLDEST = 'Updated: Oldest',
  ATOZ = 'Alphabetical: A-Z',
  ZTOA = 'Alphabetical: Z-A',
}

const Sortings: Record<keyof typeof SortOptions, SortingState> = {
  LATEST: [{ id: 'last_updated', desc: true }],
  OLDEST: [{ id: 'last_updated', desc: false }],
  ATOZ: [{ id: 'name', desc: false }],
  ZTOA: [{ id: 'name', desc: true }],
};

const extractTextFromContent = (content) => {
  let text = '';

  content.forEach((node) => {
    if (node.text) {
      text += node.text;
    }

    if (node.content) {
      text += ' ' + extractTextFromContent(node.content);
    }
  });

  return text;
};

const globalFilterFn: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);
  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export const EHROrderIndividualTable = () => {
  const { team } = useTeam();
  const isAdmin = team.current_teammate?.role === ROLES.Admin;
  const [medication, setMedication] = useState<Medication | undefined>();
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteTargetId, setDeleteTargetId] = useState<string | null>(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sortBy, setSortBy] = useState<keyof typeof SortOptions>('LATEST');

  const { data, refetch, isLoading } = useQuery({
    queryKey: ['team', 'medications', 'individual'],
    queryFn: ehrAPI.getMedications,
    initialData: [],
    initialDataUpdatedAt: 0,
  });

  const { mutate } = useMutation({
    mutationFn: ehrAPI.deleteMedication,
    onSuccess: refetch,
  });

  const handleDelete = () => {
    !!deleteTargetId && mutate(deleteTargetId);
    setDeleteTargetId(null);
  };

  const columns = useMemo<ColumnDef<Medication>[]>(
    () => [
      {
        header: 'Order Name',
        accessorKey: 'name',
        cell: (cell) => <div className='whitespace-nowrap'>{cell.renderValue() as ReactNode}</div>,
      },
      {
        header: 'Code',
        accessorKey: 'code',
        cell: (cell) => (
          <div className='line-clamp-1' title={cell.getValue() + ''}>
            {cell.renderValue() as ReactNode}
          </div>
        ),
      },
      {
        header: 'Indication',
        accessorKey: 'indication',
        cell: (cell) => (
          <div className='line-clamp-1'>
            {extractTextFromContent(cell.row.original.indication?.content || [])}
          </div>
        ),
      },
      ...(isAdmin
        ? [
            {
              header: 'Action',
              accessorKey: 'id',
              cell: (cell) => {
                const handleOpen = () => {
                  setMedication(cell.row.original);
                  setOpen(true);
                };
                return (
                  <div className='flex space-x-[12px]'>
                    <div title='Edit' className='cursor-pointer' onClick={handleOpen}>
                      <Icons.Edit className='fill-gray-600 hover:fill-primary-600' />
                    </div>
                    <div title='Delete' className='cursor-pointer'>
                      <Icons.TrashBin
                        className='fill-gray-600 hover:fill-primary-600'
                        onClick={() => setDeleteTargetId(cell.row.original.unique_code)}
                      />
                    </div>
                  </div>
                );
              },
            },
          ]
        : []),
      {
        id: 'last_updated',
        accessorFn: (row) => new Date(row.last_updated),
        sortingFn: 'datetime',
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    // for filter
    state: {
      globalFilter,
    },
    globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    // for sorting
    initialState: {
      columnVisibility: { last_updated: false },
      sorting: [
        {
          id: 'last_updated',
          desc: true,
        },
      ],
    },
    getSortedRowModel: getSortedRowModel(),
  });

  const handleSort = (sortBy: keyof typeof SortOptions) => {
    setSortBy(sortBy);
    table.setSorting((old) => Sortings[sortBy]);
  };

  const handleOpen = () => {
    setMedication(undefined);
    setOpen(true);
  };

  return (
    <>
      <div className='w-full space-y-4'>
        <div className='sticky top-0 w-full'>
          <div className='space-y-6'>
            <div className='relative flex items-center justify-between'>
              <H2>
                <span className='text-gray-700'>EHR Orders:</span> Individual
              </H2>
              <div className='flex gap-2'>
                <div className='flex min-w-[336px] items-center gap-[4px] rounded border border-gray-300 bg-white p-2'>
                  <MainSearchIcon />
                  <input
                    className='m-0 !h-[20px] w-full !border-none text-[14px] leading-[20px] text-gray-900 placeholder-gray-300 shadow-none focus:border-none focus:outline-none'
                    type='text'
                    placeholder='Search EHR Order'
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                  />
                </div>
                {isAdmin && (
                  <Button className='flex items-center' onClick={handleOpen}>
                    <Icons.Plus className='h-4 fill-white' />
                    Create Order
                  </Button>
                )}
              </div>
            </div>
            {data.length > 0 && (
              <div className='flex items-center gap-2'>
                <Body1>Sort by</Body1>
                <Listbox
                  value={sortBy}
                  onChange={handleSort}
                  className='relative w-[180px] bg-white'
                  as='div'
                >
                  <Listbox.Button className='flex w-full justify-between rounded border border-gray-200 p-2 focus:bg-white'>
                    <Body2>{SortOptions[sortBy]}</Body2>
                    <Icons.CaretDown />
                  </Listbox.Button>
                  <Listbox.Options className='absolute top-10 z-10 w-full bg-white'>
                    {Object.keys(SortOptions).map((sortOption) => (
                      <Listbox.Option
                        key={sortOption}
                        value={sortOption}
                        className='p-2 hover:bg-primary-300'
                      >
                        {SortOptions[sortOption]}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Listbox>
              </div>
            )}
          </div>
        </div>
        {!isLoading && table.getRowModel().rows.length === 0 ? (
          <div className='!mt-[300px] text-center text-gray-700'>
            <H4>No EHR orders yet.</H4>
          </div>
        ) : (
          <table className='border-strong w-full border bg-white'>
            {table.getHeaderGroups().map((headerGroup) => (
              <thead className='border-b-2 border-gray-300' key={headerGroup.id}>
                {headerGroup.headers.map((header) =>
                  header.isPlaceholder ? null : (
                    <th
                      key={header.id}
                      className={twJoin(
                        'px-[8px] py-[16px] text-left first:w-[374px] first:pl-[16px] last:pr-[16px] [&:nth-child(2)]:w-[332px]',
                        isAdmin && 'last:w-[76px]'
                      )}
                    >
                      <H4 className='whitespace-nowrap text-gray-700'>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </H4>
                    </th>
                  )
                )}
              </thead>
            ))}
            <tbody>
              {isLoading ? (
                <SkeletonBody />
              ) : (
                table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className='px-[8px] py-[12px] first:pl-[16px] last:pr-[16px]'
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        )}
      </div>
      <SlidePanel open={open} onClose={() => setModalOpen(true)}>
        <EHROrderForm
          medication={medication}
          onClose={() => setOpen(false)}
          refetch={refetch}
          modalOpen={modalOpen}
          toggleModal={() => setModalOpen(!modalOpen)}
        />
      </SlidePanel>
      <ConfirmModal
        preset='delete'
        open={!!deleteTargetId}
        content='Are you sure you want to delete this Team Member'
        toggleModal={() => setDeleteTargetId(null)}
        performAction={handleDelete}
      />
    </>
  );
};

const SkeletonBody = () => {
  const { team } = useTeam();
  const isAdmin = team.current_teammate?.role === ROLES.Admin;

  return (
    <tr>
      <td className='min-w-[374px] px-[8px] py-[12px] pl-[16px]'>
        <Skeleton className='h-[20px] bg-gray-200' />
      </td>
      <td className='min-w-[332px] px-[8px] py-[12px]'>
        <Skeleton className='h-[20px] bg-gray-200' />
      </td>
      <td className='w-full px-[8px] py-[12px]'>
        <Skeleton className='h-[20px] bg-gray-200' />
      </td>
      {isAdmin && (
        <td className='min-w-[72px] px-[8px] py-[12px] pr-[16px]'>
          <Skeleton className='h-[20px] bg-gray-200' />
        </td>
      )}
    </tr>
  );
};
