import { Listbox, Popover } from '@headlessui/react';
import { rankItem } from '@tanstack/match-sorter-utils';
import {
  ColumnDef,
  FilterFn,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Fragment,
  HTMLAttributes,
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { WithLastUpdated } from '../../api/resources';
import { MainSearchIcon } from '../../assets/icons/MainSearchIcon';
import { MODULE_TYPES } from '../../constants';
import { hasViewOnlyPermission } from '../../utils/permissions';
import { Icons } from '../utils/Icons';
import { Body1, Body2, H2, H4 } from '../utils/typo';

export type ResourceFormModuleTypes = MODULE_TYPES.ALGO | MODULE_TYPES.CALCULATOR;

enum SortOptions {
  LATEST = 'Updated: Latest',
  OLDEST = 'Updated: Oldest',
  ATOZ = 'Alphabetical: A-Z',
  ZTOA = 'Alphabetical: Z-A',
}

const Sortings: Record<keyof typeof SortOptions, SortingState> = {
  LATEST: [{ id: 'last_updated', desc: true }],
  OLDEST: [{ id: 'last_updated', desc: false }],
  ATOZ: [{ id: 'cell', desc: false }],
  ZTOA: [{ id: 'cell', desc: true }],
};

const globalFilterFn: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);
  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

interface Labels {
  title: string;
  input: string;
  button: string;
  data: string;
}

interface ResourceTableProps<T extends WithLastUpdated> extends HTMLAttributes<HTMLDivElement> {
  moduleType: ResourceFormModuleTypes;
  labels: Labels;
  data: T[];
  renderCard: (resource: T) => ReactNode;
  onOpen: (resourceId: string | null) => void;
}

export const HasEditPermissionContext = createContext<boolean>(false);

export const ResourceTable = <T extends WithLastUpdated>({
  children,
  moduleType,
  labels,
  data,
  renderCard,
  onOpen,
}: ResourceTableProps<T>) => {
  const [globalFilter, setGlobalFilter] = useState('');

  useEffect(() => {
    // When switching between tabs, always start from the top.
    window.scrollTo(0, 0);
  }, []);

  const [sortBy, setSortBy] = useState<keyof typeof SortOptions>('LATEST');

  const columns = useMemo<ColumnDef<T>[]>(
    () => [
      {
        id: 'cell',
        cell: (cell) => renderCard(cell.row.original),
        // if multiple key or complicated logic(eg. value is object) is needed, use accessorFn like below.
        // accessorFn: (row) => row.title + row.condition,
        accessorFn: (row) => {
          if (Object.hasOwn(row, 'title')) {
            return row['title'];
          } else if (Object.hasOwn(row, 'name')) {
            return row['name'];
          } else if (Object.hasOwn(row, 'source')) {
            return row['source'];
          } else {
            // for calculator integration
            return row['integrated_calculator_info']['name'];
          }
        },
        sortingFn: 'alphanumeric',
      },
      {
        id: 'last_updated',
        accessorFn: (row) => new Date(row.last_updated),
        sortingFn: 'datetime',
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    // for filter
    state: {
      globalFilter,
    },
    globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    // for sorting
    initialState: {
      columnVisibility: { last_updated: false },
      sorting: [
        {
          id: 'last_updated',
          desc: true,
        },
      ],
    },
    getSortedRowModel: getSortedRowModel(),
  });

  const handleSort = (sortBy: keyof typeof SortOptions) => {
    setSortBy(sortBy);
    table.setSorting((old) => Sortings[sortBy]);
  };

  const [hasEditPermission, setHasEditPermission] = useState<boolean>(
    !hasViewOnlyPermission(moduleType)
  );

  return (
    <div className='w-full space-y-4'>
      <div className='sticky top-0 w-full bg-primary-bg-resource p-8'>
        <div className='space-y-6'>
          <div className='relative flex items-center justify-between'>
            <H2>{labels.title}</H2>
            <div className='flex gap-2'>
              <div className='flex min-w-[336px] items-center gap-[4px] rounded border border-gray-300 bg-white p-2'>
                <MainSearchIcon />
                <input
                  className='m-0 !h-[20px] w-full !border-none text-[14px] leading-[20px] text-gray-900 placeholder-gray-300 shadow-none focus:border-none focus:outline-none'
                  type='text'
                  placeholder={`Search ${labels.input}`}
                  value={globalFilter}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                />
              </div>
              {!hasViewOnlyPermission(moduleType) && (
                <Popover className='relative'>
                  <Popover.Button
                    className='flex items-center rounded-[4px] bg-primary-500 p-2 text-button-1 font-semibold text-white hover:bg-primary-600'
                    onClick={() => onOpen(null)}
                  >
                    <Icons.Plus className='h-4 fill-white' />
                    Create {labels.button}
                  </Popover.Button>
                  {children}
                </Popover>
              )}
            </div>
          </div>
          <div className='flex items-center gap-2'>
            <Body1>Sort by</Body1>
            <Listbox
              value={sortBy}
              onChange={handleSort}
              className='relative w-[180px] bg-white'
              as='div'
            >
              <Listbox.Button className='flex w-full justify-between rounded border border-gray-200 p-2 focus:bg-white'>
                <Body2>{SortOptions[sortBy]}</Body2>
                <Icons.CaretDown />
              </Listbox.Button>
              <Listbox.Options className='absolute top-10 z-10 w-full bg-white'>
                {Object.keys(SortOptions).map((sortOption) => (
                  <Listbox.Option
                    key={sortOption}
                    value={sortOption}
                    className='p-2 hover:bg-primary-300'
                  >
                    {SortOptions[sortOption]}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Listbox>
          </div>
        </div>
      </div>
      <div className='space-y-4 p-8'>
        {table.getRowModel().rows.length == 0 ? (
          <div className='mt-[300px] text-center text-gray-700'>
            <H4>No {labels.data} yet.</H4>
          </div>
        ) : (
          table.getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <HasEditPermissionContext.Provider value={hasEditPermission} key={cell.id}>
                  <div key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                </HasEditPermissionContext.Provider>
              ))}
            </Fragment>
          ))
        )}
      </div>
    </div>
  );
};

ResourceTable.PopoverPanel = ({ children }) => (
  <Popover.Panel className='absolute right-0 z-10 mt-2 rounded bg-white shadow-04'>
    {children}
  </Popover.Panel>
);
