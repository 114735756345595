const ChannelRoutes = [
  // {
  //   type: 'PRIVATE',
  //   path: CHANNELS_ROUTE,
  //   component: OldChannelPage,
  // },
  // {
  //   type: 'PRIVATE',
  //   path: CHANNEL_CREATE_ROUTE,
  //   // component: ChannelsForm,
  //   component: OldChannelFormPage,
  // },
  // {
  //   type: 'PRIVATE',
  //   path: CHANNEL_ROUTE,
  //   component: OldChannelFormPage,
  // },
];

export default ChannelRoutes;
