const IndexcRoutes = [
  // {
  //   type: 'PRIVATE',
  //   path: '/',
  //   component: Home,
  // },
  // {
  //   type: 'PRIVATE',
  //   path: PROFILE_ROUTE,
  //   component: Profile,
  // },
];

export default IndexcRoutes;
