import { Listbox } from '@headlessui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { ROLES, TeamMate } from '../../api/team';
import { ReactComponent as ArrowDown } from '../../assets/icons/v2/general/ic_arrow_down.svg';
import { ReactComponent as Check } from '../../assets/icons/v2/item/check.svg';
import { ReactComponent as Delete } from '../../assets/icons/v2/item/trash_bin.svg';
import { HOME_ROUTE } from '../../constants';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useTeam } from '../../hooks/useTeam';
import Avatar from '../utils/Avatar';
import { DeleteModal } from '../utils/modals/DeleteModal';

interface MemberRowProps {
  member: TeamMate;
  isAdmin: boolean;
  uniqueAdminNo?: number;
}

const MemberRow = ({ member, isAdmin, uniqueAdminNo }: MemberRowProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { patchRole, deleteMember } = useTeam();
  const handleChange = (item) => {
    patchRole({
      id: member.id,
      role: item,
    });
  };

  const navigate = useNavigate();
  const { authentication } = useAuthentication();
  const handleDelete = () => {
    deleteMember(member.id);
    if (authentication.user?.id && authentication.user.id === member.user.id) {
      navigate(HOME_ROUTE, { replace: true });
    }
  };
  return (
    <tr>
      <td className='w-1/2'>
        <div className='flex items-center gap-[16px] py-[12px]'>
          <Avatar name={member.user.name} />
          <p className='text-body-1'>{member.user.name}</p>
        </div>
      </td>
      <td className='w-1/2 py-[12px]'>
        <div className='relative flex w-5/6 justify-between'>
          {isAdmin ? (
            <Listbox onChange={handleChange}>
              {({ open }) => (
                <>
                  <Listbox.Button>
                    <div className='flex w-28 items-center justify-between gap-[4px]'>
                      <p className='text-body-1'>{member.role_display_value}</p>
                      <ArrowDown className={twMerge(open && 'rotate-180', 'transition')} />
                    </div>
                  </Listbox.Button>
                  <Listbox.Options className='absolute top-10 z-10 max-h-[310px] w-[160px] overflow-y-auto rounded-sm bg-white py-[4px] shadow-04'>
                    {Object.values(ROLES)
                      .filter((role) => member.role !== role)
                      .map((role) => (
                        <Listbox.Option
                          key={role}
                          value={role}
                          className='cursor-pointer px-[12px] py-[10px]'
                        >
                          <div className='flex justify-between'>
                            <p>{Object.keys(ROLES).find((key) => ROLES[key] === role)}</p>
                            <Check />
                          </div>
                        </Listbox.Option>
                      ))}
                  </Listbox.Options>
                </>
              )}
            </Listbox>
          ) : (
            <p className='text-body-1'>{member.role_display_value}</p>
          )}
          {isAdmin && uniqueAdminNo !== member.id && (
            <Delete
              className='cursor-pointer fill-gray-600 hover:fill-error'
              onClick={() => setIsOpen(true)}
            />
          )}
          <DeleteModal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            onDelete={handleDelete}
            title='Delete Member'
            content='Are you sure you want to delete this member?'
          />
        </div>
      </td>
    </tr>
  );
};

export default MemberRow;
