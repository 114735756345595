import { useMutation, useQuery } from '@tanstack/react-query';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import { CustomToast } from 'components/utils/toast-message';
import { useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { profileApi } from '../../../api/profile';
import { DEFAULT_PROFILE_URL, ROLE_OPTIONS, SPECIALTIES_OPTIONS } from '../../../constants';
import { useAuthentication } from '../../../hooks/useAuthentication';
import Button from '../../utils/Button';
import Checkbox from '../../utils/Checkbox';
import Input from '../../utils/Input';
import Label from '../../utils/Label';
import { MultiSelect } from '../../utils/MultiSelect';

interface ProfileInput {
  first_name: string;
  last_name: string;
  email: string;
  institution: string;
  specialties: string[];
  roles: string[];
  subscribed_to_newsletter: boolean;
  profile_picture?: File;
  is_staff: boolean;
}

interface ProfileFormProps {
  onOpen: () => void;
}

const ProfileForm = ({ onOpen }: ProfileFormProps) => {
  const { data: userInfo } = useQuery({
    queryKey: ['userInfo'],
    queryFn: profileApi.getProfile,
    initialData: {
      id: 0,
      first_name: '',
      last_name: '',
      email: '',
      institution: '',
      medical_specialties: [],
      role: [],
      subscribed_to_newsletter: false,
      is_staff: false,
      job_title: '',
    },
  });
  const [fileSrc, setFileSrc] = useState<string>(userInfo.profile_picture ?? DEFAULT_PROFILE_URL);

  const fullName = useMemo(() => `${userInfo.first_name} ${userInfo.last_name}`, [userInfo]);
  const defaultValues = useMemo<ProfileInput>(
    () => ({
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      email: userInfo.email,
      institution: userInfo.institution,
      specialties: userInfo.medical_specialties || [],
      roles: userInfo.role || [],
      subscribed_to_newsletter: userInfo.subscribed_to_newsletter,
      is_staff: userInfo.is_staff,
    }),
    [userInfo]
  );
  const { register, handleSubmit, setValue, control, reset } = useForm<ProfileInput>({
    defaultValues,
    mode: 'all',
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const { refetch, authentication } = useAuthentication();
  const { mutate, isPending } = useMutation({
    mutationFn: profileApi.putProfile,
    onSuccess: () => {
      refetch();
      toast.success(CustomToast, { data: 'Personal Information Updated' });
    },
  });
  const onSubmit: SubmitHandler<ProfileInput> = (data) => {
    if (isPending) return;

    const formData = Object.keys(data).reduce((formData, key) => {
      const value =
        typeof data[key] === 'boolean' || Array.isArray(data[key])
          ? JSON.stringify(data[key])
          : data[key];
      formData.set(key, value);
      return formData;
    }, new FormData());

    mutate(formData);
  };

  const onInvalid = () => {
    toast.error(CustomToast, { data: 'Required field is empty' });
  };

  const onImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

    if (allowedExtensions.exec(file.name)) {
      setValue('profile_picture', file);
      setFileSrc(URL.createObjectURL(file));
    } else {
      toast.warning(CustomToast, { data: 'Only file can be Uploaded' });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onInvalid)} className='w-full'>
      {/* need custom shadow */}
      <img
        src={fileSrc}
        alt='Your Image'
        onClick={onImageClick}
        className='m-auto h-[107px] w-[107px] cursor-pointer rounded-[50%] border-[3px] border-solid border-white shadow-04'
      />
      <Input ref={fileInputRef} type='file' className='hidden' onChange={handleFileChange} />
      <p className='pt-[10px] text-center text-[18px] font-semibold'>{fullName}</p>
      <div className='flex flex-wrap space-y-[15px]'>
        <Label className='w-full space-y-2'>
          <span className='text-body-2 text-gray-700'>Email</span>
          <Input
            className='!h-12 border-0 !bg-gray-200 !px-[15px] !py-[15px] text-gray-500'
            disabled={true}
            value={userInfo.email}
          />
        </Label>
        <div className='flex w-full space-x-[30px]'>
          <Label className='basis-1/2 space-y-2'>
            <span className='text-body-2 text-gray-700'>First Name</span>
            <Input
              className='!h-12 !border-gray-200 !px-[15px] !py-[15px]'
              required
              {...register('first_name', { required: true })}
            />
          </Label>
          <Label className='basis-1/2 space-y-2'>
            <span className='text-body-2 text-gray-700'>Last Name</span>
            <Input
              className='!h-12 !border-gray-200 !px-[15px] !py-[15px]'
              required
              {...register('last_name', { required: true })}
            />
          </Label>
        </div>
        <Label className='w-full space-y-2'>
          <span className='text-body-2 text-gray-700'>Institution</span>
          <Input
            className='!h-12 !border-gray-200 !px-[15px] !py-[15px]'
            {...register('institution')}
          />
        </Label>
        <div className='w-full space-y-2'>
          <span className='text-body-2 text-gray-700'>Role</span>
          <MultiSelect options={ROLE_OPTIONS} name='roles' placeholder='Roles' control={control} />
        </div>
        <div className='w-full space-y-2'>
          <span className='text-body-2 text-gray-700'>Specialities</span>
          <MultiSelect
            options={SPECIALTIES_OPTIONS}
            name='specialties'
            placeholder='Specialties'
            control={control}
          />
        </div>
        <Label className='ml-[-4px] flex w-full cursor-pointer items-center space-x-[8px] pt-[10px]'>
          <Checkbox
            type='checkbox'
            defaultChecked={true}
            {...register('subscribed_to_newsletter')}
          />
          <span>Subscribe to our newsletter</span>
        </Label>
        <div className='flex w-full justify-between pt-[15px]'>
          <div>
            {(authentication.auth_type === 'knox' || authentication.providerId === 'password') && (
              <Button type='button' className='w-[144px] py-[8px] font-bold' onClick={onOpen}>
                Change Password
              </Button>
            )}
          </div>
          <div className='flex space-x-[10px]'>
            <Link to='/'>
              <Button
                type='button'
                className='bg-transparent px-[15px] py-[8px] font-extrabold !text-primary-500 hover:bg-primary-600'
              >
                Cancel
              </Button>
            </Link>
            <Button className='!w-[90px] py-[8px] font-bold' disabled={isPending}>
              {isPending ? <LoadingSpinner size='small' /> : 'Update'}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ProfileForm;
