import { useQuery } from '@tanstack/react-query';
import { ComponentType, useContext } from 'react';
import { resourcesAPI, Trigger } from '../../../api/resources';
import { ModuleContext } from '../../../components/utils/module/ModuleContext';

interface Triggers {
  triggers: Trigger[];
  candidate_triggers: Trigger[];
}

export function useTriggers() {
  const { module } = useContext(ModuleContext);
  return useQuery({
    queryKey: [module?.type, module?.id, 'triggers'],
    queryFn: resourcesAPI.getTriggers,
    initialData: [],
    select: (data): Triggers => ({
      triggers: data.filter((trigger) => !trigger.candidate_trigger),
      candidate_triggers: data.filter((trigger) => trigger.candidate_trigger),
    }),
    staleTime: Infinity,
    initialDataUpdatedAt: 0,
  });
}

export function withTriggers<P extends object>(WrappedComponent: ComponentType<P>) {
  return (props: P) => {
    const { data, refetch } = useTriggers();
    return <WrappedComponent triggerState={data} refetchTriggers={refetch} {...props} />;
  };
}
