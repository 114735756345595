import Button from 'components/utils/Button';
import { Modal } from '../modals/Modal';

interface AvoSlideCloseModalProps {
  open: boolean;
  onDiscard: () => void;
  onContinue: () => void;
}

export const AvoSlideCloseModal = ({ onContinue, onDiscard, open }: AvoSlideCloseModalProps) => {
  return (
    <Modal open={open} onClose={onContinue} size='small' className='p-0'>
      <Modal.Head onClose={onContinue} className='m-0 px-[16px] py-[12px]'>
        Discard changes?
      </Modal.Head>
      <Modal.Body className='p-[16px] pb-[24px]'>
        You have unsaved changes. If you exit now, your progress will be lost.
      </Modal.Body>
      <Modal.Footer className='flex gap-[8px] px-[16px] py-[12px]'>
        <Button.Outline className='border-gray-200 !text-gray-700' onClick={onContinue}>
          Continue Editing
        </Button.Outline>
        <Button.Warning
          onClick={() => {
            onContinue();
            onDiscard();
          }}
        >
          Discard
        </Button.Warning>
      </Modal.Footer>
    </Modal>
  );
};
