export const LOGIN_ACTION = 'LOGIN_ACTION';
export const LOGOUT_ACTION = 'LOGOUT_ACTION';
export const RESET_STATE = 'RESET_STATE';

export const GET_MODULES_LIST = 'GET_MODULES_LIST';
export const GET_MODULE = 'GET_MODULE';
export const GET_CALCULATOR = 'GET_CALCULATOR';

export const GET_UNIQUE_CODE = 'GET_UNIQUE_CODE';

export const SET_PATH = 'SET_PATH';

export const GET_INFOBOXES = 'GET_INFOBOXES';
export const GET_NOTE_GENERATOR = 'GET_NOTE_GENERATOR';
export const GET_REF_MANAGER = 'GET_REF_MANAGER';
export const GET_FORMULAS = 'GET_FORMULAS';
export const GET_MEDIA = 'GET_MEDIA';
export const GET_CONDITIONAL_TEXTS = 'GET_CONDITIONAL_TEXTS';
export const GET_CUSTOM_NUMERICS = 'GET_CUSTOM_NUMERICS';

export const GET_CALCULATORS = 'GET_CALCULATORS';
export const GET_UNIVERSAL_CALCULATORS = 'GET_UNIVERSAL_CALCULATORS';

export const GET_TEAM_CALCULATORS = 'GET_TEAM_CALCULATORS';
export const GET_TEAM_MIRRORS = 'GET_TEAM_MIRRORS';

export const SET_IS_MIRROR = 'SET_IS_MIRROR';
export const SET_IS_CALC_MIRROR = 'SET_IS_CALC_MIRROR';

export const GET_KNOWLEDGE_BASE = 'GET_KNOWLEDGE_BASE';
export const GET_KNOWLEDGE_BASE_LIST = 'GET_KNOWLEDGE_BASE_LIST';
export const SET_IS_KB_MIRROR = 'SET_IS_KB_MIRROR';
export const SET_IS_FORM_DISABLED = 'SET_IS_FORM_DISABLED';
