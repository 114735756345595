import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';

import axios from 'axios';
import 'components/utils/modals/modal.css';
import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'reactstrap';
import { CHANNEL_LIST_API_URL } from '../../../../constants';
import { withAuthentication } from '../../../../hooks/useAuthentication';
import { withTeam } from '../../../../hooks/useTeam';
import { ChannelWrapper } from '../sync-modal/styles';
import {
  ChannelBox,
  ChannelLastSynced,
  ChannelName,
  ChannelNameBox,
  ChannelsList,
  FormSubtitle,
  FormTitle,
  GreenCheckbox,
  UniversalCheckboxContainer,
  UniversalCheckboxLabel,
} from './styles';

class FirebaseChannelForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      channels: [],
      channelCheck: [],
      channelsToProps: [],
    };
  }

  checkModuleExists = (channel) => {
    const modules = channel.modules || [];
    for (let module of modules) {
      if (module.id === this.props.module.id) return true;
    }

    return false;
  };

  checkCalculatorExists = (channel) => {
    const calculators = channel.calculators || [];
    for (let calculator of calculators) {
      if (calculator.id === this.props.module.id) return true;
    }

    return false;
  };

  checkKnowledgeBaseExists = (channel) => {
    const knowledgeBases = channel.knowledge_bases || [];
    for (let knowledgeBase of knowledgeBases) {
      if (knowledgeBase.id === this.props.module.id) return true;
    }

    return false;
  };

  setCheckedChannels = async (res) => {
    let checkedChannels = [];
    res.forEach((channel) => {
      if (this.props.isCalc) {
        const bool = this.checkCalculatorExists(channel);
        if (bool === true && channel.last_synced) {
          checkedChannels.push(true);
        } else {
          checkedChannels.push(false);
        }
      } else if (this.props.isAIKnowledgeBase) {
        const bool = this.checkKnowledgeBaseExists(channel);
        if (bool === true && channel.last_synced) {
          checkedChannels.push(true);
        } else {
          checkedChannels.push(false);
        }
      } else {
        const bool = this.checkModuleExists(channel);
        if (bool === true && channel.last_synced) {
          checkedChannels.push(true);
        } else {
          checkedChannels.push(false);
        }
      }
    });
    return checkedChannels;
  };

  componentWillMount() {
    axios.get(CHANNEL_LIST_API_URL + this.props.team.id).then((res) => {
      this.setCheckedChannels(res.data).then((checkedChannels) => {
        this.setState(
          {
            channels: res.data,
            channelCheck: checkedChannels,
          },
          () => {
            // This block make sure will call after updating the state
            this.getChannelsToSync(this.state.channelCheck).then((value) =>
              this.props.handleChannelSelection(value)
            );
          }
        );
      });
    });

    if (this.props.isCalc) {
      this.props.setIsUniversal(this.props.teamCalculatorState?.calculator?.is_universal || false);
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onKeyPress = (e) => {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  };

  selectChannels = async (key) => {
    let channelsToAdd = [...this.state.channelCheck];
    channelsToAdd[key] = !channelsToAdd[key];
    this.setState({ channelCheck: channelsToAdd });
    return channelsToAdd;
  };

  getChannelsToSync = async (value) => {
    var channelsToSync = [];
    value.forEach((element, index) => {
      if (element === true) {
        channelsToSync.push(this.state.channels[index].id);
      }
    });
    return channelsToSync;
  };

  handleChannelSelection = (event, key) => {
    this.selectChannels(key).then((channelsToAdd) => {
      this.getChannelsToSync(channelsToAdd).then((channelsToSync) =>
        this.props.handleChannelSelection(channelsToSync)
      );
    });
  };

  render() {
    let channels = this.state.channels;
    const isStaff = this.props.authentication.user?.is_staff;

    return (
      <div style={{ height: '100%' }}>
        <Form onKeyPress={this.onKeyPress} style={{ height: '100%' }}>
          <Box className='ml-5 mt-2' style={{ height: '100%' }}>
            <Box style={{ position: 'relative', right: '11px' }}>
              <FormTitle className='ml-4 mt-3 '>
                {this.props.notification
                  ? 'Send a message to users who subscribed'
                  : 'Choose your distribution channels'}
              </FormTitle>

              <FormSubtitle className='ml-4'>
                {this.state.channelCheck.filter(Boolean).length} of {channels.length} channels are
                selected.
              </FormSubtitle>
            </Box>

            <ChannelWrapper>
              <ChannelsList>
                {channels.map((channel, index) => (
                  <ChannelBox>
                    <FormControlLabel
                      style={{ margin: 0 }}
                      control={
                        <GreenCheckbox
                          name='channel'
                          checked={this.state.channelCheck[index]}
                          onChange={(e) => this.handleChannelSelection(e, index)}
                        />
                      }
                    />
                    <ChannelNameBox>
                      <ChannelName
                        style={{ color: this.state.channelCheck[index] ? '#08A88E ' : '#797979' }}
                      >
                        {channel.name}
                      </ChannelName>

                      {!this.props.notification && channel.last_synced && (
                        <ChannelLastSynced>
                          Synced on: {new Date(channel.last_synced).toLocaleString('en-US')}
                        </ChannelLastSynced>
                      )}
                    </ChannelNameBox>
                  </ChannelBox>
                ))}
              </ChannelsList>
            </ChannelWrapper>

            {!this.props.notification && this.props.isCalc && isStaff && (
              <div
                style={{ position: 'absolute', bottom: 130, background: 'white' }}
                data-testid='staff-only'
              >
                <UniversalCheckboxContainer>
                  <FormControlLabel
                    style={{ margin: 0 }}
                    control={
                      <GreenCheckbox
                        name='isUniversal'
                        checked={this.props.isUniversal}
                        onChange={() => this.props.setIsUniversal(!this.props.isUniversal)}
                      />
                    }
                  />
                  <UniversalCheckboxLabel>Publish as Universal Calculator</UniversalCheckboxLabel>
                </UniversalCheckboxContainer>
              </div>
            )}
          </Box>
        </Form>
      </div>
    );
  }
}

FirebaseChannelForm.propTypes = {
  module: PropTypes.any.isRequired,
  isCalc: PropTypes.bool.isRequired,
  isAIKnowledgeBase: PropTypes.bool.isRequired,
  handleChannelSelection: PropTypes.func.isRequired,
  isUniversal: PropTypes.bool.isRequired,
  setIsUniversal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  teamCalculatorState: state.teamCalculatorState,
});

export default withTeam(withAuthentication(connect(mapStateToProps)(FirebaseChannelForm)));
