import { Popover, Transition } from '@headlessui/react';
import { Fragment, useMemo, useState } from 'react';
import { generatePath, Navigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { ROLES } from '../../api/team';
import { ReactComponent as Ellipsis } from '../../assets/icons/v2/item/ellipsis.svg';
import {
  ALGO_ROUTE,
  CALC_ROUTE,
  HAS_EDIT_PERMISSIONS,
  HAS_VIEW_PERMISSIONS,
  KB_ROUTE,
  MODULE_TYPES,
  Permissions,
} from '../../constants';
import { ModuleWithMirror, useModules } from '../../hooks/useModules';
import { useTeam } from '../../hooks/useTeam';
import { ConfirmModal } from '../utils/modals/ConfirmModal';
import CustomModal from '../utils/modals/CustomModal';
import ModuleTransfer from './ModuleTransfer';

interface ModuleSettingProps {
  show: boolean;
  module: ModuleWithMirror;
  closeIcon: () => void;
}

interface Modals {
  deleteModal: boolean;
  transferModal: boolean;
  mirrorCopyModal: boolean;
  duplicateModal: boolean;
  reverify: boolean;
  unverify: boolean;
}

export const ModuleSetting = ({ show, module, closeIcon }: ModuleSettingProps) => {
  const [showModal, setShowModal] = useState<Modals>({
    deleteModal: false,
    transferModal: false,
    mirrorCopyModal: false,
    duplicateModal: false,
    reverify: false,
    unverify: false,
  });

  const [redirectLink, setRedirectLink] = useState<string>('');
  const { team } = useTeam();

  const isTeamAdmin = useMemo(() => team.current_teammate?.role === ROLES.Admin, [team]);
  const isMirror = useMemo(() => module?.type === MODULE_TYPES.MIRROR, [module]);
  const hasEditPermission = useMemo(
    () =>
      !isMirror &&
      (isTeamAdmin ||
        (module?.current_user_role && HAS_EDIT_PERMISSIONS.includes(module.current_user_role)) ||
        (!module?.current_user_role &&
          team.current_teammate?.role &&
          module.permission_type &&
          HAS_EDIT_PERMISSIONS.includes(module.permission_type))),
    [team, module, isMirror, isTeamAdmin]
  );

  const hasViewPermissions = useMemo(
    () =>
      isMirror ||
      isTeamAdmin ||
      (module?.current_user_role && HAS_VIEW_PERMISSIONS.includes(module?.current_user_role)) ||
      (!module?.current_user_role &&
        team.current_teammate?.role &&
        module.permission_type &&
        HAS_VIEW_PERMISSIONS.includes(module.permission_type)),
    [isMirror, isTeamAdmin, module, team]
  );
  const hasOwnerPermission = useMemo(
    () => isTeamAdmin || module?.current_user_role === Permissions.OWNER,
    [isTeamAdmin, module]
  );

  const editModule = () => {
    if (module?.type === MODULE_TYPES.KNOWLEDGE_BASE) {
      setRedirectLink(generatePath(KB_ROUTE, { moduleId: module.id }));
    } else if (module?.type === MODULE_TYPES.CALCULATOR) {
      setRedirectLink(generatePath(CALC_ROUTE, { moduleId: module.id }));
    } else {
      setRedirectLink(generatePath(ALGO_ROUTE, { moduleId: module.id as any })); // TODO temporary fix
    }
  };

  const toggleDeleteModal = () =>
    setShowModal({
      ...showModal,
      deleteModal: !showModal.deleteModal,
    });

  const toggleDuplicateModal = () => {
    //his.handleMenuClose();
    setShowModal({
      ...showModal,
      duplicateModal: !showModal.duplicateModal,
    });
  };

  const openTransferModal = () =>
    setShowModal({
      ...showModal,
      transferModal: !showModal.transferModal,
    });

  const toggleModal = (modalName: string) =>
    setShowModal({
      ...showModal,
      [modalName]: !showModal[modalName],
    });

  const { deleteModule, duplicateModule, verifyModule, unverifyModule, copyModule } = useModules();
  const onDelete = () => {
    toggleDeleteModal();
    deleteModule(module);
  };

  const onVerify = () => {
    showModal.reverify && toggleModal('reverify');
    verifyModule(module);
  };

  const onUnverify = () => {
    toggleModal('unverify');
    unverifyModule(module);
  };

  const onDuplicate = () => {
    toggleDuplicateModal();
    duplicateModule(module);
  };

  const onCopy = () => {
    toggleModal('mirrorCopyModal');

    copyModule({ module, teamId: team.id });
  };

  if (!!redirectLink) {
    return <Navigate to={redirectLink} />;
  }

  return (
    <>
      <Popover className='relative' onClick={(e) => e.stopPropagation()}>
        {({ close }) => (
          <>
            <Popover.Button
              onClick={closeIcon}
              className={twMerge(
                !show && 'hidden',
                'absolute right-0 -translate-y-1/2 justify-center rounded-sm border border-gray-300 bg-white p-[8px] font-semibold shadow-[rgba(0,0,0,0.06)_0px_2px_4px] transition-all'
              )}
            >
              <Ellipsis />
            </Popover.Button>
            <Popover.Overlay className='fixed inset-0 z-10 bg-transparent' />
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel
                className='absolute top-0 z-10 -translate-x-full -translate-y-5'
                onClick={close}
              >
                <ul className='overflow-hidden whitespace-nowrap rounded-lg bg-white py-[4px] text-[15px] text-body-1 shadow-lg ring-1 ring-black/5'>
                  {hasEditPermission && (
                    <li className='px-[12px] py-[10px] hover:bg-primary-200' onClick={editModule}>
                      Edit
                    </li>
                  )}
                  {hasOwnerPermission && (
                    <li
                      className='px-[12px] py-[10px] hover:bg-primary-200'
                      onClick={toggleDeleteModal}
                    >
                      Delete
                    </li>
                  )}
                  {!isMirror && hasViewPermissions && (
                    <li
                      className='px-[12px] py-[10px] hover:bg-primary-200'
                      onClick={toggleDuplicateModal}
                    >
                      Duplicate
                    </li>
                  )}
                  {!isMirror && hasOwnerPermission && (
                    <li
                      className='px-[12px] py-[10px] hover:bg-primary-200'
                      onClick={() => toggleModal('mirrorCopyModal')}
                    >
                      Create Mirror Copy
                    </li>
                  )}
                  {hasOwnerPermission && (
                    <li
                      className='px-[12px] py-[10px] hover:bg-primary-200'
                      onClick={openTransferModal}
                    >
                      Transfer Team
                    </li>
                  )}
                  {!isMirror && hasViewPermissions && module.last_verified && (
                    <li
                      className='px-[12px] py-[10px] hover:bg-primary-200'
                      onClick={() => toggleModal('reverify')}
                    >
                      Reverify Module
                    </li>
                  )}
                  {!isMirror && hasViewPermissions && module.last_verified && (
                    <li
                      className='px-[12px] py-[10px] hover:bg-primary-200'
                      onClick={() => toggleModal('unverify')}
                    >
                      Unverify Module
                    </li>
                  )}
                </ul>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <CustomModal
        width={500}
        contentClass='center-modal'
        modalHeader='Transfer ALGO'
        modal={showModal.transferModal}
        toggleModal={openTransferModal}
      >
        <ModuleTransfer module={module} />
      </CustomModal>
      <ConfirmModal
        preset='delete'
        content={
          isMirror
            ? `Are you sure you want to delete "${module?.title}" mirror?`
            : module?.type === MODULE_TYPES.CALCULATOR
              ? `Are you sure you want to delete "${module?.title}" calculator?`
              : module?.type === MODULE_TYPES.KNOWLEDGE_BASE
                ? `Are you sure you want to delete "${module?.title}" Knowledge Base? `
                : `Are you sure you want to delete "${module?.title}" ALGO?`
        }
        open={showModal.deleteModal}
        toggleModal={toggleDeleteModal}
        performAction={onDelete}
      />
      <ConfirmModal
        preset='duplicate'
        content={
          module?.type === MODULE_TYPES.CALCULATOR
            ? 'Are you sure you want to duplicate this calculator?'
            : module?.type === MODULE_TYPES.KNOWLEDGE_BASE
              ? 'Are you sure you want to duplicate this Knowledge Base?'
              : 'Are you sure you want to duplicate this ALGO?'
        }
        open={showModal.duplicateModal}
        toggleModal={toggleDuplicateModal}
        performAction={onDuplicate}
      />
      <ConfirmModal
        content='Are you sure you want to create a mirror copy?'
        confirmText='Create Mirror'
        open={showModal.mirrorCopyModal}
        toggleModal={() => toggleModal('mirrorCopyModal')}
        performAction={onCopy}
      />
      <ConfirmModal
        content={
          showModal.unverify
            ? 'Are you sure you want to unverify this module?'
            : 'Are you sure you want to reverify this module?'
        }
        confirmText={showModal.unverify ? 'Unverify Module' : 'Reverify Module'}
        open={showModal.unverify || showModal.reverify}
        toggleModal={() => (showModal.unverify ? toggleModal('unverify') : toggleModal('reverify'))}
        performAction={showModal.unverify ? onUnverify : onVerify}
      />
    </>
  );
};
