import { CheckCircleIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useMutation } from '@tanstack/react-query';
import { moduleAPI } from 'api/module';
import { AlgoIcon } from 'assets/icons/algoIcon';
import AVOMDIconGreen from 'assets/icons/avomdIconGreen';
import { CalculatorIcon } from 'assets/icons/calculatorIcon';
import { KnowledgeBaseIcon } from 'assets/icons/knowledgeBaseIcon';
import { SimulateIcon } from 'assets/icons/simulateIcon';
import clsx from 'clsx';
import { simulateInWebapp } from 'components/module-detail/ConversationBoardUtils';
import Button from 'components/utils/Button';
import SyncModal from 'components/utils/firebase/sync-modal';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { CustomToast } from 'components/utils/toast-message';
import { queryClient } from 'queryClient';
import { HTMLAttributes, useContext, useState } from 'react';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { twJoin } from 'tailwind-merge';
import { ROLES } from '../../api/team';
import {
  ALGO_ROUTE,
  CALC_ROUTE,
  HAS_PUBLISH_PERMISSIONS,
  HAS_VIEW_PERMISSIONS,
  HOME_ROUTE,
  KB_ROUTE,
  MODULE_TYPES,
  Permissions,
} from '../../constants';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useTeam } from '../../hooks/useTeam';
import { HEADER_Z_INDEX } from '../../v2/utils/constant';
import ShareLink from './partials/share-link';
interface ModuleHeaderProps extends HTMLAttributes<HTMLDivElement> {}

const getEditPageUrl = (moduleType, moduleId) => {
  if (!moduleType || !moduleId) return null;
  switch (moduleType) {
    case MODULE_TYPES.ALGO:
      return generatePath(ALGO_ROUTE, { moduleId });
    case MODULE_TYPES.CALCULATOR:
      return generatePath(CALC_ROUTE, { moduleId });
    default:
      return generatePath(KB_ROUTE, { moduleId });
  }
};

const ModuleIcon = ({ moduleType }) => {
  switch (moduleType) {
    case MODULE_TYPES.ALGO:
      return <AlgoIcon />;
    case MODULE_TYPES.CALCULATOR:
      return <CalculatorIcon />;
    default:
      return <KnowledgeBaseIcon />;
  }
};

export const ModuleHeader = ({ className }: ModuleHeaderProps) => {
  const [syncModal, setSyncModal] = useState(false);
  const navigate = useNavigate();
  const { module } = useContext(ModuleContext);
  const { team } = useTeam();
  const teamRole = team.current_teammate?.role;
  const isTeamAdmin = teamRole === ROLES.Admin;
  const isTeamMember = teamRole === ROLES.Member;
  const { authentication } = useAuthentication();
  const isStaff = !!authentication.user?.is_staff;
  const isTrialUser = authentication.user?.user_type === 'trial';

  const permissionType = module?.current_user_role;
  const hasAllPermissions =
    isTeamAdmin ||
    HAS_VIEW_PERMISSIONS.includes(permissionType as Permissions) ||
    (!permissionType &&
      !!teamRole &&
      HAS_VIEW_PERMISSIONS.includes(module?.permission_type as Permissions));

  const hasPublishPermission =
    (!isTrialUser && (isTeamAdmin || isTeamMember)) ||
    HAS_PUBLISH_PERMISSIONS.includes(permissionType as Permissions) ||
    (!permissionType &&
      !!teamRole &&
      HAS_PUBLISH_PERMISSIONS.includes(module?.permission_type as Permissions));

  const hasOnlyViewPermission: boolean =
    module?.isMirror ||
    (!isTeamAdmin &&
      (permissionType === Permissions.CAN_VIEW ||
        (!permissionType && !!teamRole && module?.permission_type === Permissions.CAN_VIEW)));

  const editPageUrl = getEditPageUrl(module?.type, module?.id);

  const { mutate: verify } = useMutation({
    mutationFn: moduleAPI.verifyModule,
    onSuccess: () => {
      toast.success(CustomToast, { data: `${module?.type} verified successfully` });
    },
  });
  return (
    <>
      <div
        className={twJoin(
          'fixed left-0 top-0 flex w-full flex-wrap bg-white',
          HEADER_Z_INDEX,
          //Need to remove className after refactoring
          className
        )}
      >
        <div className='flex w-full items-center border-b border-stone-300 px-[12px] py-[3px] shadow-[3px]'>
          <div className='flex grow space-x-10'>
            <div
              className='cursor-pointer fill-gray-600 stroke-2 text-gray-600'
              onClick={() => navigate(HOME_ROUTE)}
            >
              <AVOMDIconGreen />
            </div>
            <div
              className={clsx('flex items-center space-x-2', {
                'cursor-pointer': !hasOnlyViewPermission,
              })}
              onClick={() => !hasOnlyViewPermission && editPageUrl && navigate(editPageUrl)}
            >
              <div>
                <ModuleIcon moduleType={module?.type} />
              </div>
              <div className='ml-3 overflow-hidden text-heading-4 text-gray-900'>
                <p className='mb-0 line-clamp-2'>{module?.title}</p>
              </div>
              <ChevronRightIcon className='h-6 w-6' />
            </div>
          </div>
          <div className='flex space-x-2'>
            {hasAllPermissions &&
              module &&
              [MODULE_TYPES.ALGO, MODULE_TYPES.CALCULATOR].includes(module.type) && (
                <Button.Outline
                  onClick={() => simulateInWebapp(module.id, module.type)}
                  className='!rounded-full px-[12px] py-[6px]'
                >
                  <div className='flex items-center space-x-1'>
                    <SimulateIcon />
                    <span className='whitespace-nowrap'>Simulate</span>
                  </div>
                </Button.Outline>
              )}
            {hasAllPermissions &&
              module &&
              [MODULE_TYPES.ALGO, MODULE_TYPES.CALCULATOR].includes(module.type) && (
                <ShareLink
                  calculatorId={
                    module.type === MODULE_TYPES.CALCULATOR ? `${module?.id}` : undefined
                  }
                  openSyncModal={() => setSyncModal(true)}
                />
              )}
            {hasAllPermissions && !isTrialUser && module && (
              <Button.Outline
                onClick={() => verify(module)}
                className='!rounded-full px-[12px] py-[6px]'
              >
                <div className='flex items-center space-x-1'>
                  <CheckCircleIcon className='h-6 w-6' />
                  <span className='whitespace-nowrap'>Verify</span>
                </div>
              </Button.Outline>
            )}
            {hasPublishPermission && (
              <Button onClick={() => setSyncModal(true)} className='px-[24px] py-[6px] font-bold'>
                Publish
              </Button>
            )}
          </div>
        </div>
      </div>
      {module && (
        <SyncModal
          module={module}
          modal={syncModal}
          toggleModal={() => setSyncModal(false)}
          isAIKnowledgeBase={module.type === MODULE_TYPES.KNOWLEDGE_BASE}
          syncStatus={() => {
            // TODO After converting the sync modal to a functional component, this prop will no longer be needed.
            queryClient.invalidateQueries({ queryKey: [module.type, module.id] });
          }}
        />
      )}
    </>
  );
};
