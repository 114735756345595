import {
  AntdConfig,
  Builder,
  BuilderProps,
  Config,
  ImmutableTree,
  JsonGroup,
  JsonTree,
  Query,
  Utils,
} from '@react-awesome-query-builder/antd';
import axios from 'axios';
import { CALCULATOR_API_URL, MODULE_API_URL, NUMERICS_API_URL } from 'constants/api-endpoints';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GENDER, GENDER_VALUES } from '../../../constants';

import { useQuery } from '@tanstack/react-query';
import 'css/querybuilder.css';
import { useAuthentication } from '../../../hooks/useAuthentication';
import { commonSubfieldsAdvancedPhenotyping } from './QueryBuilderConfig';
import { useVariables } from '../../../hooks/module/useVariables';
import { useTriggers } from '../../../hooks/module/resources/useTriggers';

const defaultConfig: Config = {
  ...AntdConfig,
  fields: {
    variables: { label: 'Variables', type: '!struct', subfields: {} },
    triggers: { label: 'Triggers', type: '!struct', subfields: {} },
    numerics: { label: 'Numerics', type: '!struct', subfields: {} },
    customNumerics: { label: 'Custom Numerics', type: '!struct', subfields: {} },
    formulae: { label: 'Formulae', type: '!struct', subfields: {} },
    fhir: { label: 'FHIR', type: '!struct', subfields: {} },
  },
  operators: {
    ...AntdConfig.operators,
    equal: {
      ...AntdConfig.operators.equal,
      label: '=',
      formatOp: (field, _, value) => `${field} == ${value}`,
    },
    not_equal: {
      ...AntdConfig.operators.not_equal,
      label: '≠',
      formatOp: (field, _, value) => `${field} != ${value}`,
    },
    less: { ...AntdConfig.operators.less, label: '<' },
    less_or_equal: { ...AntdConfig.operators.less_or_equal, label: '≤' },
    greater: { ...AntdConfig.operators.greater, label: '>' },
    greater_or_equal: { ...AntdConfig.operators.greater_or_equal, label: '≥' },
    between: { ...AntdConfig.operators.between, textSeparators: ['from', 'to'] },
    not_between: { ...AntdConfig.operators.not_between, textSeparators: ['from', 'to'] },
    is_empty: { ...AntdConfig.operators.is_empty, jsonLogic: '==' }, // Legacy compatibility
    is_not_empty: { ...AntdConfig.operators.is_not_empty, jsonLogic: '!=' }, // Legacy compatibility
    is_null: {
      ...AntdConfig.operators.is_null,
      label: 'is not available',
      formatOp: (field) => `${field} == null`,
    },
    is_not_null: {
      ...AntdConfig.operators.is_not_null,
      label: 'is available',
      formatOp: (field) => `${field} != null`,
    },
    select_equals: { ...AntdConfig.operators.select_equals, label: '=' },
    select_not_equals: { ...AntdConfig.operators.select_not_equals, label: '≠' },
    select_any: {
      ...AntdConfig.operators.is_not_null,
      label: 'Is any',
      formatOp: (field) => `${field} != null`,
    },
    select_not_any: {
      ...AntdConfig.operators.is_null,
      label: 'Not any',
      formatOp: (field) => `${field} == null`,
    },
    days_within_the_last: { label: 'Within the last (days)', jsonLogic: 'days_within_the_last' },
    days_for_at_least: { label: 'For at least (days)', jsonLogic: 'days_for_at_least' },
    items_latest: { label: 'Latest (items)', jsonLogic: (_, op, value) => ({ [op]: value }) },
  },
  types: {
    ...AntdConfig.types,
    number: { ...AntdConfig.types.number, valueSources: ['value', 'field'] },
    duration: {
      defaultOperator: 'days_within_the_last',
      widgets: { number: { operators: ['days_within_the_last', 'days_for_at_least'] } },
    },
    dose: { ...AntdConfig.types.number },
    value: { ...AntdConfig.types.number },
    filter: {
      defaultOperator: 'items_latest',
      widgets: { number: { operators: ['items_latest'] } },
    },
  },
  settings: {
    ...AntdConfig.settings,
    removeIncompleteRulesOnLoad: false,
    removeEmptyRulesOnLoad: false,
  },
};

const emptyRule: JsonGroup = {
  id: Utils.uuid(),
  type: 'group',
  children1: [
    {
      type: 'rule',
      id: Utils.uuid(),
      properties: { field: null, fieldSrc: 'field', operator: null, value: [], valueSrc: [] },
    },
  ],
};

export interface QueryBuilderProps {
  type: any;
  moduleId?: string;
  calculatorId?: string;
  queryValue?: JsonGroup;
  setQueryValue: (jsonTree: JsonTree) => void;
  setCondition: (condition: string) => void;
  setConditionLogic: (conditionLogic: any) => void;
  setChoiceMapping: (choiceMapping: any) => void;
}

export function QueryBuilder({
  type,
  moduleId,
  calculatorId,
  queryValue,
  setQueryValue,
  setCondition,
  setConditionLogic,
  setChoiceMapping,
}: QueryBuilderProps) {
  const { authentication } = useAuthentication();
  const { data: variables } = useVariables();
  const customNumericState = useSelector((state: any) => state.customNumericState);
  const { data: triggerState } = useTriggers();
  const formulaState = useSelector((state: any) => state.formulaState);

  const [tree, setTree] = useState(Utils.loadTree(emptyRule));
  const [config, setConfig] = useState(defaultConfig);
  const [isLoading, setIsloading] = useState(true);

  const { data: numbers } = useQuery({
    queryKey: [NUMERICS_API_URL],
    queryFn: ({ queryKey }) => axios.get(queryKey[0]).then(({ data }) => data),
  });

  useEffect(() => {
    if (!numbers) return;

    function getListValues(choices, variableId) {
      const values: any[] = [];
      const choiceMapping = {};
      choiceMapping[variableId] = {};

      for (const choice of choices) {
        const title = choice.name;
        choiceMapping[variableId][title] = title;
        values.push({ title, value: title === 'Any' ? null : choice.unique_code });
      }

      setChoiceMapping(choiceMapping);
      return values;
    }

    async function initConfig() {
      const { data: numerics } = await axios.get(
        type === 'calculator'
          ? CALCULATOR_API_URL + calculatorId + '/numerics'
          : MODULE_API_URL + moduleId + '/numerics'
      );
      const customNumerics = customNumericState.customNumerics;
      const triggers = [...triggerState.triggers, ...triggerState.candidate_triggers];
      const formulae = formulaState.formulas;

      const _config = _.cloneDeep(config);

      for (const variable of variables) {
        if (variable.option_type == 'number') {
          _config.fields.variables['subfields'][variable.unique_code] = {
            label: variable.name,
            type: 'number',
            fieldSettings: { step: 1 },
          };
        }
        // No need for now to add text_input's text variable in trigger builder
        // (According to ticket description, https://avomd.atlassian.net/browse/AV-1836)
        // Make sure to inform backend team when uncomment this code. If text_input simple variable
        // and it's submit variable is added in same condition then convert_condition function for
        // Firebase will cause issue.
        // else if (variable.option_type === 'text') {
        //   config.fields.variables['subfields'][variable.unique_code] = {
        //     label: variable.name,
        //     type: 'text',
        //   }
        // }
        else if (['boolean', 'choices'].includes(variable.option_type)) {
          // Group card uses {variable_name}/submit, whereas other cards use {variable_name}/submitted
          const operators = variable.name.includes('/submit')
            ? ['select_equals']
            : ['select_equals', 'select_not_equals', 'select_any', 'select_not_any'];
          _config.fields.variables['subfields'][variable.unique_code] = {
            label: variable.name,
            type: 'select',
            operators,
            valueSources: ['value'],
            fieldSettings: {
              listValues: getListValues(variable.choices, variable.id),
            },
          };
        }
      }

      for (const numeric of numerics) {
        if (numeric.code === GENDER.SEX) {
          _config.fields.numerics['subfields'][numeric.unique_code] = {
            label: numeric.title,
            type: 'select',
            operators: ['select_equals', 'select_not_equals', 'select_any', 'select_not_any'],
            valueSources: ['value'],
            fieldSettings: {
              listValues: [
                {
                  title: GENDER.MALE,
                  value: GENDER_VALUES[GENDER.MALE],
                },
                {
                  title: GENDER.FEMALE,
                  value: GENDER_VALUES[GENDER.FEMALE],
                },
              ],
            },
          };
        } else {
          _config.fields.numerics['subfields'][numeric.unique_code] = {
            label: numeric.title,
            type: 'number',
            fieldSettings: { step: 0.00001 },
          };
        }
      }

      for (const customNumeric of customNumerics) {
        _config.fields.customNumerics['subfields'][customNumeric.unique_code] = {
          label: customNumeric.name,
          type: 'number',
          fieldSettings: { step: 0.00001 },
        };
      }

      for (const trigger of triggers) {
        if (trigger.title !== 'Always On' && trigger.candidate_trigger === false) {
          _config.fields.triggers['subfields'][trigger.unique_code] = {
            label: trigger.title,
            type: 'select',
            operators: ['select_equals', 'select_not_equals', 'select_any', 'select_not_any'],
            valueSources: ['value'],
            fieldSettings: {
              listValues: [
                { value: '1', title: 'True' },
                { value: '0', title: 'False' },
                { value: 'null', title: 'Determined' },
                { value: 'Not null', title: 'Undetermined' },
              ],
            },
          };
        }
      }

      for (const formula of formulae) {
        _config.fields.formulae['subfields'][formula.unique_code] = {
          label: formula.name,
          type: 'number',
          fieldSettings: { step: 0.00001 },
        };
      }

      if (!!authentication.user?.is_staff) {
        _config.fields.fhir['subfields'].labs = {
          label: 'Labs',
          type: '!group',
          mode: 'some',
          subfields: commonSubfieldsAdvancedPhenotyping('Lab', numbers ?? []),
        };

        _config.fields.fhir['subfields'].vitals = {
          label: 'Vitals',
          type: '!group',
          mode: 'some',
          subfields: commonSubfieldsAdvancedPhenotyping('Vital', numbers ?? []),
        };
      }
      setConfig(_config);
      setIsloading(false);
    }
    initConfig();
  }, [numbers]);

  useEffect(() => {
    if (queryValue) {
      try {
        const tree = Utils.loadTree(queryValue);
        if (tree) setTree(tree);
      } catch (error) {
        console.error('Error loading tree:', error);
      }
    }
  }, [queryValue]);

  const onChange = useCallback((immutableTree: ImmutableTree, config: Config) => {
    setTree(immutableTree);
    setConfig(config);
    setQueryValue(Utils.getTree(immutableTree));
    setCondition(
      Utils.queryString(immutableTree, config)
        ?.replace(/"/g, '')
        .replace(/\\/g, '')
        .replace(/NOT/g, '!') ?? ''
    );
    setConditionLogic(Utils.jsonLogicFormat(immutableTree, config));
  }, []);

  const renderBuilder = useCallback((props: BuilderProps) => {
    return (
      <div className='query-builder-container'>
        <div className='query-builder'>
          <Builder {...props} />
        </div>
      </div>
    );
  }, []);

  if (isLoading) return null;
  return <Query {...config} value={tree} onChange={onChange} renderBuilder={renderBuilder} />;
}
