import { UseMutateFunction, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { CopyModuleRequest, moduleAPI } from '../api/module';
import { CustomToast } from '../components/utils/toast-message';
import { MODULE_TYPES, MODULE_TYPES_MAP, Permissions } from '../constants';
import { useTeam } from './useTeam';

export interface Mirror {
  id: number;
  created: string;
  last_updated: string;
  is_in_current_team: boolean;
  type: string;
  module: Module;
}

interface PermittedUser {
  id: number;
  name: string;
  profile_picture: string;
}

// TODO: there's also Module interface in components/utils/module
export interface Module {
  id: number;
  title: string;
  code: string;
  status: string;
  last_synced: string | null;
  last_updated: string;
  type: MODULE_TYPES;
  isMirror?: boolean;
  owner?: PermittedUser;
  created: string;
  team: ModuleTeam;
  current_user_role?: Permissions | null;
  last_updated_by?: PermittedUser;
  permission_type?: Permissions;
  last_verified?: string | null;
  reviewed_by?: ReviewedBy | null;
  is_universal: boolean;
  is_in_current_team?: boolean;
  review_expire_at: Date | null;
  use_new_editor: boolean;
  /** Field to verify if draftJS has been converted to tiptap. */
  // TODO: This field should be removed once all modules have converted to tiptap.
  has_converted: boolean;
}

interface ModuleTeam {
  id: number;
  name: string;
}

interface ReviewedBy {
  id: number;
  email: string;
  name: string;
}

export interface ModuleWithMirror extends Module {
  ownerName: string;
  mirrorOf?: string;
  mirroredModuleId?: number;
}

interface Modules {
  modules: Module[];
  mirrors: Mirror[];
}

interface UseModules {
  modules: ModuleWithMirror[];
  isLoading: boolean;
  teamModules: ModuleWithMirror[];
  globalModules: ModuleWithMirror[];
  deleteModule: UseMutateFunction<AxiosResponse<any, any>, unknown, ModuleWithMirror, unknown>;
  duplicateModule: UseMutateFunction<AxiosResponse<any, any>, unknown, ModuleWithMirror, unknown>;
  transferModule: UseMutateFunction<AxiosResponse<any, any>, unknown, CopyModuleRequest, unknown>;
  verifyModule: UseMutateFunction<AxiosResponse<any, any>, unknown, Module, unknown>;
  unverifyModule: UseMutateFunction<AxiosResponse<any, any>, unknown, ModuleWithMirror, unknown>;
  copyModule: UseMutateFunction<AxiosResponse<any, any>, unknown, CopyModuleRequest, unknown>;
}

export const READ_ONLY_STATUS = 'Read only';

export const useModules = (): UseModules => {
  const { team } = useTeam();
  const teamId = useMemo(() => team.id, [team]);

  const { data, isLoading, refetch } = useQuery<Modules>({
    queryKey: ['moduleWithMirror', teamId],
    queryFn: moduleAPI.getModules,
    initialDataUpdatedAt: 0,
    initialData: { modules: [], mirrors: [] },
    enabled: !!teamId,
  });

  const modules = useMemo<ModuleWithMirror[]>(() => {
    const mirrorList = data.mirrors.reduce(
      (prev: ModuleWithMirror[], mirror) => [
        ...prev,
        {
          ...mirror.module,
          id: mirror.id,
          ownerName: mirror.module?.owner?.name ?? '',
          is_in_current_team: mirror.is_in_current_team,
          mirroredModuleId: mirror.module?.id,
          mirrorOf: mirror.module?.type,
          status: READ_ONLY_STATUS,
          type: MODULE_TYPES.MIRROR,
          created: mirror.created,
        },
      ],
      []
    );
    const moduleList = data.modules.reduce(
      (prev: ModuleWithMirror[], module) => [
        ...prev,
        {
          ...module,
          ownerName: module.owner?.name ?? '',
        },
      ],
      []
    );
    return [...moduleList, ...mirrorList];
  }, [data]);

  const { mutate: deleteModule } = useMutation({
    mutationFn: moduleAPI.deleteModule,
    onSuccess: (data, variables) => {
      refetch();
      toast.success(CustomToast, {
        data: `${MODULE_TYPES_MAP[variables.type]} deleted successfully!`,
      });
    },
    onError: () => {
      toast.error(CustomToast, { data: 'Deletion unsuccessful!' });
    },
  });
  const { mutate: duplicateModule } = useMutation({
    mutationFn: moduleAPI.duplicateModule,
    onSuccess: (data, variables) => {
      refetch();
      toast.success(CustomToast, { data: `${MODULE_TYPES_MAP[variables.type]} Duplicated!` });
    },
    onError: () => {
      toast.error(CustomToast, { data: 'Duplication unsuccessful!' });
    },
  });
  const { mutate: transferModule } = useMutation({
    mutationFn: moduleAPI.transferModule,
    onSuccess: () => {
      refetch();
      toast.success(CustomToast, { data: 'Team changed successfully!' });
    },
    onError: () => {
      toast.error(CustomToast, { data: 'Change unsuccessful!' });
    },
  });
  const { mutate: verifyModule } = useMutation({
    mutationFn: moduleAPI.verifyModule,
    onSuccess: (data, variables) => {
      refetch();
      toast.success(CustomToast, {
        data: `${MODULE_TYPES_MAP[variables.type]} verified successfully!`,
      });
    },
    onError: () => {
      toast.error(CustomToast, { data: 'Verification unsuccessful!' });
    },
  });
  const { mutate: unverifyModule } = useMutation({
    mutationFn: moduleAPI.unverifyModule,
    onSuccess: (data, variables) => {
      refetch();
      toast.success(CustomToast, {
        data: `${MODULE_TYPES_MAP[variables.type]} unverified successfully!`,
      });
    },
    onError: () => {
      toast.error(CustomToast, { data: 'Cannot unverify ALGO!' });
    },
  });
  const { mutate: copyModule } = useMutation({
    mutationFn: moduleAPI.copyModule,
    onSuccess: () => {
      refetch();
      toast.success(CustomToast, { data: 'Mirror created successfully!' });
    },
    onError: () => {
      toast.error(CustomToast, { data: 'Copy unsuccessful!' });
    },
  });

  return {
    modules,
    isLoading: isLoading,
    teamModules: modules.filter((module) => module.is_in_current_team),
    globalModules: modules.filter((module) => !module.is_in_current_team && module.is_universal),
    deleteModule,
    duplicateModule,
    transferModule,
    verifyModule,
    unverifyModule,
    copyModule,
  };
};
