import { useQuery } from '@tanstack/react-query';
import { ComponentType, useContext } from 'react';
import { resourcesAPI } from '../../api/resources';
import { ModuleContext } from '../../components/utils/module/ModuleContext';
import { VariableTypes } from '../../components/utils/tiptap/tiptapInterfaces';

interface Choice {
  name: string;
  unique_code: string;
}

export interface Variable {
  choices: Choice[] | null;
  id: number;
  name: string;
  option_type: 'choices' | 'text' | 'boolean' | 'number';
  type?: VariableTypes;
  unique_code: string;
}

export function useVariables() {
  const { module } = useContext(ModuleContext);
  return useQuery({
    queryKey: [module?.type, module?.id, 'variables'],
    queryFn: resourcesAPI.getVariables,
    initialData: [],
    staleTime: Infinity,
    initialDataUpdatedAt: 0,
  });
}

export function withVariables<P extends object>(WrappedComponent: ComponentType<P>) {
  return (props: P) => {
    const { data: variables } = useVariables();
    return <WrappedComponent variables={variables} {...props} />;
  };
}
