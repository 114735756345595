import { Box } from '@material-ui/core';
import { getNoteGenerator } from 'actions/resources/getNoteGenerator';
import { getInfoBoxes } from 'actions/resources/infoBoxes';
import { getRefManagers } from 'actions/resources/refManager';
import getUniqueCode from 'actions/unique-code/getUniqueCodeAction';
import axios from 'axios';
import InputField from 'components/utils/form-input/field';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { Header } from 'components/utils/panels/Header';
import { CancelButton, CreateButton } from 'components/utils/styled-components/FormStyle';
import { CustomToast } from 'components/utils/toast-message';
import { CANCEL_BUTTON, SAVE_BUTTON } from 'constants/variables';
import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'reactstrap';
import { getRepresentationPhrase, isValidURL } from 'utils/utilityFunctions';
import { REFERENCE_MANAGER_API_URL } from '../../../constants';

interface refMangFormState {
  id: string;
  source: any;
  url: any;
  shortenedTitleSync: boolean;
  shortened_source: any;
  module: any;

  isShortenedSourceEnabled: boolean;

  moduleId: string;
}

interface refMangFormProps {
  type: any;
  addItem?: (item: any) => void;
  toggleModal: () => void;
  savePosition: () => void;
  isFullHeight: boolean;
  formState: any;
  refManagerId: any;
  getRefManagers: (type: string, mirrorId, moduleId) => void;
  populateSuggestions: () => void;
  getUniqueCode: (calculatorId?: string, type?: string) => void;
  getInfoBoxes: (type: string, mirrorId, moduleId) => void;
  getNoteGenerator: (type: any, mirrorId, moduleId) => void;
  modal?: boolean;
  closeUiElementPane?: () => void;

  //for withRouter
  params: any;
}
// The Wrapper functional component
const RefManagerFormWrapper = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  return (
    <RefManagerForm
      {...props} // Pass down any props from Redux connect
      navigate={navigate}
      params={params}
      location={location}
    />
  );
};
class RefManagerForm extends Component<refMangFormProps, refMangFormState> {
  constructor(props: any) {
    super(props);

    // state element name should be same as the name of input elements in form
    this.state = {
      id: '',
      source: '',
      url: '',
      shortenedTitleSync: true,
      shortened_source: '',
      module: '',

      isShortenedSourceEnabled: false,

      moduleId: this.props.params.moduleId,
    };
  }

  helpInfoData = getHelpInfoData('Reference');

  componentDidMount() {
    const { refManagerId } = this.props;

    if (refManagerId) {
      axios.get(REFERENCE_MANAGER_API_URL + refManagerId + '/').then((res) => {
        this.setState({
          id: res.data.id,
          source: res.data.source ? res.data.source : '',
          url: res.data.url ? res.data.url : '',
          shortened_source: res.data.shortened_source ? res.data.shortened_source : '',
          module: res.data.module,
          isShortenedSourceEnabled: res.data.is_shortened_source_enabled,
        });
      });
    }
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { shortenedTitleSync } = this.state;
    if (e.target.name === 'source') {
      if (shortenedTitleSync === true) {
        this.setState({
          shortened_source: getRepresentationPhrase(e.target.value, 500, false, 10),
        });
      }
    } else if (e.target.name === 'shortened_source') {
      this.setState({ shortenedTitleSync: false });
    }

    this.setState({
      [e.target.name]: e.target.value,
    } as Pick<refMangFormState, any>);
  };

  getUrl = () => {
    const { url } = this.state;
    if (isValidURL(url)) {
      if (url.indexOf('https') === -1 && url.indexOf('http') === -1) {
        this.setState({
          url: 'https://' + url,
        });
        return 'https://' + url;
      } else {
        return url;
      }
    } else {
      return null;
    }
  };

  getPayload = () => {
    const { type } = this.props;
    const { source, shortened_source, isShortenedSourceEnabled, moduleId } = this.state;
    let payload = {
      source: source,
      url: this.getUrl(),
      shortened_source: shortened_source,
      is_shortened_source_enabled: isShortenedSourceEnabled,
    };
    if (type === 'calculator') {
      payload['calculator'] = moduleId;
    } else {
      payload['module'] = moduleId;
    }

    return payload;
  };

  createReference = async () => {
    const { toggleModal, savePosition } = this.props;
    // if url is invalid, show alert
    if (!this.getUrl()) {
      toast.error(CustomToast, { data: 'Invalid URL' });
      return;
    }

    await savePosition();

    axios.post(REFERENCE_MANAGER_API_URL, this.getPayload()).then(async (res) => {
      const { type, addItem, populateSuggestions, getRefManagers, getUniqueCode } = this.props;

      const { moduleId } = this.state;

      addItem?.({ ...res.data, type: 'reference' });
      await getRefManagers(type, null, moduleId);

      // update mention suggestion if user creates a reference from formula/ answer text card form
      if (populateSuggestions) populateSuggestions();

      // reset unique codes mapping to get latest result
      if (type === 'calculator') {
        await getUniqueCode(moduleId, type);
      } else {
        await getUniqueCode(moduleId, type);
      }

      toggleModal();
    });
  };

  editReference = async () => {
    const {
      addItem,
      toggleModal,
      savePosition,
      getUniqueCode,
      getInfoBoxes,
      getNoteGenerator,
      getRefManagers,
      type,
    } = this.props;
    const { id, moduleId } = this.state;
    // if url is invalid, show alert
    if (!this.getUrl()) {
      toast.error(CustomToast, { data: 'Invalid URL' });
      return;
    }

    await savePosition();

    axios.put(REFERENCE_MANAGER_API_URL + id + '/', this.getPayload()).then(async (res) => {
      addItem?.({ ...res.data, type: 'reference' });
      getRefManagers(type, null, moduleId);

      // refresh infoboxes list to get update the name of reference used in infoboxes
      getInfoBoxes(type, null, moduleId);
      getNoteGenerator(type, null, moduleId);

      // reset unique codes mapping to get latest result
      if (type === 'calculator') {
        await getUniqueCode(moduleId, type);
      } else {
        await getUniqueCode(moduleId, type);
      }

      toggleModal();
    });
  };

  onKeyPress = (e: any) => {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  };

  toggleModal = (modalName: any) => {
    this.setState(
      (prevState) =>
        ({
          [modalName]: !prevState[modalName],
        }) as Pick<refMangFormState, any>
    );
  };
  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.props.refManagerId) {
      this.props.modal && this.props.closeUiElementPane?.();
      this.editReference();
    } else {
      this.props.modal && this.props.closeUiElementPane?.();
      this.createReference();
    }
  };

  render() {
    const { isFullHeight, toggleModal } = this.props;
    const { source, isShortenedSourceEnabled, url, shortened_source } = this.state;
    const helpInfoData = this.helpInfoData;

    return (
      <Form
        onKeyPress={this.onKeyPress}
        autoComplete='off'
        className='flex h-full min-h-full flex-col'
        onSubmit={this.handleSubmit}
      >
        {isFullHeight ? (
          <Header className='px-0' title='References' toggleModal={toggleModal} />
        ) : (
          ''
        )}

        <Box className='ml-1'>
          <InputField
            name='source'
            required={true}
            value={source}
            onChange={this.onChange}
            label={helpInfoData?.source?.label}
            detail={helpInfoData?.source?.detail}
            placeholder={helpInfoData?.source?.placeholder}
            maxLength={helpInfoData?.source?.character_limit}
          />
        </Box>

        <Box className='ml-1'>
          <InputField
            addToggle={true}
            name='shortened_source'
            value={shortened_source}
            onChange={this.onChange}
            disabled={!isShortenedSourceEnabled}
            toggleValue={isShortenedSourceEnabled}
            label={helpInfoData?.shortened_source?.label}
            detail={helpInfoData?.shortened_source?.detail}
            placeholder={helpInfoData?.shortened_source?.placeholder}
            handleToggle={() => this.toggleModal('isShortenedSourceEnabled')}
          />
        </Box>

        <Box className='ml-1'>
          <InputField
            name='url'
            value={url}
            required={true}
            onChange={this.onChange}
            label={helpInfoData?.url?.label}
            detail={helpInfoData?.url?.detail}
            placeholder={helpInfoData?.url?.placeholder}
            maxLength={helpInfoData?.url?.character_limit}
          />
        </Box>

        <div className='row' style={{ marginLeft: 'auto', marginTop: 'auto' }}>
          <CancelButton style={{ color: 'black', marginRight: '10px' }} onClick={toggleModal}>
            {CANCEL_BUTTON}
          </CancelButton>
          <CreateButton type='submit' name='action'>
            {SAVE_BUTTON}
          </CreateButton>
        </div>

        <ConfirmModal
          preset='unsaved'
          open={this.props.modal!}
          toggleModal={this.props.closeUiElementPane!}
          toggleModalPanel={this.props.toggleModal!}
          handleSubmit={this.handleSubmit}
          panelForm
        />
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });
const mapDispatchToProps = (dispatch, refMangFormProps) => ({
  getUniqueCode: (moduleId, type) => dispatch(getUniqueCode(moduleId, type)),
  getInfoBoxes: (type, mirrorId, moduleId) => dispatch(getInfoBoxes(type, mirrorId, moduleId)),
  getRefManagers: (type, mirrorId, moduleId) => dispatch(getRefManagers(type, mirrorId, moduleId)),
  getNoteGenerator: (type, mirrorId, moduleId) =>
    dispatch(getNoteGenerator(type, mirrorId, moduleId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefManagerFormWrapper);
