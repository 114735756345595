import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useMutation } from '@tanstack/react-query';
import { GptBoxApi } from 'api/cards';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import { GptBoxFormVariable } from 'components/module/cards/GPTBoxCard';
import SituationBuilder from 'components/resources/triggers/SituationBuilder';
import { AvoSwitch } from 'components/utils/AvoSwitch';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/utils/AvoTooltip';
import Button from 'components/utils/Button';
import { ComboBox } from 'components/utils/ComboBox';
import { Icons } from 'components/utils/Icons';
import Input from 'components/utils/Input';
import Label from 'components/utils/Label';
import { RadioButton } from 'components/utils/RadioButton';
import { StaffOnlyBadgeType, StaffOnlyWrapper } from 'components/utils/StaffOnlyWrapper';
import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import { ToolbarButton } from 'components/utils/draftJS/utils';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { AvoSlideCloseModal } from 'components/utils/panels/AvoSlideCloseModal';
import { SlidePanel } from 'components/utils/panels/AvoSlidePanel';
import RequiredMark from 'components/utils/requiredMark';
import { RichTextMenuButtonTypes, SuggestionTypes } from 'components/utils/tiptap/tiptapInterfaces';
import { Body1, Body2, Caption2, H4 } from 'components/utils/typo';
import VariableAutoGenerator from 'components/utils/variable-generator';
import { useKnoweledgeBase } from 'hooks/useEHRVariables';
import { getEncoding } from 'js-tiktoken';
import { Info, X } from 'lucide-react';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { getVariableName } from 'utils/gptUtils';
import { getNumerics, getSuggestions, getVariables } from 'utils/suggestions';
import { useTriggers } from '../../hooks/module/resources/useTriggers';
export const encoding = getEncoding('cl100k_base');

const richTextButtonsShowList: RichTextMenuButtonTypes[] = ['more'];

interface GPTBoxFormProps {
  handleRichText: (value: any) => void;
  handleRichTextTiptap: (value: any) => void;
  handleAddTitle: (title: string) => void;
  formState: any;
  teamState: any;
  knowledgeBases: any;
  gptBoxId: number;
  triggerState: any;
  position: number;
  handleSelectedItem: () => void;
  handleAddTrigger: (trigger: any) => void;
  handleAddVariable: (variable: any) => void;
  startPreview: (component?: any) => void;
  moduleId: string;
  toggleModal: () => void;
  resetState: () => void;
  container: any;
  modal?: boolean;
  closeUiElementPane?: (type?: any) => void;
  setIsFormDisabled: (val) => void;
}

const suggestionsToExclude: SuggestionTypes[] = [
  'reference',
  'infobox',
  'link',
  'media',
  'contact_number',
  'ehr_order',
];

interface GptBoxFormProps {
  gptboxId: number;
  onSave: () => void;
  onCancel: () => void;
}

const gptModels = [
  { label: 'GPT 4o', value: 'gpt-4o' },
  { label: 'GPT o1 preview', value: 'o1-preview' },
  { label: 'GPT o1-mini', value: 'o1-mini' },
] as const;
export const gptModelMap = gptModels.reduce(
  (acc, model) => {
    acc[model.value] = model.label;
    return acc;
  },
  {} as Record<GptModelValues, string>
);
export type GptModelValues = (typeof gptModels)[number]['value'];

export const GptBoxForm = ({ gptboxId, onSave, onCancel }: GptBoxFormProps) => {
  const { module } = useContext(ModuleContext);
  const { data: triggerState } = useTriggers();
  const triggers = [...triggerState.triggers, ...triggerState.candidate_triggers];
  const [triggerId, setTriggerId] = useState<string | number>('');
  const triggerOptions = triggers.map((trigger) => ({
    value: trigger.id,
    label: trigger.title,
  }));
  const triggerComponentProps = {
    toggleModal: () => setTriggerFormOpen(false),
    isFullHeight: true,
    selectTrigger: (value) => setTrigger(value),
    createModal: true,
    triggerId: triggerId,
    // modal: triggerModalOpen,
    savePosition: () => {},
    // toogleModalPane: () => setTriggerModalOpen(false),
  };

  const [triggerFormOpen, setTriggerFormOpen] = useState(false);
  const [closeModalOpen, setCloseModalOpen] = useState(false);

  const {
    watch,
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = useFormContext<GptBoxFormVariable>();
  const initialPrompt = useRef(
    module?.use_new_editor ? getValues('prompt_json_tiptap') : getValues('prompt_json')
  );

  const [isPromptEmpty, setIsPromptEmpty] = useState(!gptboxId);

  /** for draftjs */
  const knowledgeBases = useKnoweledgeBase();
  const [variables, setVariables] = useState([]);
  const [suggestions, setSuggestions] = useState<{ name: string; code: string }[]>([]);
  const [numerics, setNumerics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!module || module.use_new_editor) return;
    getVariables(module.id).then(setVariables);
    getSuggestions(module.id).then(setSuggestions);
    getNumerics(module.id).then(setNumerics);
  }, [module?.id]);

  const { mutateAsync } = useMutation({
    mutationFn: gptboxId
      ? (data: GptBoxFormVariable) => GptBoxApi.patch(gptboxId, data)
      : (data: GptBoxFormVariable) => GptBoxApi.post(data),
  });
  const onPromptDraftJsChange = (value: any) => {
    const text = value.blocks.map((block) => block.text).join('\n');
    setIsPromptEmpty(!text);
    setValue('prompt_json', value, { shouldDirty: true });
  };

  const onPromptTiptapChange = (editor) => {
    setValue('prompt_json_tiptap', editor.getJSON(), { shouldDirty: true });
    setIsPromptEmpty(editor.isEmpty);
  };
  const setTrigger = (value) => {
    setValue('trigger', value);
  };

  const onSubmit = async (data: GptBoxFormVariable) => {
    if (isLoading || !watch('title') || isPromptEmpty) return;
    setIsLoading(true);
    if (!data.variable) {
      data.variable = await getVariableName(data.title);
    }
    await mutateAsync(data);
    onSave();
    setIsLoading(false);
  };

  return (
    <>
      <form className='flex h-full flex-col' onSubmit={handleSubmit(onSubmit)}>
        <div className='grow overflow-y-auto'>
          <div className='sticky top-0 z-10 flex items-center justify-between bg-white pl-4 pr-3 pt-[20px]'>
            <H4>GPT Box</H4>
            <div
              className='cursor-pointer'
              onClick={() => (isDirty ? setCloseModalOpen(true) : onCancel())}
            >
              <X size={20} />
            </div>
          </div>
          <div className='mt-4 px-4'>
            <div className='space-y-1'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>Trigger</Body2>
                <RequiredMark />
              </div>
              <ComboBox
                onChange={(value: any) => setValue('trigger', value, { shouldDirty: true })}
                options={triggerOptions}
                selectedValue={watch('trigger')}
                onCreate={() => {
                  setTriggerId('');
                  setTriggerFormOpen(true);
                }}
                onEdit={(id) => {
                  setTriggerId(id);
                  setTriggerFormOpen(true);
                }}
                createButtonLabel='Create New Trigger'
              />
            </div>
            <div className='mt-6 space-y-1'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>Title</Body2>
                <RequiredMark />
              </div>
              <Input
                {...register('title', { required: true, maxLength: 200 })}
                required
                maxLength={200}
              />
              <Caption2 className='flex justify-end text-gray-text-weak'>
                {watch('title').length}/200
              </Caption2>
            </div>
            <div className='mt-1 space-y-2'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>Prompt</Body2>
                <RequiredMark />
              </div>
              <AvoEditor
                gptBox
                moduleId={module?.id}
                setValue={onPromptDraftJsChange}
                prevValue={initialPrompt.current}
                placeholder='Write a prompt.'
                suggestions={suggestions}
                variables={variables}
                numerics={numerics}
                knowledgeBases={knowledgeBases}
                richTextButtonShowList={[ToolbarButton.VARIABLES, ToolbarButton.CONDITIONAL_TEXT]}
                wrapperClassNames='flex-grow max-h-[600px] min-h-[280px] !h-auto'
                onUpdate={onPromptTiptapChange}
                initialContent={initialPrompt.current}
                suggestionsToExclude={suggestionsToExclude}
                richTextButtonsShowListTiptap={richTextButtonsShowList}
              />
            </div>
            <StaffOnlyWrapper type={StaffOnlyBadgeType.STAFF}>
              <div className='mt-6'>
                <div className='w-full space-y-1'>
                  <Body2>LLM Model</Body2>
                  <Listbox
                    className='relative'
                    as='div'
                    onChange={(value: GptModelValues) =>
                      setValue('model', value, { shouldDirty: true })
                    }
                    value={watch('model')}
                  >
                    <Listbox.Button className='z-20 flex w-full justify-between gap-1 rounded border border-gray-200 p-2.5 pl-3 focus:bg-white'>
                      <Body2>{gptModelMap[watch('model')]}</Body2>
                      <Icons.CaretDown />
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      enter='transition duration-100 ease-out'
                      enterFrom='transform scale-95 opacity-0'
                      enterTo='transform scale-100 opacity-100'
                      leave='transition duration-75 ease-out'
                      leaveFrom='transform scale-100 opacity-100'
                      leaveTo='transform scale-95 opacity-0'
                    >
                      <Listbox.Options className='absolute z-10 w-full rounded bg-white shadow-04'>
                        {gptModels.map((model) => (
                          <Listbox.Option
                            key={model.value}
                            value={model.value}
                            className='p-2 hover:bg-primary-300'
                          >
                            {({ selected }) => (
                              <div className='flex justify-between'>
                                <Body2>{model.label}</Body2>
                                {selected && <CheckIcon className='h-5 w-5 text-primary-500' />}
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </Listbox>
                </div>
              </div>
            </StaffOnlyWrapper>
            <StaffOnlyWrapper type={StaffOnlyBadgeType.BETA}>
              <div className='mt-6 flex items-center justify-between'>
                <div className='flex items-center gap-1 text-gray-900'>
                  <Body2>Hide Output</Body2>
                  <Tooltip placement='bottom'>
                    <TooltipTrigger>
                      <Info size={20} color='#C5D1D8' />
                    </TooltipTrigger>
                    <TooltipContent>
                      <div className='w-[280px] gap-4 rounded bg-gray-800 px-3 py-2 shadow'>
                        <Caption2 className='text-white'>
                          Enable this option to use outputs as inputs in subsequent GPT processes.
                          If enabled, <span className='whitespace-nowrap'>‘Follow-up Actions’</span>{' '}
                          mode will be disabled.
                        </Caption2>
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </div>
                <AvoSwitch
                  checked={watch('is_hide')}
                  onChange={(value) => setValue('is_hide', value, { shouldDirty: true })}
                />
              </div>
            </StaffOnlyWrapper>
            {watch('is_hide') && (
              <div className='mt-1 space-y-2 bg-gray-100 px-2 py-3'>
                <Label className='flex gap-1'>
                  <div className='p-[3.5px]'>
                    <RadioButton {...register('hide_option')} value='sneak' />
                  </div>
                  <div>
                    <Body1 className='text-gray-900'>Sneak peek on click</Body1>
                    <Caption2 className='text-gray-700'>
                      Show AI reasoning when the user clicks on the link
                    </Caption2>
                  </div>
                </Label>
                <Label className='flex gap-1'>
                  <div className='p-[3.5px]'>
                    <RadioButton {...register('hide_option')} value='hide-reasoning' />
                  </div>
                  <div>
                    <Body1 className='text-gray-900'>Hide AI reasoning</Body1>
                    <Caption2 className='text-gray-700'>Keep AI reasoning hidden</Caption2>
                  </div>
                </Label>
                <Label className='flex gap-1'>
                  <div className='p-[3.5px]'>
                    <RadioButton {...register('hide_option')} value='hide-all' />
                  </div>
                  <div>
                    <Body1 className='text-gray-900'>Hide the box completely</Body1>
                    <Caption2 className='text-gray-700'>
                      The flow will be invisible to users
                    </Caption2>
                  </div>
                </Label>
              </div>
            )}
            <div className='mt-6'>
              <VariableAutoGenerator
                tagTitle='AI Output'
                variable={watch('variable')}
                question={watch('title')}
                setVariableName={(value) => setValue('variable', value, { shouldDirty: true })}
              />
            </div>
          </div>
        </div>
        <div className='shrink-0'>
          <div className='flex justify-end gap-2 px-[12px] pb-[22px] pt-[12px]'>
            <div className='flex'>
              <Button.Reverse
                type='button'
                onClick={() => (isDirty ? setCloseModalOpen(true) : onCancel())}
                disabled={isLoading}
              >
                Cancel
              </Button.Reverse>
            </div>
            <div className='flex w-[80px]'>
              <Button disabled={isLoading || !isDirty || isPromptEmpty || !watch('title')}>
                {isLoading ? <LoadingSpinner /> : 'Save'}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <AvoSlideCloseModal
        open={closeModalOpen}
        onContinue={() => setCloseModalOpen(false)}
        onDiscard={onCancel}
      />
      <SlidePanel open={triggerFormOpen} onClose={() => setTriggerFormOpen(false)}>
        <div className='mx-4 mt-3'>
          <SituationBuilder {...triggerComponentProps} />
        </div>
      </SlidePanel>
    </>
  );
};
