import { auth } from 'api/firebase';
import axios from 'axios';
import { CustomToast } from 'components/utils/toast-message';
import { signOut } from 'firebase/auth';
import { authAtom } from 'hooks/useAuthentication';
import { getDefaultStore } from 'jotai';
import { RESET } from 'jotai/utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Events, track } from 'utils/analytics';

axios.interceptors.request.use(
  async (config) => {
    const store = getDefaultStore();
    const authenticate = store.get(authAtom);
    if (authenticate.auth_type === 'knox') {
      if (!!authenticate.token) {
        config.headers.Authorization = `Token ${authenticate.token}`;
      }
      return config;
    }

    // else auth_type == 'firebase'   // TODO: This flow should be modified later when frontend use firebase auth only
    await auth.authStateReady();
    const firebaseToken = await auth.currentUser?.getIdToken();
    if (authenticate.auth_type === 'firebase' && !!firebaseToken) {
      config.headers.Authorization = `Bearer ${firebaseToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    try {
      const responseConfigData = error?.response?.config?.data;
      if (responseConfigData) {
        const tmp = JSON.parse(responseConfigData);
        delete tmp.password;
        error.response.config.data = JSON.stringify(tmp);
      }
    } catch (e) {}

    track(Events.API_ERROR, error?.response);

    const defaultMsg = "Oops! Something went wrong. We're sorry, but an unexpected error occurred.";
    if (error?.response?.status === 401) {
      const store = getDefaultStore();
      if (!!store.get(authAtom).token) {
        toast.error(CustomToast, { data: 'Session Expired, logging out...!' });
        store.set(authAtom, RESET);
        signOut(auth);
      }
    } else if (error?.response?.status === 403) {
      toast.error(CustomToast, {
        data: 'You are not authorized to perform this action. Please contact the team admin or the owner to get access.',
        delay: 10000,
      });

      if (window.location.pathname !== '/') setTimeout(() => (window.location.href = '/'), 10000);
    } else if (error?.response?.status === 400) {
      const errorData =
        error?.response?.data?.error ||
        error?.response?.data?.non_field_errors ||
        error?.response?.data;
      if (errorData) {
        if (typeof errorData === 'object') {
          Object.values(errorData).forEach((value) => {
            if (Array.isArray(value)) {
              value.forEach((v) => toast.error(CustomToast, { data: v }));
            } else if (value) {
              toast.error(CustomToast, { data: value });
            }
          });
        } else {
          toast.error(CustomToast, {
            data: errorData,
          });
        }
      } else {
        toast.error(CustomToast, {
          data: defaultMsg,
        });
      }
    } else {
      toast.error(CustomToast, {
        data: defaultMsg,
      });
    }
    return Promise.reject(error?.response || 'Promise Rejected');
  }
);
