import { useQuery } from '@tanstack/react-query';
import { calculatorAPI } from 'api/module';
import { resourcesAPI } from 'api/resources';
import { useTeam } from 'hooks/useTeam';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { MODULE_TYPES } from '../../../constants';

export const useToolOptions = () => {
  const { team } = useTeam();
  const { pathname } = useLocation();
  const moduleType = pathname.split('/')[1];
  const { moduleId } = useParams<{ moduleId: string }>();

  const { data: infoboxes = [], refetch: refetchInfoboxes } = useQuery({
    queryKey: ['infoboxes', moduleId],
    queryFn:
      moduleType === MODULE_TYPES.ALGO
        ? resourcesAPI.getInfoboxes
        : resourcesAPI.getCalculatorInfoboxes,
    refetchOnWindowFocus: false,
  });

  const { data: smartNotes = [] } = useQuery({
    queryKey: ['smart-notes', moduleId],
    queryFn:
      moduleType === MODULE_TYPES.ALGO
        ? resourcesAPI.getSmartNotes
        : resourcesAPI.getCalculatorSmartNotes,
    refetchOnWindowFocus: false,
  });

  const { data: legacyCalculators = [] } = useQuery({
    queryKey: ['legacyCalculators'],
    queryFn: calculatorAPI.getLegacyCalculators,
    refetchOnWindowFocus: false,
  });

  const { data: universalCalculators = [] } = useQuery({
    queryKey: ['universalCalculators'],
    queryFn: calculatorAPI.getUniversalCalculators,
    refetchOnWindowFocus: false,
  });

  const { data: teamCalculators = [] } = useQuery({
    queryKey: ['teamCalculators', team.id],
    queryFn: calculatorAPI.getTeamCalculators,
    refetchOnWindowFocus: false,
  });

  const infoboxOptions = useMemo(() => {
    return infoboxes.map((infobox) => ({
      value: infobox.id,
      label: infobox.title,
      id: infobox.id, // needed for backend
    }));
  }, [infoboxes]);

  const smartNoteOptions = useMemo(() => {
    return smartNotes.map((smartNote) => ({
      value: smartNote.id,
      label: smartNote.title,
      infobox_type: smartNote.infobox_type, // needed for note generator
      id: smartNote.id, // needed for backend
    }));
  }, [smartNotes]);

  const legacyCalcOptions = useMemo(() => {
    return legacyCalculators.map((calculator) => ({
      value: calculator.id,
      label: calculator.title,
      type: 'legacy_calculator',
      id: calculator.id, // needed for backend
    }));
  }, [legacyCalculators]);

  const universalCalcOptions = useMemo(() => {
    return universalCalculators.map((calculator) => ({
      value: calculator.id,
      label: calculator.name,
      type: 'calculator',
      id: calculator.id, // needed for backend
    }));
  }, [universalCalculators, legacyCalculators]);

  const teamCalcOptions = useMemo(() => {
    return teamCalculators.map((calculator) => ({
      value: calculator.id,
      label: calculator.name,
      type: 'calculator',
      id: calculator.id, // needed for backend
    }));
  }, [teamCalculators]);

  const calcOptions = useMemo(() => {
    return [
      { label: 'Team Calculator', value: 'team_calculator', type: 'heading' },
      ...teamCalcOptions,
      { label: 'Universal Calculator', value: 'universal_calculator', type: 'heading' },
      ...[...universalCalcOptions, ...legacyCalcOptions],
    ];
  }, [universalCalcOptions, legacyCalcOptions, teamCalcOptions]);

  return {
    calcOptions,
    infoboxOptions,
    smartNoteOptions,
    teamCalcOptions,
    refetchInfoboxes,
    legacyCalcOptions,
    universalCalcOptions,
  };
};
