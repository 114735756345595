import { Box } from '@material-ui/core';
import axios from 'axios';
import AppearingSituation from 'components/utils/AppearingSituation';
import InputField from 'components/utils/form-input/field';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { Header } from 'components/utils/panels/Header';
import { CustomToast } from 'components/utils/toast-message';
import { CANCEL_BUTTON, CARD_SECTION_DIVIDER, SAVE_BUTTON } from 'constants/variables';
import React, { Component, ComponentType, FormEvent } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Form } from 'reactstrap';
import { SECTION_DIVIDER_API_URL } from '../../constants';
import { CancelButton, CreateButton, FixedRow, UnderlyingElement } from './ChoicePanelForm';
import { withTriggers } from '../../hooks/module/resources/useTriggers';

interface SectionDividerFormState {
  triggerOptions: any;
  pk: string;
  title: string;
  subtitle: string;
  trigger: string;
  module: string;

  containerCard: any;
  position: number | null;
}

interface SectionDividerFormProps {
  formState?: any;
  sectionDividerId: number;
  triggerState?: any;
  handleSelectedItem: () => void;
  position: number;
  handleAddTrigger: (trigger: any) => void;
  startPreview: (component?: any) => void;
  handleAddTitle: (title: string) => void;
  handleAddSubtitle: (sub: string) => void;
  moduleId?: number;
  toggleModal: () => void;
  resetState: () => void;
  modal?: boolean;
  closeUiElementPane?: (type?: any) => void;
}

class SectionDividerForm extends Component<SectionDividerFormProps, SectionDividerFormState> {
  constructor(props: SectionDividerFormProps) {
    super(props);

    this.state = {
      triggerOptions: [],
      pk: '',
      title: '',
      subtitle: '',
      trigger: '',
      module: '',

      containerCard: '',
      position: null,
    };
  }

  helpInfoData = getHelpInfoData('SectionDivider');

  componentDidMount() {
    if (!this.props.sectionDividerId) {
      let alwayOnTrigger = this.props.triggerState.triggers.find(
        (data) => data.title === 'Always On'
      );
      this.setState({
        trigger: alwayOnTrigger ? alwayOnTrigger.id : '',
        position: this.props.position,
      });
      this.props.handleSelectedItem();
      this.props.handleAddTrigger(alwayOnTrigger ? alwayOnTrigger.id : '');
      this.props.startPreview('renderDividerPreview');
    }

    // if update request encountered
    if (this.props.sectionDividerId) {
      axios.get(SECTION_DIVIDER_API_URL + this.props.sectionDividerId + '/').then((res) => {
        this.setState({
          pk: res.data.id,
          title: res.data.title,
          subtitle: res.data.subtitle,
          position: res.data.position,
          trigger: res.data.trigger ? res.data.trigger : '',
          module: res.data.module,
        });
        this.props.handleAddTitle(res.data.title);
        this.props.handleAddSubtitle(res.data.subtitle);
        this.props.handleAddTrigger(res.data.trigger ? res.data.trigger : '');
      });
    }
  }

  // updates the state on field input
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'title') {
      this.props.handleAddTitle(e.target.value);
    }
    if (e.target.name === 'subtitle') {
      this.props.handleAddSubtitle(e.target.value);
    }
    this.setState({ [e.target.name]: e.target.value } as Pick<SectionDividerFormState, any>);
  };

  getPayload = () => {
    return {
      title: this.state.title,
      subtitle: this.state.subtitle,
      trigger: this.state.trigger === 'notAssigned' ? null : this.state.trigger,
      module: this.props.moduleId,
      position: this.state.position,
    };
  };

  createSectionDivider = () => {
    this.props.toggleModal();
    axios.post(SECTION_DIVIDER_API_URL, this.getPayload()).then(() => {
      this.props.resetState();
    });
  };

  editSectionDivider = () => {
    this.props.toggleModal();
    axios.put(SECTION_DIVIDER_API_URL + this.state.pk + '/', this.getPayload()).then(() => {
      this.props.resetState();
    });
  };

  getSelectedTrigger = (data: any) => {
    this.setState({
      trigger: data,
    });
    this.props.handleAddTrigger(data);
  };

  onKeyPress = (e: any) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { title } = this.state;
    const { sectionDividerId, modal, closeUiElementPane } = this.props;
    if (title === '') {
      toast.error(CustomToast, { data: 'Please fill out the section divider title field.' });
    } else if (sectionDividerId) {
      this.editSectionDivider();
    } else {
      this.createSectionDivider();
    }
    modal && closeUiElementPane?.();
  };

  render() {
    const helpInfoData = this.helpInfoData;
    const triggers = [
      ...this.props.triggerState.triggers,
      ...this.props.triggerState.candidate_triggers,
    ];

    return (
      <div className='row mt-14 h-[97%] overflow-visible'>
        <div className='side-panel-form' style={{ overflow: 'visible' }}>
          <Header
            title={
              this.props.sectionDividerId
                ? `Update ${CARD_SECTION_DIVIDER}`
                : `Create ${CARD_SECTION_DIVIDER}`
            }
            toggleModal={this.props.toggleModal}
          />
          <Form
            onKeyPress={this.onKeyPress}
            autoComplete='off'
            className='sectionDivider flex h-full flex-col px-3 pb-0'
            onSubmit={this.handleSubmit}
          >
            <Box>
              <AppearingSituation
                triggerOptions={triggers}
                onInputChange={this.getSelectedTrigger}
                defaultValue={this.state.trigger}
                moduleId={this.props.moduleId}
              />
            </Box>

            <Box style={{ marginTop: '30px' }}>
              <InputField
                name='title'
                required={true}
                value={this.state.title}
                onChange={this.onChange}
                label={helpInfoData?.title?.label}
                detail={helpInfoData?.title?.detail}
                placeholder={helpInfoData?.title?.placeholder}
                maxLength={helpInfoData?.title?.character_limit}
              />
            </Box>

            <Box style={{ marginTop: '20px' }}>
              <InputField
                name='subtitle'
                value={this.state.subtitle}
                onChange={this.onChange}
                label={helpInfoData?.subtitle?.label}
                detail={helpInfoData?.subtitle?.detail}
                placeholder={helpInfoData?.subtitle?.placeholder}
                maxLength={helpInfoData?.subtitle?.character_limit}
              />
            </Box>
            <UnderlyingElement />
            <FixedRow>
              <div
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 30,
                  marginBottom: 10,
                }}
              >
                <CancelButton onClick={this.props.toggleModal} style={{ marginRight: '40px' }}>
                  {CANCEL_BUTTON}
                </CancelButton>
                <CreateButton type='submit' name='action'>
                  {SAVE_BUTTON}
                </CreateButton>
              </div>
            </FixedRow>
          </Form>
        </div>
        <ConfirmModal
          preset='unsaved'
          open={this.props.modal!}
          toggleModal={this.props.closeUiElementPane!}
          toggleModalPanel={this.props.toggleModal!}
          handleSubmit={this.handleSubmit}
          panelForm
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({ ...state });

export default withTriggers(
  connect(mapStateToProps, {})(SectionDividerForm)
) as ComponentType<SectionDividerFormProps>;
