import { JSONContent } from '@tiptap/react';
import { debounce } from 'lodash';
import { MentionProvider } from '../../utils/module/MentionContext';
import { Tiptap } from '../../utils/tiptap/Tiptap';

interface TiptapJSONEditorProps {
  initBody: JSONContent;
  onChange: (value: JSONContent) => void;
  onValidation: (value: boolean) => void;
}

export const TiptapJSONEditor = ({ initBody, onChange, onValidation }: TiptapJSONEditorProps) => {
  const onUpdate = debounce((editor) => {
    onChange(editor.getJSON());
    try {
      JSON.parse(editor.getText());
      onValidation(true);
    } catch {
      onValidation(false);
    }
  }, 300);

  return (
    <MentionProvider excludedSuggestionTypes={[]} performChoiceCodeSubstitution>
      <Tiptap onUpdate={onUpdate} initialContent={initBody} />
    </MentionProvider>
  );
};
