import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseMutateFunction,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { useContext } from 'react';
import { ConditionalText, ConditionalTextRequest, resourcesAPI } from '../../../api/resources';
import { MODULE_TYPES } from '../../../constants';

interface UseConditionalTexts {
  data: ConditionalText[];
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters) | undefined
  ) => Promise<QueryObserverResult<ConditionalText[]>>;
  postConditionalText: UseMutateFunction<any, unknown, ConditionalTextRequest>;
  putConditionalText: UseMutateFunction<any, unknown, ConditionalTextRequest>;
  isMutationLoading: boolean;
}

export const useConditionalTexts = (): UseConditionalTexts => {
  const { module } = useContext(ModuleContext);

  const { data, refetch } = useQuery({
    queryKey: [module?.type, module?.id, 'conditional-texts'],
    queryFn:
      module?.type === MODULE_TYPES.ALGO
        ? resourcesAPI.getConditionalTexts
        : resourcesAPI.getCalculatorConditionalTexts,
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 1000 * 60 * 5,
  });

  const { mutate: postConditionalText, isPending: isPostLoading } = useMutation({
    mutationFn: resourcesAPI.postConditionalText,
    onSuccess: () => refetch,
  });
  const { mutate: putConditionalText, isPending: isPutLoading } = useMutation({
    mutationFn: resourcesAPI.putConditionalText,
    onSuccess: () => refetch,
  });

  return {
    data,
    refetch,
    postConditionalText,
    putConditionalText,
    isMutationLoading: isPostLoading || isPutLoading,
  };
};
