import Invitation from 'components/teams/Invitation';
import AuthorForm from 'components/teams/authorForm';
import MemberManagment from 'components/teams/memberManagment';
import CreateTeam from 'components/teams/team-settings/createTeam';
import { CREATE_TEAM_ROUTE, MEM_MANGEMENT_ROUTE, OLD_CREATE_TEAM_ROUTE } from '../../constants';

const TeamRoutes = [
  {
    type: 'PUBLIC',
    path: '/invitation/:inviteCode',
    component: Invitation,
  },
  {
    type: 'PRIVATE',
    path: '/authorForm',
    component: AuthorForm,
  },
  {
    type: 'PRIVATE',
    path: CREATE_TEAM_ROUTE,
    component: CreateTeam,
  },
  {
    type: 'REDIRECT',
    path: OLD_CREATE_TEAM_ROUTE,
    to: CREATE_TEAM_ROUTE,
  },
  {
    type: 'PRIVATE',
    path: MEM_MANGEMENT_ROUTE,
    component: MemberManagment,
  },
  // {
  //   type: 'PRIVATE',
  //   path: EHR_CONFIG_ROUTE,
  //   component: EHRConfig,
  // },
  // {
  //   type: 'PRIVATE',
  //   path: OLD_EHR_ORDER_INDIVIDUAL_ROUTE,
  //   component: OldIndividualEHROrder,
  // },
];

export default TeamRoutes;
