import { Disclosure, Menu } from '@headlessui/react';
import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import { ToolbarButton } from 'components/utils/draftJS/utils';
import { Fragment, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { convertCodeToText, generateID } from '../../../../utils/utilityFunctions';
import { Icons } from '../../../utils/Icons';
import { Icon } from '../../../utils/common/Icon';
import { SlidePanel } from '../../../utils/panels/SlidePanel';
import { RichTextMenuButtonTypes, SuggestionTypes } from '../../../utils/tiptap/tiptapInterfaces';
import { Body1, Body2, H4 } from '../../../utils/typo';
import { convertCondition, removePrefixesInCondition } from '../../triggers/SituationBuilderUtils';
import ConditionBuilderForm from './ConditionBuilderForm';

const suggestionsToExclude: SuggestionTypes[] = [
  'link',
  'reference',
  'knowledge_base',
  'conditional_text',
  'media',
  'contact_number',
  'ehr_order',
];
const richTextButtonsShowList: RichTextMenuButtonTypes[] = [
  'textStyles',
  'bold',
  'italic',
  'highlight',
  'bulletList',
  'orderedList',
  'more',
];

interface ConditionDisclosureProps {
  index: number;
  numerics: any;
  variables: any;
  draftJSSuggestions: any;
}

export const ConditionalTextDisclosure = ({
  index,
  numerics,
  variables,
  draftJSSuggestions,
}: ConditionDisclosureProps) => {
  const { watch, setValue } = useFormContext();
  const conditions = watch('conditions');
  const currentCondition = conditions[index];
  const hasMultipleDefaultCondition =
    conditions.reduce((acc: number, condition: any) => (condition.is_default ? acc + 1 : acc), 0) >
      1 && currentCondition.is_default;

  const { pathname } = useLocation();
  const moduleType = pathname.split('/')[1];
  const { moduleId } = useParams<{ moduleId: string }>();

  const getEditorTextLength = (draftJSOrTiptapItem: any) => {
    if (draftJSOrTiptapItem?.content?.length > 0) {
      return draftJSOrTiptapItem.content.map((item) => item.content?.[0].text ?? '').join(' ')
        .length;
    } else if (draftJSOrTiptapItem?.blocks?.length > 0) {
      return draftJSOrTiptapItem.blocks.map((block) => block.text).join(' ').length;
    } else {
      return 0;
    }
  };

  const handleDuplicate = () => {
    const targetCondition = { ...currentCondition, sortableId: generateID() };
    delete targetCondition.id;
    const duplicatedConditions = [targetCondition, ...conditions];
    duplicatedConditions.forEach((condition, position) => (condition.position = position));
    setValue('conditions', duplicatedConditions);
  };
  const handleDelete = () => {
    setValue(
      'conditions',
      conditions.filter((_, key) => key !== index)
    );
  };
  const handleChange = (e) => {
    setValue(
      'conditions',
      conditions.map((condition, conditionIndex) =>
        conditionIndex === index ? { ...condition, label: e.target.value } : condition
      )
    );
  };

  // TODO: remove below when replace DraftJS to tiptap
  const knowledgeBase = useSelector((state: any) => state.knowledgeBaseState.knowledgeBases) || [];
  const [textContent, setTextContent] = useState();

  const changeText = (e: any) => {
    const text = e.blocks.map((block) => block.text).join('\n');
    handleConditions(index, text, e, undefined);
  };

  // TODO: need to refine code when remove DraftJS
  const handleConditions = (index, text: string, textJson, textJsonTiptap) => {
    const data = textJsonTiptap
      ? { text: text, text_content_tiptap: textJsonTiptap }
      : {
          text: text,
          text_content: textJson,
        };

    setValue(
      'conditions',
      conditions.map((condition, conditionIndex) =>
        conditionIndex === index ? { ...condition, ...data } : condition
      )
    );
  };

  //queryBuilder Logic
  const [condition, setCondition] = useState();
  const [conditionJson, setConditionJson] = useState();
  const [conditionLogic, setConditionLogic] = useState();
  const setBuilderCondition = (e) => {
    let formattedCondition = removePrefixesInCondition(e);
    let index = formattedCondition.indexOf('IN');

    while (index !== -1) {
      formattedCondition = convertCondition(formattedCondition, index);
      index = formattedCondition.indexOf('IN');
    }
    setCondition(formattedCondition);
  };

  const setRawCondition = (index, condition, conditionLogic, conditionJson) => {
    setValue(
      'conditions',
      conditions.map((currentCondition, conditionIndex) =>
        conditionIndex === index
          ? {
              ...conditions[index],
              condition,
              condition_logic: conditionLogic,
              builder_condition_json: conditionJson,
            }
          : currentCondition
      )
    );
  };

  return (
    <Disclosure as='div' className='m-[4px] rounded border border-gray-border-strong bg-white'>
      {({ open }) => (
        <>
          <Disclosure.Button as={Fragment}>
            <div
              className='flex w-full cursor-pointer items-center space-x-[8px] px-[16px] py-[14px] focus:bg-transparent'
              onClick={() => setTextContent(currentCondition.text_content)}
            >
              <Icons.ChevronDown className={twMerge(open && 'rotate-180')} />
              {open ? (
                <>
                  <input
                    className='grow border-b border-gray-300 text-body-1 focus:outline-0 disabled:bg-transparent'
                    value={currentCondition.label ?? 'None of the above (Default)'}
                    onChange={handleChange}
                    onClick={(e) => e.stopPropagation()}
                    disabled={currentCondition.is_default}
                  />
                  {(!currentCondition.is_default || hasMultipleDefaultCondition) && (
                    <Menu as='div' className='relative' onClick={(e) => e.stopPropagation()}>
                      {({ open }) => (
                        <>
                          <Menu.Button
                            as='p'
                            className={twMerge(
                              'm-0 flex h-[20px] w-[20px] items-center justify-center rounded',
                              open && 'bg-gray-600'
                            )}
                          >
                            <Icons.ThreeDot className={open ? 'fill-white' : 'fill-gray-600'} />
                          </Menu.Button>
                          <Menu.Items
                            className={twMerge(
                              'absolute right-0 z-10 m-0 w-[100px] rounded bg-white shadow-md',
                              'outline-0' // due to material UI
                            )}
                          >
                            {!currentCondition.is_default && (
                              <Menu.Item
                                className='mb-0 h-[40px] w-[100px] px-3 py-[10px] hover:bg-primary-bg-strong hover:text-white'
                                as='p'
                                onClick={handleDuplicate}
                              >
                                <Body2>Duplicate</Body2>
                              </Menu.Item>
                            )}
                            {(!currentCondition.is_default || hasMultipleDefaultCondition) && (
                              <Menu.Item
                                as='p'
                                className='mb-0 h-[40px] w-[100px] px-3 py-[10px] hover:bg-primary-bg-strong hover:text-white'
                                onClick={handleDelete}
                              >
                                <Body2>Delete</Body2>
                              </Menu.Item>
                            )}
                          </Menu.Items>
                        </>
                      )}
                    </Menu>
                  )}
                </>
              ) : (
                <Body1 className='truncate'>
                  {currentCondition.label ?? 'None of the above (Default)'}
                </Body1>
              )}
            </div>
          </Disclosure.Button>
          <Disclosure.Panel className='space-y-[20px]'>
            <div className='ml-[28px] space-y-[12px] px-[16px]'>
              {!currentCondition.is_default && (
                <div className='flex space-x-[8px]'>
                  <Body2 className='grow truncate rounded border border-gray-border-strong bg-gray-bg-disabled px-[12px] py-[9px]'>
                    {conditions[index]?.condition &&
                      convertCodeToText(conditions[index]?.condition)}
                  </Body2>
                  <SlidePanel.Child>
                    <SlidePanel.ChildButton className='rounded border border-gray-border-strong px-[25px] py-[9px] text-button-1 focus:bg-transparent'>
                      Edit
                    </SlidePanel.ChildButton>
                    <SlidePanel.ChildBody
                      className='w-[480px] px-[16px] py-[20px] shadow-06'
                      parentId='slide-panel'
                      id='slide-panel-child'
                    >
                      {(close) => (
                        <>
                          <div className='mb-[30px] flex items-center justify-between'>
                            <H4>Conditional Text</H4>
                            <Icon.Close className='ml-auto cursor-pointer' onClick={close} />
                          </div>
                          <ConditionBuilderForm
                            moduleId={moduleId}
                            calculatorId={moduleId}
                            type={moduleType}
                            setCondition={setBuilderCondition}
                            setConditionLogic={(logic) => setConditionLogic(logic)}
                            setQueryValue={(json) => setConditionJson(json)}
                            queryValue={
                              conditions[index]?.builder_condition_json
                                ? conditions[index]?.builder_condition_json
                                : null
                            }
                            conditionIndex={index}
                            addConditionTrigger={() =>
                              setRawCondition(index, condition, conditionLogic, conditionJson)
                            }
                            setChoiceMapping={() => {}}
                            closePanel={close}
                          />
                        </>
                      )}
                    </SlidePanel.ChildBody>
                  </SlidePanel.Child>
                </div>
              )}
              <div className='space-y-[4px]'>
                <AvoEditor
                  conditionalText
                  suggestions={draftJSSuggestions}
                  setValue={changeText}
                  prevValue={textContent}
                  disableConditionalText={true}
                  numerics={numerics}
                  moduleId={moduleId}
                  variables={variables}
                  knowledgeBases={knowledgeBase}
                  moduleType={moduleType}
                  richTextButtonShowList={[
                    ToolbarButton.TEXT_STYLE,
                    ToolbarButton.BOLD,
                    ToolbarButton.ITALIC,
                    ToolbarButton.HIGHLIGHT,
                    ToolbarButton.INFOBOX,
                    ToolbarButton.INSERT_LINK,
                    ToolbarButton.VARIABLES,
                  ]}
                  wrapperClassNames='flex-grow max-h-[600px] min-h-[280px] !h-auto'
                  onUpdate={(editor) =>
                    handleConditions(index, editor.getText(), null, editor.getJSON())
                  }
                  initialContent={conditions[index]?.text_content_tiptap}
                  suggestionsToExclude={suggestionsToExclude}
                  richTextButtonsShowListTiptap={richTextButtonsShowList}
                />
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
