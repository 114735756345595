import ErrorImage from 'assets/images/404-error.png';
import {
  ErrorPage,
  ErrorText,
  Heading,
  ImageContainer,
  MainButton,
  Small,
} from 'components/utils/styled-components/NotFoundPageStyle';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <ErrorPage data-testid='error-page__test'>
      <ErrorText>
        <Heading>
          Sorry, <br /> We cannot find the page you are looking for
        </Heading>
        <Small>Please try searching again</Small>
        <MainButton onClick={() => navigate('/')}>Back to the Main Page</MainButton>
      </ErrorText>
      <ImageContainer>
        <img src={ErrorImage} alt='404-not-found' width='424px' height='288px' />
      </ImageContainer>
    </ErrorPage>
  );
};

export default NotFoundPage;
