import { useSuspenseQuery } from '@tanstack/react-query';
import getUniqueCode from 'actions/unique-code/getUniqueCodeAction';
import { moduleAPI } from 'api/module';
import React, { createContext, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MODULE_TYPES } from '../../../constants';
import { Module } from '../../../hooks/useModules';

interface ModuleContextType {
  module?: Module | null;
}

export const ModuleContext = createContext<ModuleContextType>({
  module: null,
});

export const ModuleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();
  const { moduleId, moduleType = MODULE_TYPES.KNOWLEDGE_BASE } = useParams<{
    moduleId: string;
    moduleType: MODULE_TYPES;
  }>();
  const { data: module, isLoading } = useSuspenseQuery({
    queryKey: [moduleType, parseInt(moduleId as any)],
    queryFn: moduleAPI.getModule,
    staleTime: 60 * 1000 * 5,
  });

  // TODO: remove when redux data depracated
  useEffect(() => {
    moduleType !== MODULE_TYPES.KNOWLEDGE_BASE && dispatch(getUniqueCode(moduleId, moduleType));
  }, [module, moduleType]);

  // TODO The title is being used as the name of the module in the Module interface; therefore, it needs to be added explicitly. The same applies to the created key.
  return (
    <ModuleContext.Provider
      value={{
        module: !isLoading && module ? { ...module, title: module.name, created: '' } : null,
      }}
    >
      {children}
    </ModuleContext.Provider>
  );
};
